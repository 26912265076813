import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Fade, Skeleton} from "@mui/material";
import {MdClear} from "react-icons/md"
import { fetchCompaniesGoogle, fetchCompaniesDetailsGoogle, categoryDetailClear } from "../../redux/actions";

//My imports
import CompanyDetails from "./company_details";

const CreateCompany = () => {
    const dispatch = useDispatch()
    const [isSearching, setIsSearching] = useState(true)
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState(false)
    const [name, setName] = useState('')

    const handleSearch = (e) => {
        let value = e.target.value;
        if(value.length >= 3){
            dispatch(fetchCompaniesGoogle(value))
            setSearch(true)
        } else {
            setSearch(false)
        }
    }
    const closeModal = () => {
        setOpen(false)
        setIsSearching(true)
        setSearch(false)
        dispatch(categoryDetailClear())
    }
    const handleCompanyDetails = (company) => {
        dispatch(fetchCompaniesDetailsGoogle(company.place_id))
        setName(company.description)
        setIsSearching(false)
    }
    const companies = useSelector(state => state.companiesSearchReducer.companies)
    const company_details = useSelector(state => state.companiesGoogleDetailsReducer.company)
    return(
        <>
            <div className="search-component">
                <button className="blue-hyperlink no-text-decoration" onClick={() => setOpen(true)}>List Your Business For Free</button>
            </div>
            <Modal open={open} onClose={() => closeModal()}>
                <div className="create-company-modal">
                    <div className="company-modal-header">
                        <h5>{isSearching ? "Search Company" : "Company details"}</h5>
                        <button onClick={() => closeModal()}><MdClear/></button>
                    </div> 
                    {isSearching ? 
                        <div className="company-modal-body">
                            <div>
                                <div className="autocomplete-input">
                                    <input type="text" placeholder="search company" onKeyUp={(e) => handleSearch(e)}/>
                                    <Fade in={search}>
                                        <div className="autocomplete-results custom-scrollbar-vertical">
                                            {companies === undefined ? 
                                            <>
                                                <Skeleton height={15} width={200} style={{marginBottom: "15px"}}/> 
                                                <Skeleton height={15} width={200}/>
                                            </>
                                            : 
                                            companies.map(company => {
                                                return(
                                                    <div className="autocomplete-results-item" key={company.place_id} onClick={(e) => handleCompanyDetails(company)}>
                                                        <h4>{company.description}</h4>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div> 
                    : company_details === undefined ?  
                        <div className="company-modal-body pt-25">
                            <Skeleton height={50} width="100%"/>
                            <Skeleton height={50} width="100%"/>
                            <Skeleton height={50} width="100%"/>
                            <Skeleton height={50} width="100%"/>
                        </div>
                        : <CompanyDetails setSearch={setSearch} setIsSearching={setIsSearching} setOpen={setOpen} company_details={company_details && company_details} clicked={name}/>}
                </div>
            </Modal>
        </>
    )
}
export default CreateCompany