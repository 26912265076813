import React, {useEffect, useState} from "react";
import { Skeleton } from "@mui/material";
import {Link} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import {Divider, Fade, ClickAwayListener} from "@mui/material";
import { fetchSearchCatComp, searchCategoriesDrop } from "../../redux/actions";
import CreateCompany from "../Companies/createCompany";

const Search = ({type,serachValue}) => {
    const dispatch = useDispatch()

   
    const searchItems = (e) => {
        let value = e.target.value;
        if(value.length >= 3){
            dispatch(fetchSearchCatComp(value))
            dispatch(searchCategoriesDrop(true))
        } else {
            dispatch(searchCategoriesDrop(false))
        }
    }
  
const search = useSelector(state => state.searchCatDrop.search)

    // startline for HomeOrigin
        useEffect(()=>{
            
            if(type === "HomeOrigin" && serachValue.length >= 3){
                searchItems({
                    target:{
                        value: serachValue
                    }
                });
            }

        },[serachValue]);
        const dropDownOffset = type === "HomeOrigin" ? { top:"-5px"} : {};
    // endline for HomeOrigin

    
    const results = useSelector(state => state.searchCatAndComp.result);
    
    return(
        <div className="search-component"> 

           {type !== "HomeOrigin" && <ClickAwayListener onClickAway={() =>  dispatch(searchCategoriesDrop(false))}>
                <input type="search" placeholder="Search Company or Category" onChange={(e) => searchItems(e)}/>
            </ClickAwayListener>}

            <CreateCompany/>
            <Fade in={search}>
                <div className="search-results-here custom-scrollbar-vertical" style={{...dropDownOffset}} >
                    <div>
                        <h6>Companies</h6>
                        <div className="search-results-companies">
                            {results === undefined ? 
                                <div>
                                    <div>
                                        <Skeleton height={30} width="40%"/> 
                                        <Skeleton height={15} width="70%"/> 
                                    </div>
                                </div>
                                :
                                results.companies && results.companies.length > 0 ?
                                results.companies.map(company => {
                                    return(
                                        <Link to={`/company/${company.url}`} key={`cat${company.title}`}>
                                            <h5>{company.title}</h5>
                                            <span>{company.url}</span>
                                        </Link>
                                    )
                                })
                                : <h6>No companies found</h6>
                            }
                        </div>
                    </div>
                    <Divider/>
                    <div>
                        <h6>Categories</h6>
                        <div className="search-results-categories">
                            {results === undefined ? 
                                <div>
                                    <div>
                                        <Skeleton height={30} width="40%"/> 
                                        <Skeleton height={15} width="70%"/> 
                                    </div>
                                </div>
                                :
                                results.categories && results.categories.length > 0 ?
                                results.categories.map(category => {
                                    return(
                                        <Link to={`/${category.url}`} key={`cat${category.title}`}>
                                            <h5>{category.title}</h5>
                                            <span>{category.url}</span>
                                        </Link>
                                    )
                                })
                                : <h6>No categories found</h6>
                            }
                        </div>
                    </div>
                </div>
            </Fade>
        </div> 
    )
}
export default Search