import React from "react";
import { Grid, Skeleton } from "@mui/material";

const LayoutLoading = () => {
    const style = {
        common: {
            backgroundColor: "#EFF2F7",
            transform: "unset"
        },
        divWrapper: {
            display: "flex",
            marginBottom: 54,
            alignItems: "center"
        },
        profile: {
            backgroundColor: "#EFF2F7",
            flex: '0 0 auto',
            marginRight: 24,
            transform: "unset"
        },
        textHolder: {
            flex: 1
        },
        marginBottom: {
            marginBottom: "24px",
        },
        text:{
            backgroundColor: "#EFF2F7",
            borderRadius: 100,
            transform: "unset"
        },
        box: {
            backgroundColor: "#EFF2F7",
            borderRadius: 5,
            transform: "unset"
        },
        gradientText: {
            background: 'linear-gradient(90deg, rgba(254, 252, 254, 0.63) 0%, rgba(239, 242, 247, 0.63) 124.71%)'
        },
        profileBackground: {
            backgroundColor: "#F8F8Fb",
            flex: '0 0 auto'
        },
        insideBox: {
            visibility: "visible",
            padding: "32px 18px",
            display: "flex",
            alignItems: "center"
        },
        insideText: {
            background: 'linear-gradient(90deg, rgba(254, 252, 254, 0.63) 0%, rgba(239, 242, 247, 0.63) 124.71%)',
            borderRadius: 100,
            transform: "unset",
            marginLeft: '20px',
            flex: 1
        },
        contMargin: {
            marginBottom: "35px"
        },
        insideBigBox: {
            visibility: "visible",
        },
        insideBigBoxHeader: {
            display: "flex",
            alignItems: "center",
            visibility: "visible",
            marginBottom: "27px"
        },
        profileBackgroundWhite: {
            backgroundColor: "#fff",
        },
        insideTextWhite: {
            background: '#fff',
            borderRadius: 100,
            transform: "unset",
        },
        marginLeft: {
            marginLeft: "20px"
        },
        bigBox: {
            padding: "27px 20px",
            backgroundColor: "#EFF2F7",
            transform: "unset",
            borderRadius: "15px",
            boxSizing: "border-box"
        },
        mb15: {
            marginBottom: "15px"
        },
        wrapper: {
            paddingTop: "30px"
        }
    }
    return (
        <div style={style.wrapper}>
            {/* <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div style={style.divWrapper}>
                        <Skeleton style={style.profile} animation="wave" variant="circular" width={108} height={108}/>
                        <div style={style.textHolder}>
                            <Skeleton style={{...style.text, ...style.marginBottom}} animation="wave" variant="text" width={'50%'} height={37} />
                            <Skeleton style={style.text} animation="wave" variant="text" width={'70%'} height={24} />
                        </div>
                    </div>
                </Grid>
            </Grid> */}
            <Grid container spacing={2} style={style.contMargin}>
                <Grid item xs={12} md={3}>
                    <Skeleton style={style.box} animation="wave" variant="rectangular" width="100%" height={164}>
                        <div style={style.insideBox}>
                            <Skeleton variant="circular" width={33} height={33} style={style.profileBackground} animation={false}/>
                            <Skeleton style={style.insideText} animation={false} variant="text" width={'100%'} height={24} />
                        </div>
                    </Skeleton>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Skeleton style={style.box} animation="wave" variant="rectangular" width="100%" height={164}>
                        <div style={style.insideBox}>
                            <Skeleton variant="circular" width={33} height={33} style={style.profileBackground} animation={false}/>
                            <Skeleton style={style.insideText} animation={false} variant="text" width={'100%'} height={24} />
                        </div>
                    </Skeleton>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Skeleton style={style.box} animation="wave" variant="rectangular" width="100%" height={164}>
                        <div style={style.insideBox}>
                            <Skeleton variant="circular" width={33} height={33} style={style.profileBackground} animation={false}/>
                            <Skeleton style={style.insideText} animation={false} variant="text" width={'100%'} height={24} />
                        </div>
                    </Skeleton>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Skeleton style={style.box} animation="wave" variant="rectangular" width="100%" height={164}>
                        <div style={style.insideBox}>
                            <Skeleton variant="circular" width={33} height={33} style={style.profileBackground} animation={false}/>
                            <Skeleton style={style.insideText} animation={false} variant="text" width={'100%'} height={24} />
                        </div>
                    </Skeleton>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={style.contMargin}>
                <Grid item xs={12}>
                    <Skeleton style={style.text} animation="wave" variant="text" width={"70%"} height={20} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton style={style.bigBox} animation="wave" variant="rectangular" width="100%" height={200}>
                        <div style={style.insideBigBoxHeader}>
                            <Skeleton variant="circular" width={33} height={33} style={style.profileBackgroundWhite} animation={false}/>
                            <Skeleton style={{...style.insideTextWhite, ...style.marginLeft}} animation={false} variant="text" width={'20%'} height={24} />
                        </div>
                        <div style={style.insideBigBox}>
                            <Skeleton style={{...style.insideTextWhite, ...style.mb15}} animation={false} variant="text" width={"100%"} height={20} />
                            <Skeleton style={style.insideTextWhite} animation={false} variant="text" width={"20%"} height={20} />
                        </div>
                    </Skeleton>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Skeleton style={style.box} animation="wave" variant="rectangular" width="100%" height={164}>
                        <div style={style.insideBox}>
                            <Skeleton variant="circular" width={33} height={33} style={style.profileBackground} animation={false}/>
                            <Skeleton style={style.insideText} animation={false} variant="text" width={'100%'} height={24} />
                        </div>
                    </Skeleton>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Skeleton style={style.box} animation="wave" variant="rectangular" width="100%" height={164}>
                        <div style={style.insideBox}>
                            <Skeleton variant="circular" width={33} height={33} style={style.profileBackground} animation={false}/>
                            <Skeleton style={style.insideText} animation={false} variant="text" width={'100%'} height={24} />
                        </div>
                    </Skeleton>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Skeleton style={style.box} animation="wave" variant="rectangular" width="100%" height={164}>
                        <div style={style.insideBox}>
                            <Skeleton variant="circular" width={33} height={33} style={style.profileBackground} animation={false}/>
                            <Skeleton style={style.insideText} animation={false} variant="text" width={'100%'} height={24} />
                        </div>
                    </Skeleton>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Skeleton style={style.box} animation="wave" variant="rectangular" width="100%" height={164}>
                        <div style={style.insideBox}>
                            <Skeleton variant="circular" width={33} height={33} style={style.profileBackground} animation={false}/>
                            <Skeleton style={style.insideText} animation={false} variant="text" width={'100%'} height={24} />
                        </div>
                    </Skeleton>
                </Grid>
            </Grid>
        </div>
    )
};
export default LayoutLoading