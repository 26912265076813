import React, {useEffect} from "react";
import { Grid, Skeleton} from "@mui/material";
import {Link} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {MdNavigateNext, MdNavigateBefore} from "react-icons/md";

//Fetch
import { fetchCategories } from "../../redux/actions";


const Categories = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCategories())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const categories = useSelector(state => state.categoriesReducer.categories)
    const handleDragStart = (e) => e.preventDefault();

    let chunk = 16
    let slicedCategories = [];

    if(categories === undefined){
        return(
            <Grid container spacing={1}>
                {[...Array(chunk)].map((e, i) => {
                    return(
                        <Grid item xs={12} md={6} lg={4} xl={3} key={`sekeleton${i}`}>
                            <Skeleton height={100}/>
                        </Grid>
                    )
                })}
            </Grid>
        )
    }

    for (let i = 0; i < categories.length; i+= chunk){
        slicedCategories.push(categories.slice(i, i + chunk))
    }
    
    return(
        <div>
            {categories && categories.length > chunk ? 
                <AliceCarousel 
                    disableDotsControls 
                    mouseTracking 
                    renderPrevButton={() => {
                        return <MdNavigateBefore className="custom-navigation"/>
                    }}
                    renderNextButton={() => {
                        return <MdNavigateNext className="custom-navigation"/>
                    }}
                    items={slicedCategories && slicedCategories.map(array => {
                        return(
                            <Grid container spacing={2} onDragStart={handleDragStart}>
                                {array.map((category) => {
                                    return(
                                        <Grid item xs={12} md={6} lg={4} xl={3} key={`category${category.id}`}>
                                            <Link to={`/categories/${category.slug}`} className="no-text-decoration dark-blue-cl">
                                                <div className="home-categry-shortcut">
                                                    <img src={category.icon ? category.icon : "/admin-icons/other.svg"} alt={category.name} />
                                                    <h6>{category.name}</h6>
                                                </div>
                                            </Link>
                                        </Grid>
                                    ) 
                                })}
                            </Grid>   
                        )
                    })}
                />
                :              
                <Grid container spacing={2}>
                    {categories.map((category) => {
                        return(
                            <Grid item xs={12} md={6} lg={4} xl={3} key={`category${category.id}`}>
                                <Link to={`/categories/${category.slug}`} className="no-text-decoration dark-blue-cl">
                                    <div className="home-categry-shortcut">
                                        <img src={"/admin-icons/other.svg"} alt={category.name} />
                                        <h6>{category.name}</h6>
                                    </div>
                                </Link>
                            </Grid>
                        ) 
                    })}
                </Grid>
            }
        </div>
 
    )
}
export default Categories