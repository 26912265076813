import { Rating, Box } from '@mui/material'
import React from 'react'

import { BiWorld, BiCategoryAlt } from "react-icons/bi";
import {GoLocation} from "react-icons/go";
import { useNavigate } from 'react-router-dom';

const OneRowCompany = (props) => {
    const navigate = useNavigate();
    var { name, website,rating, totalReviews ,city ,address ,category,landing , totalReviewsAvg } = props?.item || {};
    name = name.replace(/\-/g, " ");
    name ??= "";
    totalReviews ??= 0;
    rating ??= 0;
    totalReviewsAvg ??= 0;
    category ??= "";

    function toTarget(){
        navigate(`/company/${landing}`);
    }
    return (
        <div className="companies-list-box section-font-family">
            <Box onClick={toTarget} className="compaines-name-box">{name}</Box>
            <Box sx={{ display: "flex", marginBottom: "5px" }}>
                <Rating name="read-only" value={totalReviewsAvg} readOnly />
                <Box sx={{ marginLeft: "10px" }}>Reviews: {totalReviews.toLocaleString()}</Box>
            </Box>
            <Box style={{display:"flex",justifyContent:"space-between"}}>
                {website && <>
                    <a href={website} target="_blank" className="no-wrap-link">
                        <BiWorld /> {website}
                    </a>
                    <Box sx={{ marginBottom: "5px" }}>
                        <BiCategoryAlt /> {category}
                    </Box>
                </>
                }
            </Box>

            <Box sx={{ marginBottom: "5px" }}>
                <GoLocation/> {city ? city : ""} { address ? address : "" }
            </Box>

        </div>
    )
}

export default OneRowCompany