export const getLocationTags = (state = [], action) => {
    switch(action.type){
        case 'GET_LOCATION_TAGS':
            return{
                ...state,
                tags: action.payload,
                loading: false
            }
        case 'LOADING_TABLES':
            return{
                ...state,
                loading: true
            }
        case 'CLEAR_LOADING_TABLES':
            return{
                ...state,
                loading: false,
            }
        case 'CLEAR_TAGS':
            return{
                ...state,
                tags: undefined,
            }
        default:
            return{
                ...state
            }
    }
}