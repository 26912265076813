export const getUserPermissions = (state = [] ,action) => {
    switch(action.type){
        case 'GET_PERMISSIONS':
            return{
                ...state,
                permissions: action.payload
            }
        case 'CLEAR_PERMISSIONS':
            return{
                ...state,
                permissions: undefined
            }
        default:
            return{
                ...state
            }
    }
}