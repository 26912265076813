export const ShowYoutubeContent = (state = "", action) => {
    switch(action.type){
        case 'SHOW_YOUTUBE_CONTENT':
            return{
                ...state,
                path: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}