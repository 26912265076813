import React, { useState, useEffect, useRef } from "react"
import { ClickAwayListener, Skeleton, Badge, Fade, CircularProgress, Collapse, IconButton } from "@mui/material"
import LocationSelectSearch from "./parts/SelectSearch"
import LocAndGroup from "./parts/LocAndGroup"
import { useSelector, useDispatch } from "react-redux"
import { useLocation, Link, useNavigate } from "react-router-dom"
import { fetchNotificationsCount,fetchNotificationsUnread, fetchTopNavData, clearTopNav, openVideoModal, irevuMultiLanguage, fetchNotifications, fetchWallet, changeWalletVisibility } from "../../redux/actions/admin-index"
import YoutubeVideo from "./modals/Information/youtubeVideo"
import BlueTooltip from "./helpers/blueTooltip"
import { SmallBlueTooltip } from "./helpers/blueTooltip"
import AddLocation from "./modals/locations/add_locations"
import { baseUrlDev } from "../Helpers/baseUrl"
import { getLocalUser } from "../TokenControl/parts/useLocalUser"
import axios from "axios"
import UserDropdown from "./UserDropdown"
import { useTranslation } from "react-i18next"
import Wallet from "./modals/wallet/wallet"
import CreateCard from "./modals/wallet/createCard"
import i18n from "../../i18n"
import { Helmet } from "react-helmet"
import { FaYoutube } from "react-icons/fa";
import { IoIosAddCircle } from "react-icons/io";
import NewNotifications from "./Notification/new_notifications"

//Images

const languages = [
    { name: "English (US)", lng: "en" },
    { name: "Franchais (France)", lng: "fr" },
    { name: "Italian (Italy)", lng: "it" },
    { name: "Español (Spain)", lng: "es" },
    { name: "Deutsch (Germany)", lng: "de" }
]


const Navbar = ({ height, title, all, groups, disable_location }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [openSearch, setOpenSearch] = useState(false)
    const [openResult, setOpenResult] = useState(false)
    const [searching, setSearching] = useState(false)
    const [openUserDetails, setOpenUserDetails] = useState(false)
    const [open, setOpen] = useState(false)
    const [show, setShow] = useState(false)
    const [showLoc, setShowLoc] = useState(false);
    const [openPlatformModal, setOpenPlatformModal] = useState(false);
    const [loading, setLoading] = useState()
    const [showLng, setShowLng] = useState(false)

    const { t, i18n } = useTranslation();

    const onSearch = (e) => {
        if (e.target.value.length >= 3) {
            setSearching(true)
            dispatch(fetchTopNavData(e.target.value))
            setOpenResult(true)
        } else {
            setSearching(false)
            setOpenResult(false)
            dispatch(clearTopNav())
        }
    }

    const activeOnMobNav = useSelector(state => state.showNavbarReducer.show)
    const user = useSelector(state => state.getUserInfo.user)
    const count = useSelector(state => state.getNotificationsCount.notifications_count)
    const results = useSelector(state => state.topNavSearch.res)
    const language = useSelector(state => state.getLanguage.language)
    const wallet = useSelector(state => state.getWalletReducer.wallet)
    const [delays, setDelays] = useState([]);
    const [overflowShow, setOverflowShow] = useState(false);
    const platformDropdownRef = useRef(null);

    useEffect(() =>{
        dispatch(fetchNotifications(1))
        dispatch(fetchNotificationsUnread(1))
    }, [])
 
    useEffect(() => {
        if (openPlatformModal) {
            const newDelays = [];
            for (let i = 0; i <= 7; i++) {
                newDelays.push(`delay-${i}`);
            }
            setDelays(newDelays);
        } else {
            setDelays([]);
            setOverflowShow(false);
        }
    }, [openPlatformModal]);

    useEffect(() => {
        if (openPlatformModal) {
            checkOverflow(platformDropdownRef.current);
        }
    }, [delays, openPlatformModal]);

    const checkOverflow = (element) => {
        const maxHeight = parseInt(window.getComputedStyle(element).maxHeight);
        if (element.scrollHeight > maxHeight) {
            setOverflowShow(true);
        } else {
            setOverflowShow(false);
        }
    };

    useEffect(() => {
        dispatch(fetchNotificationsCount())
        i18n.changeLanguage(user?.user?.lang ? user?.user?.lang : "en")
        dispatch(irevuMultiLanguage(user?.user?.lang ? user?.user?.lang : "en"))
    }, [user])

    useEffect(() => {
        if (results) {
            setSearching(false)
        }
    }, [results])

    useEffect(() => {
        dispatch(fetchWallet())
        setTimeout(() => {
            dispatch(fetchNotificationsCount())
        }, 60000)
    }, [])

    const logout = () => {
        setLoading(true)
        setOpenUserDetails(false)
        axios.post(`${baseUrlDev}logout`, {}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(() => {
                setLoading(false)
                window.location.href = "https://irevu.com"
                localStorage.clear()
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const changeLanguage = (lng) => {
        axios.post(`${baseUrlDev}language`, { lang: lng }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(() => {
                i18n.changeLanguage(lng);
                dispatch(irevuMultiLanguage(lng))
                localStorage.setItem("language", lng)
            })
    }
    return (
        <div className={`search-user-holder ${activeOnMobNav ? "active" : ""}`} style={{ marginTop: height ? height : 0 }}>
            <Helmet>
                <title>{title} - iReview</title>
            </Helmet>
            {/* <Notification open={open} setOpen={setOpen} /> */}
            <YoutubeVideo />
            <AddLocation open={showLoc} setOpen={setShowLoc} title={"Location"} />
            <Wallet />
            {loading && <div className="logout-div"><img src={"/admin-images/loading_ireview.gif"} alt="loader" /></div>}
            <div className="search-user-container">
                <div className="d-flex align-items-center">
                    <LocAndGroup all={all} groups={groups} disable_location={disable_location} />
                    <button className="Location-add" onClick={() => setShowLoc(true)}>{t("Add Location")}</button>
                </div>
                <div className="navbar-right-side">
                    {user && user.user && user.user.locations.filter(loc => loc.is_owner == true).length > 0 &&
                        <div className="wallet-container">
                            {wallet ?
                                <div className="wallet-wrapper" onClick={() => dispatch(changeWalletVisibility(true))}>
                                    <div>
                                        <small>{t("Balance")}</small>
                                        <span>${wallet?.wallet?.balance ? wallet?.wallet.balance : 0}</span>
                                    </div>
                                    <SmallBlueTooltip title={t("Add Balance")}>
                                        <IconButton>
                                            <IoIosAddCircle />
                                        </IconButton>
                                    </SmallBlueTooltip>
                                </div>
                                :
                                <div className="wallet-wrapper" onClick={() => dispatch(changeWalletVisibility(true))}>
                                    <div>
                                        <span>Add Card</span>
                                    </div>
                                    <SmallBlueTooltip title={t("Add Card")}>
                                        <IconButton>
                                            <IoIosAddCircle />
                                        </IconButton>
                                    </SmallBlueTooltip>
                                </div>
                            }
                        </div>
                    }
                    {/* <button onClick={() => dispatch(openVideoModal())} className="info-btn-container">
                        <BlueTooltip arrow
                            title={`${t("How it works")} - ${t(title)}`}>
                            <div className="video-holder-div">
                                <img src={"/admin-icons/fluent_video.svg"} alt="info" />
                                <h5>{t("Video Tutorial")}</h5>
                            </div>
                        </BlueTooltip>
                    </button> */}
                    <ClickAwayListener onClickAway={() => {
                        setOpenSearch(false)
                        setOpenResult(false)
                    }
                    }>
                        <div className={`search-input-holder open`}>
                            <input type="search" name="search" className="searchbox-adm-input" placeholder={t("Search Review Here")} onKeyUp={(e) => onSearch(e)} />
                            <span className="searchbox-adm-icon" onClick={() => setOpenSearch(!openSearch)}></span>

                            <Fade in={openResult}>
                                <div className="searchbox-adm-results">
                                    {results && results.data && Object.keys(results.data).length > 0 ?
                                        Object.keys(results.data).map((key, i) => {
                                            return (
                                                <div>
                                                    <h6>{key}</h6>
                                                    <ul>
                                                        {results.data[key].map(item => {
                                                            return (
                                                                <li>
                                                                    <Link to={item.url}>{item.title}</Link>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            )
                                        })

                                        : <div className="search-general">{searching ? 'searching...' : 'Nothing found'}</div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </ClickAwayListener>

                    <ClickAwayListener onClickAway={() => {
                        setOpenPlatformModal(false)
                    }}>
                        <div className="platforms-holder">
                            <span onClick={() => setOpenPlatformModal(!openPlatformModal)}>
                                <img src={"/admin-icons/show_sys_logo.svg"} alt="platforms" />
                            </span>

                            {/* <div className={`platforms-dropdown-holder ${openPlatformModal ? 'show' : ''}`}>
                                <div>
                                    <label>{t("Platforms")}</label>
                                </div>
                                <div className="scrollable-div-container custom-scrollbar-vertical">
                                    <Link to="/app/admin/goto/crm" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/mydev.svg" alt="" />
                                        <span>{t("Customer Relationship Management")}<span>
                                        </span></span>
                                    </Link>
                                    <Link to="/app/admin/goto/usender" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/sendbat.svg" alt="" />
                                        <span>{t("SMS and Email Automation")}</span>
                                    </Link>
                                    <Link to="/app/admin/goto/urless" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/urless.png" alt="" />
                                        <span>{t("Short URL & QR Code Generator")}</span>
                                    </Link>
                                    <Link to="/app/admin/goto/morsix" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/morsix.svg" alt="" />
                                        <span>{t("Inventory Management")}</span>
                                    </Link>
                                    <Link to="/app/admin/goto/claritask" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/claritask.svg" alt="" />
                                        <span>{t("Project Management")}</span>
                                    </Link>
                                    <Link to="/app/admin/goto/claritick" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/claritick.svg" alt="" />
                                        <span>{t("Ticketing System")}</span>
                                    </Link>
                                    <Link to="/app/admin/goto/convosio" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/convosio.svg" alt="" />
                                        <span>{t("Chat System")}</span>
                                    </Link>
                                </div>
                            </div> */}

                            <div className={`platforms-dropdown-holder new-platforms-dropdown-holder ${openPlatformModal ? 'show' : ''} ${overflowShow ? 'overflow-show' : ''}`} ref={platformDropdownRef}>
                                <div>
                                    <label>{t("Platforms")}</label>
                                </div>
                                <div className="scrollable-div-container custom-scrollbar-vertical">
                                    <div className={`item ${delays[0] || ''}`}>
                                        <div>
                                            <Link to="/app/admin/goto/crm" className="platform-holder-icon">
                                                <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/crm-icon.svg" alt="" />
                                                <div>
                                                    <h4>{t("CRM")}</h4>
                                                    <h6>{t("Simplifying customer relationship management for your business.")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                        <a href="https://www.youtube.com/watch?v=T-I4HYCz9yE" target="_blank">
                                            <FaYoutube />
                                        </a>
                                    </div>
                                    <div className={`item ${delays[1] || ''}`}>
                                        <div>
                                            <Link to="/app/admin/goto/usender" className="platform-holder-icon">
                                                <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/sendbat-icon.svg" alt="" />
                                                <div>
                                                    <h4>{t("Sendbat")}</h4>
                                                    <h6>{t("Online marketing platform for memorable SMS and email campaigns.")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                        <a href="https://www.youtube.com/watch?v=zYasXlSmyKI" target="_blank" rel="noopener noreferrer">
                                            <FaYoutube />
                                        </a>
                                    </div>
                                    <div className={`item ${delays[2] || ''}`}>
                                        <div>
                                            <Link to="/app/admin/goto/urless" className="platform-holder-icon">
                                                <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/urless.png" alt="" />
                                                <div>
                                                    <h4>{t("Urless")}</h4>
                                                    <h6>{t("Simplify your links with our easy-to-use URL shortener.")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                        <a href="#" className="urless-link">
                                            <FaYoutube />
                                        </a>
                                    </div>
                                    <div className={`item ${delays[3] || ''}`}>
                                        <div>
                                            <Link to="/app/admin/goto/morsix" className="platform-holder-icon">
                                                <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/morsix.svg" alt="" />
                                                <div>
                                                    <h4>{t("Morsix")}</h4>
                                                    <h6>{t("Optimize warehouse organization and inventory control.")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                        <a href="https://www.youtube.com/watch?v=OEImRnCyFM0" target="_blank">
                                            <FaYoutube />
                                        </a>
                                    </div>
                                    <div className={`item ${delays[4] || ''}`}>
                                        <div>
                                            <Link to="/app/admin/goto/claritask" className="platform-holder-icon">
                                                <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/claritask.svg" alt="" />
                                                <div>
                                                    <h4>{t("Claritask")}</h4>
                                                    <h6>{t("Efficiently manage projects and tasks with ease.")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                        <a href="https://www.youtube.com/watch?v=4cJ25YK9W6k" target="_blank">
                                            <FaYoutube />
                                        </a>
                                    </div>
                                    <div className={`item ${delays[5] || ''}`}>
                                        <div>
                                            <Link to="/app/admin/goto/claritick" className="platform-holder-icon">
                                                <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/claritick.svg" alt="" />
                                                <div>
                                                    <h4>{t("Claritick")}</h4>
                                                    <h6>{t("Streamline ticketing to manage client requests effectively.")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                        <a href="https://www.youtube.com/watch?v=xITyoLiGEb4" target="_blank">
                                            <FaYoutube />
                                        </a>
                                    </div>
                                    <div className={`item ${delays[6] || ''}`}>
                                        <div>
                                            <Link to="/app/admin/goto/convosio" className="platform-holder-icon">
                                                <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/convosio.svg" alt="" />
                                                <div>
                                                    <h4>{t("Convosio")}</h4>
                                                    <h6>{t("Engage customers with real-time live chat support.")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                        <a href="https://www.youtube.com/watch?v=fw5Xz3h9Clg&t=2s" target="_blank">
                                            <FaYoutube />
                                        </a>
                                    </div>
                                    <div className={`item ${delays[7] || ''}`}>
                                        <div>
                                            <Link to="/app/admin/goto/ipaymer" className="platform-holder-icon">
                                                <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/ipaymer.svg" alt="" />
                                                <div>
                                                    <h4>{t("Ipaymer")}</h4>
                                                    <h6>{t("iPaymer delivers fast, easy payments 24/7 using any card.")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                        <a href="#" className="ipaymer-link">
                                            <FaYoutube />
                                        </a>
                                    </div>
                                    {/* <div className={`item ${delays[7] || ''}`}>
                                        <div>
                                            <Link to="/app/admin/goto/zuitte" className="platform-holder-icon">
                                                <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/zuitte.svg" alt="" />
                                                <div>
                                                    <h4>{t("Zuitte")}</h4>
                                                    <h6>{t("The ultimate toolkit for entrepreneurs, simplifying every aspect of business management.")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                        <a href="https://www.youtube.com/watch?v=brI0dMiqpP0" target="_blank">
                                            <FaYoutube />
                                        </a>
                                    </div>

                                    <div className={`item ${delays[9] || ''}`}>
                                        <div>
                                            <Link to="/app/admin/goto/azalytics" className="platform-holder-icon">
                                                <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/azalytics.svg" alt="" />
                                                <div>
                                                    <h4>{t("Azalytics")}</h4>
                                                    <h6>{t("From A to Z, transforming data into actionable insights.")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                        <a href="#">
                                            <FaYoutube />
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>               
                        <div>
                            <div className="admin-notification-holder" onClick={() => setOpen(!open)}>
                                {count === undefined ?
                                    <>
                                        <img src={"/admin-icons/new-noty-bell.svg"} alt="notification" />
                                    </>
                                    :
                                    <Badge badgeContent={count.unread} max={999}>
                                        <img src={"/admin-icons/new-noty-bell.svg"} alt="notification" />
                                    </Badge>
                                }
                            </div>
                            <NewNotifications open={open} setOpen={setOpen} />
                        </div>
                    </ClickAwayListener>
                    <div className="language-wrapper">
                        <ClickAwayListener onClickAway={() => setShowLng(false)}>
                            <img src={language ? `/admin-icons/flags/${language}.svg` : `/admin-icons/flags/language-icon-black.svg`} onClick={() => setShowLng(!showLng)} />
                        </ClickAwayListener>
                        <Collapse in={showLng}>
                            <div className="language-dropdown">
                                {languages.map(lng => {
                                    return (
                                        <div onClick={() => changeLanguage(lng.lng)} key={`language-${lng.name}`}>
                                            <img src={`/admin-icons/flags/${lng.lng}.svg`} /> {lng.name}
                                        </div>
                                    )
                                })}
                            </div>
                        </Collapse>
                    </div>
                    <UserDropdown user={user} setLoading={setLoading} />
                </div>
            </div>
        </div>
    )
}
export default Navbar