import React from 'react';

const spinnerStyles = `
    .spinner-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    .spinner {
        aspect-ratio: 1;
        display: grid;
        border-radius: 50%;
        background:
        linear-gradient(0deg, rgb(0 0 0/30%) 30%, #0000 0 70%, rgb(173, 171, 171) 0) 50%/8% 100%,
        linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(173, 171, 171) 0) 50%/100% 8%;
        background-repeat: no-repeat;
        animation: l23 1s infinite steps(8);
    }
  .spinner::before,
  .spinner::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(45deg); /* 360 / 8 */
  }
  .spinner::after {
    opacity: 0.83;
    transform: rotate(90deg); /* 360 / 8 * 2 */
  }
  @keyframes l23 {
    100% {transform: rotate(1turn)}
  }
`;

const Spinner = ({width}) => {
  return (
    <div  className="spinner-wrapper">
      <style>
        {spinnerStyles}
      </style>
      <div className="spinner" style={{width: `${width}px`}}></div>
    </div>
  );
};

export default Spinner;