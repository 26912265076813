const searchCatDrop = (state = [], action) => {
    switch(action.type){
        case 'SEARCH_CAT_DROP':
            return{
                ...state,
                search: action.payload
            }
        default:
            return{
                ...state
            }
    }
}
export default searchCatDrop