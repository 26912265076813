import React from 'react'
import { ClickAwayListener, Collapse, FormControl, Skeleton } from '@mui/material'
import { useEffect, useState, useMemo } from 'react'
import { useLocation  } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getLocationSelected, getGroupSelected, getGroupLocation } from '../../../redux/actions/admin-index';
import { useTranslation } from 'react-i18next';

const containsTextLocations = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;
const containsTextGroups = (group, search) => {
    const groupNameMatches = group.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
  
    if (groupNameMatches) {
      return {...group};
    }
  
    const matchingLocations = group.locations.filter(location =>
      Object.values(location).some(value =>
        typeof value === 'string' && value.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
    );
  
    return matchingLocations.length > 0 ? { ...group, locations: matchingLocations } : null;
  };

const LocAndGroup = ({all, groups, disable_location}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const nav = useLocation()
    const [searchLocations, setSearchLocations] = useState("");
    const [show, setShow] = useState(false)
    const [activeTab, setActiveTab] = useState("groups")

    const user = useSelector(state => state.getUserInfo.user)
    let locations = user && user.user.locations
    let groupsArray = user && user.user.groups

    const locationSelected = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)
    const [t] = useTranslation()

    useEffect(() => {     
        if(locations){
            if(locations.length == 0 ){
                navigate("/create/location")
            }
            if(!all || locations.length == 1){
                if(!locationSelected || locationSelected === "all"){
                    dispatch(getLocationSelected(locations[0].id))
                    localStorage.setItem('location', locations[0].id);
                    dispatch(getGroupSelected(""))
                    localStorage.setItem('group', "");
                }
           
            } else {
                if((!locationSelected || locationSelected === "all") && !groupSelected){
                    dispatch(getLocationSelected("all"))
                    localStorage.setItem('location', "all");
                    dispatch(getGroupSelected(""))
                    localStorage.setItem('group', "");
                }
            }
        }
    }, [user, nav])

    // useEffect(() => {
    //     if(groupSelected){
    //         setActiveTab("groups")
    //     } else {
    //         setActiveTab("locations")
    //     }
    // }, [groupSelected])

    useEffect(() => {
        document.body.style.overflow = show ? 'hidden' : 'auto';
    
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [show]);

    //   useEffect(() => {
    //     if(!groups){
    //         setActiveTab("locations")
    //     }
    // }, [groups])


    const displayedGroups = useMemo(() => groupsArray && groupsArray.map(group => containsTextGroups(group, searchLocations)).filter(Boolean), [searchLocations, user]);

    if(!user || !locations){
        return(
            <FormControl sx={{width: '10vw'}}>
                <Skeleton style={{height: '37px', transform: 'unset'}}/>
            </FormControl>
        )
    }

    const changeLocation = (id) => {
        dispatch(getLocationSelected(id))
        dispatch(getGroupSelected(""))
        dispatch(getGroupLocation(""))

        localStorage.setItem('group', "");
        localStorage.setItem('location', id);
        localStorage.setItem("location_of_group", "")

        setShow(false)   
    }

    const changeGroup = (id) => {
        dispatch(getGroupSelected(id))
        dispatch(getLocationSelected(""))
        dispatch(getGroupLocation(""))

        localStorage.setItem('location', "");
        localStorage.setItem('group', id);
        localStorage.setItem("location_of_group", "")
        
        setShow(false) 
    }

    return(
        <ClickAwayListener onClickAway={() => setShow(false)}>
            <div className={disable_location ? "disabled_location_wrapper" : ""}>
                <FormControl className="select-search-loc">
                    <div onClick={() => setShow(!show)} className='select-search-header' style={{borderColor: show ? "#18AEE3" : "rgba(37, 49, 60, 0.15)"}}>
                        {locationSelected ?
                           ( locations && locations.filter(loc => loc.id == locationSelected) && locations.filter(loc => loc.id == locationSelected).length > 0 ? 
                            locations.filter(loc => loc.id == locationSelected)[0].full_name
                            :
                            t("All Groups"))
                            :
                            ( groupsArray && groupsArray.filter(group => group.id == groupSelected) && groupsArray.filter(group => group.id == groupSelected).length > 0 ?
                            `Group - ${groupsArray.filter(group => group.id == groupSelected)[0].name}`
                            :
                            t("All Groups"))
                        }
                    </div>
                </FormControl>
                <Collapse in={show}>
                    <div className='loc-group-container-wrapper'>
                        <div className="loc-group-tabs">
                            {/* {groups && 
                                <div>
                                    <div className={activeTab === "locations" ? "active" : ""} onClick={() => setActiveTab("locations")}>
                                        {t("Locations")}
                                    </div>
                                    <div className={activeTab === "groups" ? "active" : ""} onClick={() => setActiveTab("groups")}>
                                        {t("Groups")}
                                    </div>
                                </div>
                            } */}
                        </div>
                        <input type="text" placeholder={t("Search Group Or Location")} className='search-inside-dropdown' 
                            onChange={(e) => setSearchLocations(e.target.value)}
                            onKeyDown={(e) => {
                                if(e.key !== 'Escape'){
                                    e.stopPropagation();
                                }
                            }}
                        />
                        {/* <Collapse in={activeTab === "locations"}>
                            <div className='loc-group-container custom-scrollbar-vertical'>
                                {all  &&  locations.length > 1 && 
                                    <div>
                                        <input type="radio" name="location" value={"all"} id={"all_locations"} onChange={() =>  changeLocation("all")} checked={locationSelected == "all" ? true : false}/>
                                        <label htmlFor={"all_locations"}>
                                            {t("All Locations")}
                                        </label>
                                    </div>
                                }

                                {displayedLocation && displayedLocation.map(loc => {
                                    return(
                                        <div key={loc.id} className="left-loc-icon">
                                            <input type="radio" name="location" value={loc.id} id={loc.id} onChange={() =>  changeLocation(loc.id)} checked={locationSelected == loc.id  ? true : false}/>
                                            <label htmlFor={loc.id}>
                                                {loc.full_name}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </Collapse> */}
                        <Collapse in={activeTab === "groups"}>
                            <div className='loc-group-container custom-scrollbar-vertical'>
                                <div className="element-container">
                                    <input type="radio" name="groups" value={"all"} id={"all_groups"} onChange={() =>  changeLocation("all")} checked={locationSelected == "all" ? true : false}/>
                                    <label htmlFor={"all_groups"}>
                                        {t("All Groups")}
                                    </label>
                                </div>
                                {displayedGroups && displayedGroups.map(group => {
                                    return(
                                        <div key={`${group.id}-group-of-user`}>
                                            <div className="left-loc-icon-gr element-container">
                                                <input type="radio" name="location" value={group.id} id={group.id} onChange={() =>  changeGroup(group.id)} checked={groupSelected == group.id  ? true : false}/>
                                                <label htmlFor={group.id}>
                                                    {t("Group")} - {group.name}
                                                </label>
                                            </div>
                                            <div className={`group-locations ${groupSelected == group.id  ? "group-locations-selected" : ""}`}>
                                                {group.locations.map(loc => (
                                                    <div key={`${loc.id}-lof-of-gr`} className="left-loc-icon element-container">
                                                        <input type="radio" name="location" value={loc.id} id={loc.id} onChange={() =>  changeLocation(loc.id)} checked={locationSelected == loc.id  ? true : false}/>
                                                        <label htmlFor={loc.id}>
                                                            {loc.full_name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Collapse>
                    </div>
                </Collapse>
            </div>
        </ClickAwayListener>
    )
}
export default LocAndGroup