export const getNotificationSettings = (state = [], action) => {
    switch (action.type) {
        case 'GET_NOTIFICATION_SETTINGS':
            return {
                ...state,
                notifications_settings: action.payload
            }
        case 'CLEAR_ACCOUNT_NOTIFICATION':
            return {
                ...state,
                notifications_settings: undefined
            }
        default:
            return {
                ...state
            }
    }
}