const initialState = {
    notifications: [], // Initialize as an empty array
};
const initialPage = {
    page: 2
}
const initialUnreadPage = {
    page: 2
}

export const getNotifications = (state = [], action) => {
    switch (action.type) {
        case 'GET_NOTIFICATIONS':
            return {
                ...state,
                notifications: action.payload.notifications,
                loading_more: action.payload.load_more
            };
        case 'READ_LOADED_NOTIFICATIONS':
            let id = action.payload
            return {
                ...state,
                notifications: state.notifications?.map(notification => 
                  notification.review.id === id
                    ? {
                        ...notification,
                        read: 1,
                        read_at: new Date().toISOString(),
                      }
                    : notification
                ),
              };
        case "GET_NOTIFICATIONS_LOAD_MORE":
            return {
                ...state,
                notifications: [...state.notifications, ...action.payload.notifications],
                loading_more: action.payload.load_more
            };
            default:
                return {
                    ...state
                }
    }
}
export const getNotificationsUnread = (state = [], action) => {
    switch (action.type) {
        case 'GET_NOTIFICATIONS_UNREAD':
            return {
                ...state,
                notifications: action.payload.notifications,
                loading_more: action.payload.load_more
            };
        case 'READ_ALL_NOTIFICATIONS':
            return {
                ...state,
                notifications: []
            };
        case 'READ_LOADED_NOTIFICATIONS':
            return{
                ...state,
                notifications: state.notifications.filter(el => el.id != action.payload)
            }
        case "GET_NOTIFICATIONS_UNREAD_LOAD_MORE":
            return {
                ...state,
                notifications: [...state.notifications, ...action.payload.notifications],
                loading_more: action.payload.load_more
            };
            default:
                return {
                    ...state
                }
    }
}
export const getNotificationPagination = (state = initialPage, action) => {
    switch (action.type) {
        case 'NOTIFICATION_PAGINATION':
            return {
                ...state,
                page: action.payload
            }
        default:
            return state
    }
}

export const getUnreadNotificationPagination = (state = initialUnreadPage, action) => {
    switch (action.type) {
        case 'UNREAD_NOTIFICATION_PAGINATION':
            return {
                ...state,
                page: action.payload
            }
        default:
            return state
    }
}

export const getNotificationsCount = (state = {}, action) => {
    switch (action.type) {
        case 'GET_NOTIFICATIONS_COUNT':
            return {
                ...state,
                notifications_count: action.payload,
            }
        case 'READ_ALL_NOTIFICATIONS':
            return{
                ...state,
                notifications_count: {...state.notifications_count, unread: 0}
            }
            default:
                return {
                    ...state
                }
    }
}