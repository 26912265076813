import { Grid,Skeleton,Box } from '@mui/material';
import React from 'react'
import {Link} from "react-router-dom";



const CategorieCol = ({icon,label,target}) => {
    label ??= "No Label";
    icon ??= "/icon/animalsPets.svg";
    // TODO on base request: target -> like href or something to redirect to onClick
    // http://ireviewapi.thesocialplus.com/icons/categories/educationTraining.svg
    function onError(e){
      e.target.src = "/icon/animalsPets.svg"; // default on error;
    }
  return (
    <Grid item xs={12} sm={6} md={3}>
      {target ?  <Link to={`/categories/${target}`} className="no-text-decoration">
        <div className="one-column-category-s"><img onError={onError} src={icon}/><Box sx={{marginLeft:"5px"}}>{label}</Box></div>
        </Link> : <Skeleton variant="rectangular" style={{width:"100%",height:"80px"}} />}
    </Grid>
  )
}

export default CategorieCol