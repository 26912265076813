import React from "react"
import { Link } from "react-router-dom"

const Unlock = () => {
    return(
        <Link to="/app/admin/subscription">
            <div className='locked-page'>
                <div>
                    <div className='locked-page-btn'>
                        <span>
                            <img className="lock-item-svg" src="/admin-images/lock.svg" alt="unlock" />
                        </span>
                        <button>Unlock</button>
                    </div>
                    <p>Take your experience to the next level with Premium.</p>
                    <span>Upgrade Now</span>
                </div>
            </div>
        </Link>
    )
}
export default Unlock