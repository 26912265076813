import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom"
import { Container } from "@mui/system";
import {Button} from "@mui/material";
import {GiHamburgerMenu} from "react-icons/gi"
import {MdOutlineClear} from "react-icons/md"
import OurProductSuite from "./NavBarParts/OurProductSuite";
import { useSelector, useDispatch } from "react-redux";
import UserDropdown from "./Admin/UserDropdown";
import { fetchUserInfo } from "../redux/actions/admin-index";
const mainBaseUrlDev = process.env.REACT_APP_DEV_BASE_URL

const Navbar = ({blueHeader}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const user = localStorage.getItem("user")
    const userState = useSelector(state => state.getUserInfo.user)

    return(
        <div className={`navbar-container navbar-container-mobile ${show ? 'active' : ''} ${blueHeader ? "blue-navbar-wrapper" : ""}`}>
            {loading && <div className="logout-div"><img src={"/admin-images/loading_ireview.gif"} alt="loader" /></div>}
            <Container  maxWidth="xl">
                <div className="navbar-main">
                    <Link to="/">
                        <img src={`${blueHeader ? '/admin-images/iReview_white.svg' : '/admin-images/iReview_logo.svg'}`} alt="logo"/>
                    </Link>
                    {show !== true ? <GiHamburgerMenu onClick={() => setShow(!show)}/> : <MdOutlineClear onClick={() => setShow(false)}/>}
                    <div className="navbar-elements">
                        <ul>
                            {/* <li><Link to="/categories">Categories</Link></li> */}
                            <li style={{position:"relative"}}>
                                <OurProductSuite/>
                            </li>
                            <li>
                                <a href="https://irevu.com">Review Management Tool</a>
                            </li>
                            {user ? 
                                <>
                                    <li className="li-as-btn" style={{marginRight: "10px"}}>
                                        <Link to="/app/admin">Dashboard</Link> 
                                    </li>
                                    <UserDropdown user={userState} setLoading={setLoading}/>
                                </>
                                : 
                                    <>
                                    <li className="li-outline-as-btn">
                                        <Link to="/login">Log In</Link>
                                    </li>
                                    <li className="li-as-btn">
                                        <Link to="/create">Sign up</Link>
                                    </li>
                                </>
                            }
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Navbar