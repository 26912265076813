import React, {useEffect, useState} from "react";
import { Modal, Slide, ClickAwayListener, Fade } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector, useDispatch } from "react-redux";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { fetchNewLocations, clearNewLocations, fetchUserInfo, fetchCompetitors, clearCompetitors } from "../../../../redux/actions/admin-index";
import { IoLocationOutline }  from "react-icons/io5";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import axios from "axios";
import { showAlert } from "../../../../redux/actions";
import AlertComponent from "../../../Helpers/Alert";

const AddLocation = ({open, setOpen, title}) => {
    const dispatch = useDispatch()
    const [value, setValue] = useState('')
    const [searching, setSearching] = useState(false)
    const [selected, setSelected] = useState('')
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setOpen(false)
        setValue('')
        setSelected('')
        setSearching(false)
        setLoading(false)
        dispatch(clearNewLocations())
    }

    const locations = useSelector(state => state.newLocations.locations)
    const location = useSelector(state => state.locationSelected.location)
    const selectedLoc = useSelector(state => state.groupLocationSelected.group_location)


    useEffect(() => {
        if(locations){
            setSearching(false)
        }
    }, [locations])

    const getLocations = (val) => {
        setValue(val)
        dispatch(fetchNewLocations(val))
        if(val.length > 2){
           setSearching(true)
        } else {
            setSearching(false)
            dispatch(clearNewLocations())
        }
    }

    const chooseLocation = (name, id) => {
        setValue(name)
        setSelected(id)
        dispatch(clearNewLocations())
    }

    const submitLocation = () => {
        setLoading(true)
        axios.post(`${baseUrlDev}locations/create`, {place_id: selected}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then(() => {
            setLoading(false)
            dispatch(fetchUserInfo())
            handleClose()
            dispatch(showAlert(true, "New Location Added Successsfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch(() => {
            setLoading(false)
        })

    }

    const submitCompetitorFunc = (id) => {
        setLoading(true)
        axios.post(`${baseUrlDev}locations/competitors/${id}`, {place_id: selected}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then(() => {
            setLoading(false)
            dispatch(clearCompetitors())
            dispatch(fetchCompetitors(id))
            handleClose()
            dispatch(showAlert(true, "New Competitor Added Successsfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch(() => {
            setLoading(false)
        })
    }
    const submitCompetitor = () => {
        if(selectedLoc && (!location || location == "all")){
            submitCompetitorFunc(selectedLoc)
        }
        if (location && location !== "all") {
            submitCompetitorFunc(location)
        }
    }

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-location-title"
            aria-describedby="modal-location-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className={`custom-modal-dialog`}>
                    <AlertComponent/>
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>Add New {title}</h5>
                        </div>
                    </div>
                    <div className="custom-modal-content">
                        <div className="scrollable-loc-container custom-scrollbar-vertical">
                            <div className="field-container">
                                <label>Add New {title}</label>
                                <input type="search" value={value}
                                    placeholder={`Search for ${title}`} 
                                    onChange={(e) => getLocations(e.target.value)}
                                />
                            </div>
                            {searching && <div className="field-container location-item">Searching...</div>}
                            <Fade in={locations && locations.length > 0}>
                                <div className="field-container">
                                    <div className="location-res-container custom-scrollbar-vertical">
                                        {locations && locations.length > 0 ? locations.map(loc => {
                                            return(
                                                <div key={loc.place_id} className="location-item location-item-hover" onClick={() => chooseLocation(loc.description, loc.place_id)}><IoLocationOutline/> {loc.description}</div>
                                            )
                                        })
                                        :<div className="field-container location-item location-item">No result found</div>
                                     }
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className="custom-modal-footer">
                        <LoadingButton
                            style={{paddingLeft: loading && "30px"}}
                            className="save-btn"
                            onClick={() => title === "Location" ? submitLocation() : submitCompetitor()}
                            loading={loading}
                        >
                            Save
                        </LoadingButton>
                        <button className="close-btn" onClick={() => handleClose()}>Close</button>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}
export default AddLocation