export const getMarketingMaterialsOptions = (state = {}, action) => {
    switch (action.type) {
        case 'GET_MARKETING_MATERIAL_OPTIONS':
            return {
                ...state,
                marketing_material_options: action.payload
            }
        case 'CLEAR_MARKETING_MATERIAL_OPTIONS':
            return {
                ...state,
                marketing_material_options: undefined
            }
        case 'UPLOAD_IMAGES': {
            const { id, imageType, imageUrl, path } = action.payload;
            const updatedData = state.marketing_material_options.data.map(material => {
                if (material.id === id) {
                    const updatedCartElement = material.cart_element ? { ...material.cart_element } : { previews: {} };
                    const updatedPreviews = updatedCartElement.previews ? { ...updatedCartElement.previews } : {};
                    updatedPreviews[imageType] = imageUrl;
                    updatedPreviews[`${imageType}_path`] = path;
                    updatedCartElement.previews = updatedPreviews;
                    return {
                        ...material,
                        cart_element: updatedCartElement
                    };
                }
                return material;
            });
            return {
                ...state,
                marketing_material_options: {
                    ...state.marketing_material_options,
                    data: updatedData
                }
            };
        }
        case 'ADD_CARD_ID': {
            const { item_id, card_id, price } = action.payload;
            const updatedData = state.marketing_material_options.data.map(material => {
                if (material.id === item_id) {
                    const updatedCartElement = material.cart_element ? { ...material.cart_element } : { id: "", price: 0 };
                    updatedCartElement.id = card_id;
                    updatedCartElement.total_with_options = price;
                    return {
                        ...material,
                        cart_element: updatedCartElement
                    };
                }
                return material;
            });
            return {
                ...state,
                marketing_material_options: {
                    ...state.marketing_material_options,
                    data: updatedData
                }
            };
        }
        case 'CHANGE_POSITION':
            const { id_type, positionOptionId } = action.payload;
            const updatedDataPos = state.marketing_material_options.data.map(material => {
                if (material.id === id_type) {
                    let updatedCartElement = {}
                    if (material.cart_element) {
                        updatedCartElement = {
                            ...material.cart_element,
                            options: {
                                ...material.cart_element.options,
                                position: {
                                    option_id: positionOptionId,
                                    quantity: null
                                }
                            }
                        };
                    } else {
                        updatedCartElement = {
                            options: {
                                position: {
                                    option_id: positionOptionId,
                                    quantity: null
                                }
                            }
                        };
                    }
                    return {
                        ...material,
                        cart_element: updatedCartElement
                    };
                }
                return material;
            });
            return {
                ...state,
                marketing_material_options: {
                    ...state.marketing_material_options,
                    data: updatedDataPos
                }
            };
        default:
            return {
                ...state
            }
    }
}

export const getPreUplodedImagesReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_PRE_UPLOADED_IMAGES':
            return {
                ...state,
                images: action.payload
            }
        case 'ADD_PRE_UPLOADED_IMAGES': {
            const updatedImages = [...state.images.data, action.payload];
            return {
                ...state,
                images: { data: updatedImages }
            }
        }
        case 'CLEAR_PRE_UPLOADED_IMAGES':
            return {
                ...state,
                images: undefined
            }
        case 'DELETE_PRE_UPLOADED_IMAGES':
            const idToDelete = action.payload;
            if (!idToDelete) {
                return state;
            }
            const updatedImages = state.images.data.filter(image => image.id !== idToDelete);
            return {
                ...state,
                images: { data: updatedImages }
            }
        default:
            return {
                ...state
            }
    }
}

export const getDiscoverImagesReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_DISCOVER_IMAGES':
            return {
                ...state,
                discover_images: action.payload
            }
        case 'CLEAR_DISCOVER_IMAGES':
            return {
                ...state,
                discover_images: undefined
            }
        default:
            return {
                ...state
            }
    }
}

export const getCartReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_CART':
            return {
                ...state,
                cart: action.payload
            }
        case 'CLEAR_CART':
            return {
                ...state,
                cart: undefined
            }
        case 'EDIT_IMAGES': {
            const { id, imageType, imageUrl } = action.payload;
            const itemToUpdate = state.cart.data.find(item => item.id == id)
            if (itemToUpdate) {
                const updatedPreviews = {
                    ...itemToUpdate.previews,
                    [imageType]: imageUrl
                };
                const updatedItem = {
                    ...itemToUpdate,
                    previews: updatedPreviews
                };
                const updatedData = state.cart.data.map(item =>
                    item.id === id ? updatedItem : item
                );
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        data: updatedData
                    }
                };
            }
        }
        case 'EDIT_QUANTITY_ITEM': {
            const { id, quantity } = action.payload;
            const itemIndex = state.cart.data.findIndex(item => item.id == id);

            const updatedItem = {
                ...state.cart.data[itemIndex],
                quantity: quantity
            };
            const updatedData = [
                ...state.cart.data.slice(0, itemIndex),
                updatedItem,
                ...state.cart.data.slice(itemIndex + 1)
            ];

            if (itemIndex !== -1) {
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        data: updatedData
                    }
                };
            }
            return state;
        }
        case 'EDIT_SUBTOTAL_ITEM': {
            const { id, total_with_options } = action.payload;
            const itemIndex = state.cart.data.findIndex(item => item.id == id);

            const updatedItem = {
                ...state.cart.data[itemIndex],
                total_with_options: total_with_options
            };
            const updatedData = [
                ...state.cart.data.slice(0, itemIndex),
                updatedItem,
                ...state.cart.data.slice(itemIndex + 1)
            ];

            if (itemIndex !== -1) {
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        data: updatedData
                    }
                };
            }
            return state;
        }
        case 'EDIT_CART_TOTAL': {
            const { summary } = action.payload;

            if (summary) {
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        summary: summary
                    }
                };
            }
            return state;
        }
        case 'EDIT_CART_OPTIONS': {
            const { id, options } = action.payload;
            const itemIndex = state.cart.data.findIndex(item => item.id == id);
            const updatedItem = {
                ...state.cart.data[itemIndex],
                options: options
            };
            const updatedData = [
                ...state.cart.data.slice(0, itemIndex),
                updatedItem,
                ...state.cart.data.slice(itemIndex + 1)
            ];

            if (itemIndex !== -1) {
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        data: updatedData
                    }
                }
            };
            return state;
        }
        case 'DELETE_CART_ITEM': {
            return {
                ...state,
                cart: { "data": state.cart.data.filter(item => item.id != action.payload.id), "summary": action.payload.summary }
            }
        }
        default:
            return {
                ...state
            }
    }
}


export const getShippingServicesReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_SHIPPING_SERVICES':
            return {
                ...state,
                shipping_services: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
export const getOrdersReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_ORDERS':
            return {
                ...state,
                orders: action.payload
            }
        case 'CLEAR_ORDERS':
            return {
                ...state,
                orders: undefined
            }
        default:
            return {
                ...state
            }
    }
}
export const getOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_ORDER':
            return {
                ...state,
                order: action.payload.data
            }
        case 'CLEAR_ORDER':
            return {
                ...state,
                order: undefined
            }
        default:
            return {
                ...state
            }
    }
}
export const getShippingDefaultAdressReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_SHIPPING_DEFAULT_ADDRESS':
            return {
                ...state,
                address: action.payload
            }
        case 'GET_BILLING_DEFAULT_ADDRESS':
            return {
                ...state,
                billing_address: action.payload
            }
        case 'CLEAR_ORDER':
            return {
                ...state,
                address: undefined
            }
        default:
            return {
                ...state
            }
    }
}
export const getBillingDefaultAdressReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_BILLING_DEFAULT_ADDRESS':
            return {
                ...state,
                address: action.payload
            }
        case 'CLEAR_DEFAULT_BILLING_ADDRESS':
            return {
                ...state,
                address: undefined
            }
        default:
            return {
                ...state
            }
    }
}
export const getCardMarketingMaterialReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_CARD_MARKETING_MATERIAL':
            return {
                ...state,
                card: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const checkForImageReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_IMAGE_CHECK':
            return {
                ...state,
                check: action.payload
            }
        case 'CLEAR_IMAGE_CHECK':
            return {
                ...state,
                check: undefined
            }
        default:
            return {
                ...state
            }
    }
}