export const autoPilot = (state = [] ,action) => {
    switch(action.type){
        case 'GET_DASHBOARD_AUTOPILOT':
            return{
                ...state,
                autopilot_dashboard: action.payload
            }
        case 'CLEAR_DASHBOARD_AUTOPILOT':
            return{
                ...state,
                autopilot_dashboard: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const autoPilotSettings = (state = [] ,action) => {
    switch(action.type){
        case 'GET_AUTOPILOT_SETTINGS':
            return{
                ...state,
                autopilot_settings: action.payload
            }
        case 'CLEAR_AUTOPILOT_SETTINGS':
            return{
                ...state,
                autopilot_settings: undefined
            }
        default:
            return{
                ...state
            }
    }
}