import React,{useState,useEffect} from 'react'


import {Modal,Box, TextField,Button,Alert,Divider} from '@mui/material';

import {useSelector} from 'react-redux';
import axios from 'axios';
import {mainBaseUrl} from '../../Helpers/baseUrl';
import CreateCompany from '../../Companies/createCompany';
import AlertComponent from '../../Helpers/Alert';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius:"8px"
  };

const MissingAbout = () => {
    const companyDataSelect = useSelector(state => state.companieAboutReducer.companie);
    const [isOpen,setIsOpen] = useState(false);
    const [formData,setFormData] = useState({
        place_id: companyDataSelect?.place_id,
        email:'',
        phone: '',
    });

    const [statusRequest,setStatusRequest] = useState({
        type: null,
        message:""
    });

    useEffect(() => {
        if(typeof companyDataSelect?.place_id !== 'undefined'){
            setIsOpen(true);
        }
        setFormData({...formData,place_id:companyDataSelect?.place_id});
        
    }, [companyDataSelect]);



    function sendSubscribeCompany(){
        setStatusRequest({type:null,message:"",input:null});
        let fd = new FormData();
        if(!formData.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
            setStatusRequest({type:"error",message:"Incorrect email format!"})
            return;
        }
        for(let [key,value] of Object.entries(formData)){
            fd.append(key,value);
        }
        

        axios({
            method:"POST",
            data: fd,
            url: mainBaseUrl + "/api/v1/register/company/subscribe"
        }).then((response)=>{
            if(response.status === 200){
                setStatusRequest({type:"success",message:response.data.message});
                setFormData((pState)=>{
                    return {...pState,phone:"",email:""}
                })
            }else{
                setStatusRequest({type:"error",message:"Something went wrong!"});
            }
        }).catch(error=>{
            setStatusRequest({type:"error",message:"Something went wrong , check network connection!"});
        });
    }

  return (
    <>
    <AlertComponent/>
    <Modal
    open={isOpen}
    onClose={()=>{
        return;
    }}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
        <Box sx={style} className='about-section'>
            <div>
                <h2 style={{marginTop:"0px",textAlign: formData.place_id === "" ? "center" :"left"}}>Company not found!</h2>
                <hr/>
                <Box sx={{textAlign:"center"}}>
                    {formData.place_id === "" && "This company was not found in our database! Please, try again later"} 
                </Box>
                {formData.place_id === "" && <>
                    <Divider sx={{my:3}}>OR</Divider>
                    
                    <div className="list-business-button">
                        <CreateCompany/>
                    </div>
                </>}
            </div>
            { formData.place_id !== "" && <>
                <Box>
                    <TextField value={formData.email} onChange={(e)=> setFormData((pState)=>{
                        return {...pState,email:e.target.value}
                    })} fullWidth variant="outlined" label="Email" />
                </Box>

                <Box sx={{my:2}}>
                    <TextField type="number" value={formData.phone} onChange={(e)=>{
                        setFormData((pState)=>{
                            return {...pState,phone:e.target.value}
                        })
                    }} fullWidth variant="outlined" label="Phone Number (Optional)" />
                </Box>
                <Box sx={{mb:2}}>
                    {statusRequest.type && <Alert severity={statusRequest.type}>{statusRequest.message}</Alert>}
                </Box>
                <Box>
                    <Button 
                    onClick={(e)=>{
                        sendSubscribeCompany();
                    }} size="large" fullWidth variant="contained">Subscribe</Button>
                </Box>
                <Divider sx={{my:3}}>OR</Divider>
                <div className="list-business-button">
                        <CreateCompany/>
                </div>
            </>}
        </Box>
       
    </Modal>
    </>
    
  )
}

export default MissingAbout