export async function myLocation(ip = 'auto'){
    let proxy = 'https://corsnova.vercel.app/?url=';
    let myIp = ip;
    if(ip === 'auto'){
      myIp = await fetch('https://api64.ipify.org/').then(r=>r.text());
    }
    let url = `https://ipinfo.io/${myIp}?token=595d326f236577`;
    let jsonData = await fetch(proxy+url).then(response => response.json());
    if(!jsonData?.loc){
        return false;
    }
    return {
        lon: jsonData.loc.split(',')[1],
        lat: jsonData.loc.split(',')[0],
        ...jsonData
    }
}