import React, { useEffect, useRef, useState } from "react"
import { Collapse } from "@mui/material"
import { BsCheck2All } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { readAllNotifications, fetchNotificationsCount } from "../../../redux/actions/admin-index";
import AppUrl from "../../Helpers/appUrl";
import UnreadNotifications from "./unread";
import AllNotifications from "./all_notifications";

const NewNotifications = ({open, setOpen}) => {
    const dispatch = useDispatch()
    const [collapsedHeight, setCollapsedHeight] = useState(true)
    const [activePanel, setActivePanel] = useState(1)
    const [first_time_array, setFirstTimeArray] = useState(false)
    const [panel1ShowedCount, setPanel1ShowedCount] = useState(0)
    const [panel2ShowedCount, setPanel2ShowedCount] = useState(0)
    const notifications = useSelector(state => state.getNotifications?.notifications)
    const notifications_unread = useSelector(state => state.getNotificationsUnread?.notifications)
    const count = useSelector(state => state.getNotificationsCount.notifications_count)
    const togglePanels = () => {
        notifications_unread?.length === 0 ? setActivePanel(2) : setActivePanel(1);
    }

    
    useEffect(() => {
        if(open){
            if (activePanel == 1) {
                setPanel1ShowedCount(count => count + 1);
            }
    
            if (activePanel == 2) {
                setPanel2ShowedCount(count => count + 1);
            }
        }
    }, [activePanel, open]);

    useEffect(() => {
        if(first_time_array){
            togglePanels()
        }
    }, [first_time_array]);

    const readAll = () => {
        dispatch(readAllNotifications())
        AppUrl.postData(
            `notifications/read/all`,
            {},
            {},
            (response) => dispatch(fetchNotificationsCount()),
        );
    }
    return(
        <Collapse in={open}>
            <div className="notifications_wrap_container">
                <div className="notifications_wrap_title">
                    <h5>Notifications</h5>
                    <button onClick={() => setCollapsedHeight(!collapsedHeight)}>{collapsedHeight ? "View More" : "View Less"}</button>
                </div>
                <div className="notifications_wrap_tabs">
                    <button 
                        onClick={() => setActivePanel(1)} 
                        className={activePanel === 1 ? "active" : ""}>
                        Unread {count && <span>{count.unread}</span>}
                    </button>
                    <button 
                        onClick={() => setActivePanel(2)} 
                        className={activePanel === 2 ? "active" : ""}>
                        All {count && <span>{count.notifications}</span>}
                    </button>
                </div>
                {activePanel == 1 ? 
                <UnreadNotifications 
                    setFirstTimeArray={setFirstTimeArray} 
                    collapsedHeight={collapsedHeight} 
                    open={open} 
                    panel={panel1ShowedCount}
                    setOpen={setOpen}
                /> : 
                <AllNotifications 
                    setFirstTimeArray={setFirstTimeArray} 
                    collapsedHeight={collapsedHeight}
                    open={open} 
                    panel={panel2ShowedCount}
                    setOpen={setOpen}
                 />
                 }  
                <div className="notifications_wrap_bottom">
                    {notifications_unread?.length > 0 && <button onClick={() => readAll()}><BsCheck2All /> Mark all as read</button>}
                </div>
            </div>
        </Collapse>
    )
}
export default NewNotifications