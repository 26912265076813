export const getDownloadsReducer = (state = [] ,action) => {
    switch(action.type){
        case 'GET_DOWNLOADS':
            return{
                ...state,
                downloads: action.payload
            }
        case 'CLEAR_DOWNLOADS':
            return{
                ...state,
                downloads: undefined
            }
        case 'DELETE_DOWNLOAD':
            return{
                ...state,
                downloads: {"data": state.downloads.data.filter(item => item.id != action.payload)}
            }
        default:
            return{
                ...state
            }
    }
}