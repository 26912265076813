import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal } from "@mui/material"
import {MdClear} from "react-icons/md"
import { closeVideoModal } from "../../../../redux/actions/admin-index"

const YoutubeVideo = () => {
    const dispatch = useDispatch()

    let videoSrc = useSelector(state => state.ShowYoutubeContent.path)
    let open = useSelector(state => state.getVideo.show)

    const handleClose = () => {
        dispatch(closeVideoModal())
    }

    return(
        <Modal 
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-youtube-title"
            aria-describedby="modal-youtube-description"
        >
            <div className="iframe-holder">
                <button onClick={() => handleClose()}><MdClear/></button>
                <iframe 
                    frameBorder={0}
                    src={videoSrc} 
                    width="100%" 
                    height="100%" 
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title='video'>
                </iframe>
            </div>
        </Modal>
    )
}
export default YoutubeVideo