import React from "react";
import { Container } from "@mui/system";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

//icons
import { AiOutlineInstagram, AiFillFacebook, AiFillTwitterCircle, AiFillLinkedin } from "react-icons/ai";


const Footer = () => {
    return (
        <div className="app-footer">
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                        <div className="app-footer-item">
                            <h4>Menu</h4>
                            <ul>
                                <li>
                                    <a href="https://irevu.com/">Home</a>
                                </li>
                                <li>
                                    <a href="https://irevu.com/listings">Listing</a>
                                </li>
                                <li>
                                    <a href="https://irevu.com/pricing">Pricing</a>
                                </li>
                                <li>
                                    <a href="https://irevu.com/reviews">Reviews</a>
                                </li>
                                <li>
                                    <a href="https://irevu.com/service">Full Service</a>
                                </li>
                                <li>
                                    <a href="https://irevu.com/about/">About Us</a>
                                </li>
                                <li>
                                    <a href="https://irevu.com/contact/">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <div className="app-footer-item">
                            <h4>Visit</h4>
                            <ul>
                                <li>THE SOCIAL PLUS HOLDING - DBA iReview</li>
                                <li>FRISCO, TX</li>
                                <li>hello@ireview.com</li>
                                <li>(888) 744-7388</li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <div className="app-footer-item">
                            <h4>Legal</h4>
                            <ul>
                                <li>
                                    <a href="https://irevu.com/privacy/">Privacy</a>
                                </li>
                                <li>
                                    <a href="https://irevu.com/terms/">Terms</a>
                                </li>
                                <li>
                                    <a href="https://irevu.com/refund/">Refund</a>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <div className="app-footer-item">
                            <h4>Social Media</h4>
                            <ul className="social-media-holder">
                                <li>
                                    <a href="https://www.facebook.com/ireviewcom/" target="_blank" rel="noreferrer">
                                        <img src="/image/social-fb-logo.svg" alt="facebook" /> Facebook
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/ireview_official/" target="_blank" rel="noreferrer">
                                        <img src="/image/social-insta-logo.svg" alt="instagram" /> Instagram
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/ireviewcom" target="_blank" rel="noreferrer">
                                        <img src="/admin-images/new-twitter-logo.svg" alt="twitter" /> Twitter
                                    </a>
                                </li>
                                <li>
                                    <a href="https://ireviewofficial.medium.com/" target="_blank" rel="noreferrer">
                                        <img src="/image/social-medium-logo.svg" alt="medium" /> Medium
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/ireviewofficial/" target="_blank" rel="noreferrer">
                                        <img src="/image/social-linkedin-logo.svg" alt="linkedin" />  Linkedin
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCE2ZZvDC1Er_K82P6Z2gqtA" target="_blank" rel="noreferrer">
                                        <img src="/image/social-youtube-logo.svg" alt="youtube" />  Youtube
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
export default Footer;