import React, {useState} from "react";
import {TextareaAutosize, Grid, Alert, Stepper, Step, StepLabel, Select, MenuItem, FormControl, InputLabel} from "@mui/material";
import { useDispatch } from "react-redux/es/exports";
import { baseUrl } from "../Helpers/baseUrl";
import axios from "axios";



//My imports
import { ValidationTextField, ValidationSelectField } from "../Helpers/ValidationTextField";
import { CustomTextField } from "../Helpers/CustomTextField";
import { QontoStepIcon, QontoConnector } from "../Helpers/customStep";
import { showAlert } from "../../redux/actions";
import { countries } from "../Helpers/countries";


const CompanyDetails = ({company_details, clicked, setOpen, setSearch, setIsSearching}) => {
    const dispatch = useDispatch()
    let place_id = company_details.place_id

    let [activeStep, setActiveStep] = useState(0)
    let [error, setError] = useState('')
    let [name, setName] = useState(company_details?.name ?? "")
    let [email, setEmail] = useState(company_details.email)
    let [phone, setPhone] = useState(company_details.phone)
    let [website, setWebsite] = useState(company_details.website)
    let [address, setAddress] = useState(company_details.formatted_address)
    let [city, setCity] = useState(company_details.parsed_address && company_details.parsed_address.city)
    let [state, setState] = useState(company_details.parsed_address && company_details.parsed_address.state)
    let [country, setCountry] = useState(company_details.parsed_address && company_details.parsed_address.country)
    let [zip, setZip] = useState(company_details.parsed_address && company_details.parsed_address.zip)
    let [longitude, setLongitude] = useState(company_details.longitude)
    let [latitude, setLatitude] = useState(company_details.latitude)
    let [logo, setLogo] = useState(company_details.logo)
    let [opening_hours, setopening_hours] = useState(company_details.opening_hours)
    let [banner, setBanner] = useState(company_details.banner)
    let [file, setFile] = useState("");

    let [alertMsg, setAlertMsg] = useState({
        type:null,
        msg:null
    });

    const submitForm = (e) => {
        e.preventDefault();
        let working_hours = opening_hours.toString()

        var formData = new FormData();
        formData.append("place_id", place_id)
        formData.append("name", name)
        formData.append("phone", phone)
        formData.append("website", website)
        formData.append("address", address)
        formData.append("city", city)
        formData.append("state", state)
        formData.append("country", country)
        formData.append("zip", zip)
        formData.append("longitude", longitude)
        formData.append("latitude", longitude)
        formData.append("logo", file)
        formData.append("working_hours", working_hours)
        formData.append("banner", banner)
        formData.append("email", email)
        
        axios.post(`${baseUrl}register/company`, formData
        ).then(() => {
            setOpen(false)
            setSearch(false)
            setIsSearching(true)
            dispatch(showAlert(true, "Your company will soon be verified and imported"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch((err) => {
            setError(err.response.data.errors.place_id && err.response.data.errors.place_id[0])
        })
    }

    const goBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setError("");
    };
    const goNext =  () => {
        if(activeStep === 0 && (!email || !name)){
            setError("Please fill required fields");
            return false
        } else if (activeStep === 1 && (!city || !country || !state || !zip || !address)){
            setError("Please fill required fields");
            return false
        } else if (activeStep === 2 && (!longitude || !latitude)){
            setError("Please fill required fields");
            return false
        } 
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setError("");
    };
    const logoChange = (e) => {
          const files = Array.from(e.target.files)
          setLogo(URL.createObjectURL(files[0]))
          setFile(files[0])
    }

    return(
        <>
        <div className="company-modal-body pt-25">
            {error ? <Alert severity="error" className="mb-50 custom-error-alert">{error}</Alert> : ''}
            <form onSubmit={(e) => submitForm(e)}>
                <Stepper activeStep={activeStep} className="mb-50" connector={<QontoConnector/>}>
                    <Step><StepLabel StepIconComponent={QontoStepIcon}></StepLabel></Step>
                    <Step><StepLabel StepIconComponent={QontoStepIcon}></StepLabel></Step>
                    <Step><StepLabel StepIconComponent={QontoStepIcon}></StepLabel></Step>
                </Stepper>
              <Grid container spacing={3}>
                {activeStep === 0 ? 
                    <>
                        <Grid item xs={12} md={6}>
                            <ValidationTextField
                                key={`name-${place_id}`}
                                required
                                id="name"
                                label="Company Name"
                                style={{width: '100%'}}
                                defaultValue={name && name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ValidationTextField
                                key={`email-${place_id}`}
                                required
                                id="email"
                                label="Company Email"
                                style={{width: '100%'}}
                                defaultValue={email && email}
                                onChange={(e) => setEmail(e.target.value)}
                                inputProps={{ inputMode: 'email'}} 
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomTextField
                                id="phone_number"
                                label="Company Phone Number"
                                style={{width: '100%'}}
                                defaultValue={phone && phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomTextField
                                id="website"
                                label="Company Website"
                                style={{width: '100%'}}
                                defaultValue={website && website}
                                onChange={(e) => setWebsite(e.target.value)}
                            />
                        </Grid>
                    </>
                    : activeStep === 1 ?
                    <>
                        <Grid item xs={12} md={6}>
                            <ValidationTextField
                                required
                                id="address"
                                label="Company Address"
                                style={{width: '100%'}}
                                defaultValue={address && address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ValidationTextField
                                required
                                id="city"
                                label="Company City"
                                style={{width: '100%'}}
                                defaultValue={city && city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ValidationTextField
                                required
                                id="state"
                                label="Company State"
                                style={{width: '100%'}}
                                defaultValue={state && state}
                                onChange={(e) => setState(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <ValidationTextField
                                required
                                id="country"
                                label="Company Country"
                                style={{width: '100%'}}
                                defaultValue={country && country}
                                onChange={(e) => setCountry(e.target.value)}
                            /> */}
                              <FormControl className={country.length > 0 ? 'required-select-filled' : "required-select"} required style={{width: '100%'}}>
                                <InputLabel className="bg-white" id="select-country-label">Company Country</InputLabel>
                                <Select 
                                    labelId="select-state-label"
                                    id="demo-simple-select-error"
                                    style={{width: '100%', height: '50px'}}
                                    value={country && country.toLowerCase()}
                                    label="Country"
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                    onChange={(e) => setCountry(e.target.value)}
                                    input={<ValidationSelectField/>}
                                    >
                                    <MenuItem className="mym"value=""><em>None</em></MenuItem>
                                    {countries.map(country => {
                                        return(
                                            <MenuItem key={country.code3A} value={country.name.toLowerCase()}>{country.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>   
                        <Grid item xs={12} md={6}>
                            <ValidationTextField
                                required
                                inputProps={{ inputMode: 'numeric'}}
                                id="zip"
                                label="Company Zip"
                                style={{width: '100%'}}
                                defaultValue={zip && zip}
                                onChange={(e) => setZip(e.target.value)}
                            />
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={12} md={6}>
                            <ValidationTextField
                                    required
                                    inputProps={{ inputMode: 'numeric'}}
                                    id="longitude"
                                    label="Company Longitude"
                                    style={{width: '100%'}}
                                    defaultValue={longitude && longitude}
                                    onChange={(e) => setLongitude(e.target.value)}
                                />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ValidationTextField
                                required
                                inputProps={{ inputMode: 'numeric'}}
                                id="latitude"
                                label="Company Latitude"
                                style={{width: '100%'}}
                                defaultValue={latitude && latitude}
                                onChange={(e) => setLatitude(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomTextField
                                id="banner"
                                label="Company Banner"
                                style={{width: '100%'}}
                                defaultValue={banner && banner}
                                onChange={(e) => setBanner(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="custom-input-file">
                                <img src={logo ? logo : "/admin-images/no-logo.png"} alt={logo}/>
                                <input type="file" id="company-logo" onChange={(e) => logoChange(e)}/>
                                <label htmlFor="company-logo">
                                    Choose file
                                </label>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <TextareaAutosize
                                className="custom-text-area"
                                id="working_hours"
                                label="Working Hours"
                                style={{width: '100%'}}
                                defaultValue={opening_hours}
                                onChange={(e) => setopening_hours(e.target.value)}
                            />
                        </Grid>
                    </>
                    }
                </Grid>
                <div className="d-flex justify-content-between align-items-center">
                    {(activeStep === 2 || activeStep === 1) && <button type="button" className="grey-button" onClick={() => goBack()}>Back</button>}
                    {(activeStep === 0 || activeStep === 1) && <button type="button" className="blue-button ml-auto"  onClick={() => goNext()}>Next</button>}
                    {activeStep === 2 && <button type="submit" className="blue-button">Create Company</button>}
                    
                </div>
                
            </form>
        </div>
        </>
    )
}
export default CompanyDetails