import React from "react"
import {Alert, Grow } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import TickIcon from '/admin-images/tick-circle-success-alert.svg';

const CustomAlertIcon = (props) => {
    return <img src="/admin-images/tick-circle-success-alert.svg" alt="Custom Icon" {...props} />;
};

const AlertComponent = () => {
    const {t} = useTranslation()
    let showAlert = useSelector(state => state.alertReducer.show);
    let textAlert = useSelector(state => state.alertReducer.text);
    return(
        <Grow in={showAlert}><Alert severity="success" className="fixed-alert-right" iconMapping={{ success: <CustomAlertIcon /> }}>{t(textAlert)}</Alert></Grow>
    )
}

export default AlertComponent