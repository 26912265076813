import axios from 'axios'
import { mainBaseUrl, mainBaseUrlDev } from '../../Helpers/baseUrl';

const expires = new Date();
expires.setDate(expires.getDate() + 14);

export function apiFetchToken(token,callback, path) {
    localStorage.removeItem("location");
    localStorage.removeItem("location_of_group");
    axios({
        method: 'get',
        url: `${path !== "/admin/login" ? mainBaseUrlDev : mainBaseUrl}/api/forms/auth/login?token=${token}`,
    }).then((response) => {
        document.cookie = `irw_token=${response.data.user.i_token}; domain=.irevu.com; expires=${expires}; path=/`;
        callback(null,response.data)
    }).catch((error) => {
        callback(error,null)
    });
}

