const initialState = {
    open: false,
    message: ""
};

export const exportedAlertReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'OPEN_EXPORTED_ALERT':
        return {
          ...state,
          open: action.payload.open,
          message: action.payload.message
        }
      default:
        return state;
    }
};
