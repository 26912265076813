import { styled, InputBase} from "@mui/material";

const SignUpSelect = styled(InputBase)(({ theme }) => ({
    height: "44px",
    '& .MuiInputBase-input': {
        borderRadius: 7,
        position: 'relative',
        backgroundColor: "#F8F8F8",
        border: `1px solid rgba(166,178,205,.25)`,
        fontSize: 16,
        padding: '6px 26px 6px 12px',
        color: "#25313C",
        boxSizing: "border-box",
        height: "100%",
        lineHeight: 2,
        boxShadow: "0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%)",
    },
}));
export default SignUpSelect