export const newLocations = (state = [] ,action) => {
    switch(action.type){
        case 'GET_NEW_LOCATION':
            return{
                ...state,
                locations: action.payload
            }
        case 'CLEAR_NEW_LOCATIONS':
            return{
                ...state,
                locations: []
            }
        default:
            return{
                ...state
            }
    }
}

export const locationsTa = (state = [] ,action) => {
    switch(action.type){
        case 'GET_LOCATION_TRIPADVISOR':
            return{
                ...state,
                locations: action.payload
            }
        case 'CLEAR_LOCATION_TRIPADVISOR':
            return{
                ...state,
                locations: []
            }
        default:
            return{
                ...state
            }
    }
}