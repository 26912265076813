import React from "react"
import {useSelector} from "react-redux"

const ThankYou = () => {
    let message = useSelector(state => state.getThankYouMessage.thankyou)
    return(
        <div className="thank-you-page">
            <h1>{message ? message : 'Thank You For Your Submission!'}</h1>
        </div>
    )
}
export default ThankYou