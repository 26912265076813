import React, {useRef, useState, useEffect} from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchNotifications, notificationPagination } from "../../../redux/actions/admin-index"
import { Skeleton } from "@mui/material"
import NewNotificationItem from "./new_notification_item"
import Spinner from "../../Helpers/Spinner";


const style = {
    wrapper: {
        padding: "10px"
    },
    first_item: {
        backgroundColor: "#EFF2F7",
        transform: "unset",
        height: "15px",
        width: "80%",
        marginBottom: "7px"
    },
    second_item: {
        backgroundColor: "#EFF2F7",
        transform: "unset",
        height: "15px",
        width: "60%",
        marginBottom: "7px"
    },
    third_item: {
        backgroundColor: "#EFF2F7",
        transform: "unset",
        height: "15px",
        width: "40%",
        marginBottom: "7px"
    }
}
const AllNotifications = ({collapsedHeight, open, setFirstTimeArray, panel, setOpen}) => {
    const dispatch = useDispatch()
    const modalRef = useRef()
    const [notification_with_anim, setNotificationsWithAnim] = useState()

    const notifications = useSelector(state => state.getNotifications?.notifications)
    const notifications_pagination = useSelector(state => state.getNotificationPagination?.page)
    const load_more = useSelector(state => state.getNotifications?.loading_more)

    useEffect(() => {
        if(notifications && open){
            setNotificationsWithAnim(notifications)
            setFirstTimeArray(true)
        }
    }, [notifications, open])

    const handleLoadMore = () => {
        dispatch(fetchNotifications(notifications_pagination, true, () => dispatch(notificationPagination(notifications_pagination + 1))));
    };

    const scrollListNotifications = (e) => {
        if (modalRef.current) {
            const { scrollTop, clientHeight, scrollHeight } = modalRef.current;
            const buffer = 1; 
            if (scrollTop + clientHeight >= scrollHeight - buffer && load_more) {
                handleLoadMore()
            }
        }
    }

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.addEventListener('scroll', scrollListNotifications);
        }
        return () => {
            if (modalRef.current) {
                modalRef.current.removeEventListener('scroll', scrollListNotifications);
            }
        }

    }, [notifications])

    return(
        <div className="notifications_wrap_list">
            <div className="max-height-noty custom-scrollbar-vertical" ref={modalRef} style={{ height: collapsedHeight ? "calc(100vh - 545px)" : "calc(100vh - 220px)" }}>
            {notification_with_anim ?
                <>
                    {notification_with_anim.length > 0 ?
                    <>
                        {notification_with_anim.map((notification, index) => {
                            return (
                                <NewNotificationItem notification={notification} key={index} delay={ panel == 1 ? (index >= 15 ? '0s' : `${index * 0.2}s`) : ''} setOpen={setOpen}/>
                            )
                        })}
                        {load_more && <div style={{paddingBottom: 15}}><Spinner width={15}/></div>}
                    </>  : <p>You're up to date with all your notifications.</p>
                    }  
                
                </>
                : 
                <div className="max-height-noty custom-scrollbar-vertical">
                    {[...Array(3)].map((el, index) => {
                            return (
                                <div style={style.wrapper} key={`skeleton-noty-${index}`}>
                                    <Skeleton animation="wave" style={style.first_item}/>
                                    <Skeleton animation="wave" style={style.second_item}/>
                                    <Skeleton animation="wave" style={style.third_item}/>
                                </div>
                            )
                        })
                    }
                </div>
                }
            </div>
        </div>
    )
}
export default AllNotifications