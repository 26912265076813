import {
    styled
} from '@mui/material/styles';
import {
    TextField,
    Select
} from "@mui/material";

export const ValidationTextField = styled(TextField)({
    '& input:valid + fieldset': {
        borderColor: '#198754',
        borderWidth: 1,
        height: '50px',
        fontFamily: "Poppins-Regular",
        fontSize: '14px'
    },
    '& input:invalid + fieldset': {
        borderColor: '#f44250',
        borderWidth: 1,
        height: '50px',
        fontFamily: "Poppins-Regular",
        fontSize: '14px'
    },
    '& input:focus + fieldset': {
        borderColor: '#00B4E5'
    },
    '& input:optional + fieldset': {
        borderColor: "#0000003b"
    },
});

export const ValidationTextFieldNoLabel = styled(TextField)({
  '& input:valid + fieldset': {
      border: 'none',
      boxShadow: 'none',
      height: '100%',
      fontFamily: "Poppins-Regular",
      fontSize: '14px',
      borderRadius: "8px",
      display: "block"
  },
  '& input': {
      border: '1px solid #F3F3F3',
      boxShadow: '0px 1px 2px rgb(16 24 40 / 5%)',
      height: '44px',
      fontFamily: "Poppins-Regular",
      fontSize: '14px',
      borderRadius: "8px",
      display: "block",
      boxSizing: 'border-box',
      background: "#fff"
  },
  '& input:invalid + fieldset': {
      borderColor: '#f44250',
  },
  '& input:hover + fieldset': {
      borderColor: '#F3F3F3',
  },
  '& input:focus + fieldset': {
      borderColor: '#00B4E5'
  },
});

// export const ValidationSelectField = styled(Select)(({
//     theme,
// }) => ({
//     "&.MuiOutlinedInput-root": {
//         "&.Mui-focused:valid fieldset": {
//             borderColor: '#198754'
//         },
//         "&.Mui-focused:focus fieldset": {
//             borderColor: '#00B4E5'
//         },
//         '&.Mui-focused:invalid + fieldset': {
//             borderColor: '#f44250',
//             borderWidth: 2,
//             height: '50px',
//             fontFamily: "Poppins-Regular",
//             fontSize: '14px'
//         },
//         '& input:optional + fieldset': {
//             borderColor: "#CACACA"
//         },
//     }
// }));

export const ValidationSelectField = styled(Select)(({ theme, touched, isfocused, isValid }) => ({
    "&.MuiOutlinedInput-root": {
      "&.Mui-focused:valid fieldset": {
        borderColor: isValid ? "#198754" : "#f44250",
      },
      "&.Mui-focused:focus fieldset": {
        borderColor: isfocused ? "#00B4E5" : "#0000003b",
      },
      "&.Mui-focused:invalid + fieldset": {
        borderColor: "#f44250",
        borderWidth: 1,
        height: '50px',
        fontFamily: "Poppins-Regular",
        fontSize: '14px'
      },
      "& fieldset": {
        borderColor: touched ? "#198754" : "#0000003b",
      },
    },
}));