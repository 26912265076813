const initialState = {
    show: false
}
export const getVideo = (state = initialState, action) => {
    switch(action.type){
        case 'OPEN_VIDEO_MODAL':
            return{
                ...state,
                show: true,
            }
        case 'CLOSE_VIDEO_MODAL':
            return{
                ...state,
                show: false,
            }
        default:
            return{
                ...state
            }
    }
}