import { styled, InputBase} from "@mui/material";

const MySelectShadow = styled(InputBase)(({ theme }) => ({
    '& label': {
        backgroundColor: "#fff",
    },
    '& .MuiInputBase-root':{
        width: "195px",
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: "#fff",
        fontSize: 16,
        padding: '6px 26px 6px 12px',
        color: "#25313C",
        height: "41px",
        boxSizing: "border-box",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        width: "195px",
        boxShadow:' 0px 1px 2px rgb(16 24 40 / 5%)',
        border: '1px solid #F3F3F3',
        borderRadius: '8px',
        lineHeight: '27px'
    },
    '& .MuiSvgIcon-root' :{
        color: "#25313C"
    },
    '& .MuiInputLabel-formControl':{
        backgroundColor: "#fff",
    }
}));
export default MySelectShadow

export const MySelectBorder = styled(InputBase)(({ theme }) => ({
    '& label': {
        backgroundColor: "#fff",
    },
    '& .MuiInputBase-root':{
        width: "195px",
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: "#fff",
        fontSize: 16,
        padding: '0.375rem 0.75rem',
        color: "#495057",
        height: 'calc(1.5em + 0.75rem + 2px)',
        boxSizing: "border-box",
        width: "100%",
        border: '0.652235px solid #E0E0E0',
        borderRadius: '3.9',
        lineHeight: '1.5'
    },
    '& .MuiSvgIcon-root' :{
        color: "#25313C"
    },
    '& .MuiInputLabel-formControl':{
        backgroundColor: "#fff",
    }
}));

export const MySelectSmall = styled(InputBase)(({ theme }) => ({
    '& label': {
        backgroundColor: "#fff",
    },
    '& .MuiInputBase-root':{
        width: "195px",
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: "#fff",
        fontSize: 12,
        padding: "5px 0.75rem",
        color: '#1A1A1A',
        height: '32px',
        boxSizing: "border-box",
        width: "100%",
        border: '1px solid #EAEAEA',
        borderRadius: '4px',
        lineHeight: '1.5',
        fontFamily: 'Poppins-Regular',
        display: "flex",
        alignItems: "center"
    },
    '& .MuiSvgIcon-root' :{
        color: "#B5B8BB",
        fontSize: "16px"
    },
    '& .MuiInputLabel-formControl':{
        backgroundColor: "#fff",
    }
}));