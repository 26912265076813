const initialState = {
    form_id: '',
    name: '',
    position: '',
    visibility: true,
    field_type: 'input',
    description: '',
    placeholder: '',
    type: 'text',
    field_input: '',
    options: [],
    required: false,
    min_length: '',
    max_length: '',
    redirect_link: [],
    sendAsReview: false,
}
const initialThankYou = {
    thankyou: "Thank you"
}
const avgInitialState = []


export const formModal = (state = false , action) => {
    switch(action.type){
        case 'OPEN_MODAL':
            return{
                ...state,
                open: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}

export const fieldTypeReducer = (state = 'input' , action) => {
    switch(action.type){
        case 'FIELD_TYPE':
            return{
                ...state,
                type: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}

export const getForms = (state = [], action) => {
    switch(action.type){
        case 'GET_FORMS':
        return{
            ...state,
            forms: action.payload
        }
        case 'CLEAR_STATE':
            return{
                ...state,
                forms: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const getFormDetails = (state = [], action) => {
    switch(action.type){
        case 'GET_FORM_DETAILS':
            return{
                ...state,
                form: action.payload
            }
        case 'CLEAR_DATA_FORM':
            return{
                ...state,
                form: undefined
            }
        default:
            return{
                ...state
            }
    }
}
export const getFormDetailsFields = (state = [], action) => {
    switch(action.type){
        case 'GET_FORM_DETAILS_FIELDS':
            return{
                ...state,
                form: action.payload
            }
        case 'CLEAR_DATA_FORM_FIELDS':
            return{
                ...state,
                form: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const FormClient = (state= [], action) => {
    switch(action.type){
        case 'ADD_FORM_CLIENT':
            return{
                ...state,
                form: action.payload
            }
        default:
            return{
                ...state
            }
    }
}

export const FormFieldAdded = (state = initialState, action) => {
    switch(action.type){
        case 'ADD_FORM_ID':
            let addFormId = {...state}
            addFormId.form_id = action.payload
            return addFormId
        case 'ADD_LABEL_NAME':
            let newStateName = {...state}
            newStateName.name = action.payload
            return newStateName
        case 'ADD_INPUT_FIELD_NAME':
            let newInputFieldName = {...state}
            newInputFieldName.field_input = action.payload
            return newInputFieldName
        case 'ADD_LABEL_POSITION':
            let newStatePosition = {...state}
            newStatePosition.position = action.payload
            return newStatePosition
        case 'ADD_LABEL_VISIBILITY':
            let newStateVisibility = {...state}
            newStateVisibility.visibility = action.payload
            return newStateVisibility
        case 'FIELD_TYPE':
            let newStateFieldType = {...state}
            newStateFieldType.field_type = action.payload
            return newStateFieldType
        case 'ADD_FIELD_DESCRIPTION':
            let newStateFieldDescription = {...state}
            newStateFieldDescription.description = action.payload
            return newStateFieldDescription
        case 'ADD_FIELD_PLACEHOLDER':
            let newStateFieldPlaceholder = {...state}
            newStateFieldPlaceholder.placeholder = action.payload
            return newStateFieldPlaceholder
        case 'ADD_FIELD_INPUT_TYPE':
            let newStateFieldinputType = {...state}
            newStateFieldinputType.type = action.payload
            return newStateFieldinputType
        case 'ADD_FIELD_SELECT_OPTIONS':
            let newStateFieldSelectOptions = {...state}
            newStateFieldSelectOptions.options = action.payload
            return newStateFieldSelectOptions
        case 'ADD_FIELD_MAX_LENGTH':
            let newStateFieldmaxLength = {...state}
            newStateFieldmaxLength.max_length = action.payload
            return newStateFieldmaxLength
        case 'ADD_FIELD_MIN_LENGTH':
            let newStateFieldminLength = {...state}
            newStateFieldminLength.min_length = action.payload
            return newStateFieldminLength
        case 'ADD_FIELD_REQUIRED':
            let newStateFieldRequired = {...state}
            newStateFieldRequired.required = action.payload
            return newStateFieldRequired
        case 'ADD_FIELD_AS_REVIEW':
            let newStateFieldAsReview = {...state}
            newStateFieldAsReview.sendAsReview = action.payload
            return newStateFieldAsReview
        case 'ADD_RATING_REDIRECT':
            let newStateRatingRedirect = {...state}
            newStateRatingRedirect.redirect_link = action.payload
            return newStateRatingRedirect
        default:
            return{
                ...state
            }
    }
}

export const getThankYouMessage = (state = initialThankYou, action) => {
    switch(action.type){
        case 'THANK_YOU_MESSAGE':
        return{
            ...state,
            thankyou: action.payload
        }
        default:
            return{
                ...state
            }
    }
}

export const FormActionBuilder = (state = "", action) => {
    switch(action.type){
        case 'FORM_BUILDER_ACTION':
        return{
            ...state,
            formAction: action.payload
        }
        default:
            return{
                ...state
            }
    }
}

//SURVEY
export const getSurveys = (state = [], action) => {
    switch(action.type){
        case 'ADD_SURVEYS':
        return{
            ...state,
            surveys: action.payload
        }
        default:
            return{
                ...state
            }
    }
}



export const getSubmission = (state = [], action) => {
    switch(action.type){
        case 'GET_SUBMISSION':
        return{
            ...state,
            submissions: action.payload
        }
        default:
            return{
                ...state
            }
    }
}