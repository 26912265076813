import { useDispatch } from "react-redux";
import { getLocationSelected, getGroupSelected } from "../../../redux/actions/admin-index";

const useHandleDisable = () => {
    const dispatch = useDispatch()

    const handleDisable = () => {
        // dispatch(getLocationSelected("all"));
        // localStorage.setItem('location', "all");
        // dispatch(getGroupSelected(""));
        // localStorage.setItem('group', "");
    };
    
    return handleDisable;
}
export default useHandleDisable