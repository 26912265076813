import React, {useState, useEffect} from "react"
import { ClickAwayListener, Skeleton, Badge, Fade, CircularProgress  } from "@mui/material"
import { baseUrlDev } from "../Helpers/baseUrl"
import { Link, useNavigate } from "react-router-dom"
import { getLocalUser } from "../TokenControl/parts/useLocalUser"
import axios from "axios"
import { useDispatch } from "react-redux"
import { fetchUserInfo } from "../../redux/actions/admin-index"
import { useTranslation } from "react-i18next"

const UserDropdown = ({user, setLoading, count, setOpen}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [openUserDetails, setOpenUserDetails] = useState(false)
    const [t] = useTranslation()
    useEffect(() => {
        dispatch(fetchUserInfo())
    }, [])

    const logout = () => {
        setLoading(true)
        setOpenUserDetails(false)
        axios.post(`${baseUrlDev}logout`, {} , {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(() => {
            setLoading(false)
            window.location.href = "https://irevu.com"
            localStorage.clear()
            document.cookie = "i_token=0;domain=.irevu.com;path=/;"
            document.cookie = "irw_token=0;domain=.irevu.com;path=/;"
        })
        .catch(() => {
            setLoading(false) 
        })
    }
    return(
        <>
            <ClickAwayListener onClickAway={() => setOpenUserDetails(false)}>
                <div className="user-acc-container">
                    <div className="user-acc-info" onClick={() => setOpenUserDetails(!openUserDetails)}>
                        {user ?
                            <div className="user-image-holder">
                                <img src={user.user.avatar ? user.user.avatar : "https://irevu.com/images/avatar_default.svg"} />
                            </div>
                            : <Skeleton style={{ marginLeft: "5px", marginRight: "5px", width: "30px", height: "30px", transform: 'unset' }} variant="circular" />}
                        {user ? <span className="user-name-holder">{user.user.first_name}</span> : <Skeleton style={{ marginLeft: "5px", marginRight: "5px", width: "85px", height: "25px", transform: 'unset' }} />}
                        <span className="user-arrow">
                            <img src={"/admin-icons/arrow-down-color.svg"} alt="dropdown" />
                        </span>
                    </div>
                    <div className="user-acc-details" style={{ display: openUserDetails ? "block" : "none" }}>
                        <div className="user-acc-details-profile">
                            <div className="user-acc-details-img">
                                <img src={user && user.user && user.user.avatar ? user.user.avatar : "https://irevu.com/images/avatar_default.svg"} />
                            </div>
                            <div>
                                <h6>{user && user.user && user.user.first_name}</h6>
                                <span>{user && user.user && user.user.email}</span>
                            </div>
                        </div>
                        <div className="user-acc-details-options">
                            <h3>{t("Options")}</h3>
                            <Link to="/app/admin/account/settings" onClick={() => setOpenUserDetails(false)}>
                                <img src={"/admin-icons/d-user-icon.svg"} alt="account" />
                                <h6>{t("Account")}</h6>
                            </Link>
                            <Link to="/app/admin/support">
                                <img src={"/admin-icons/support.svg"} alt="support" />
                                <h6>{t("Support")}</h6>
                            </Link>
                        </div>
                        <div className="user-acc-details-logout" onClick={() =>  logout()}>
                            <button>
                                <img src={"/admin-icons/logout.svg"} alt="logout" />
                                <h6 >{t("Logout")}</h6>
                            </button>
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
        </>
    )
}
export default UserDropdown