export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('/');
}

export function formatDate2(date) {
    if (!date) return '';
    const d = new Date(date);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(d);
}

export function formatDateUS(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [month, day, year].join('/');
}

export function formatDateLine(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export function formatDateLineTime(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        minutes = d.getMinutes(),
        seconds = d.getSeconds();


    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return `${[year, month, day].join('-')} ${[hour, minutes, seconds].join(':')}`;
}

export function checkDate(dateStr, comma) {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    
    let inputDate = new Date(dateStr);
    
    if (inputDate.toDateString() === yesterday.toDateString()) {
        return "Yesterday";
    } else {
        let month = inputDate.toLocaleString('default', { month: 'short' });
        let day = inputDate.getDate();
        let year = inputDate.getFullYear();
        if(comma){
            return  day + ' ' + month + ', ' + year
        }
        return  day + ' ' + month + ' ' + year
    }
}
export const formatTime = (time) => {
    const timePart = time.split(' ')[1].slice(0, 5);
    const [hourStr, minuteStr] = timePart.split(':');
    const hour = parseInt(hourStr, 10);
    const period = hour >= 12 ? 'PM' : 'AM';
    const formattedTime = `${hour > 12 ? hour - 12 : hour}:${minuteStr} ${period}`;
    return formattedTime
}
export function formatDateNoty(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
    if (diffDays === 0) {
      return "Today";
    } else if (diffDays === 1) {
      return "Yesterday";
    } else if (diffDays <= 6) {
      return `${diffDays} Days ago`;
    } else {
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    }
  }