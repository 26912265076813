import { useState, useEffect } from 'react'


const useLocalUser = (callBack) => {
  const [localUser, setLocalUser] = useState((window.localStorage['user'] ? JSON.parse(window.localStorage['user']) : {}));

  useEffect(() => {
    if(callBack && window.localStorage['user']){
      callBack();
    }
    window.localStorage['user'] = JSON.stringify(localUser);
  }, [localUser]);

  return [localUser, setLocalUser];
}

/* used for non componet functions */
export function getLocalUser() {
  return (window.localStorage['user'] ? JSON.parse(window.localStorage['user']) : {});
}

export function getHeaderLocalUser() {
  const localUserToken = getLocalUser()?.token
  var header = {};
  if (localUserToken) {
    header = {
      'Authorization': `Bearer ${localUserToken}`
    }
  }
  return header;
}


export default useLocalUser