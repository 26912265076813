const categoriesReducer = (state = [], action) => {
    switch(action.type){
        case 'GET_CATEGORIES':
            return{
                ...state,
                categories: action.payload.data
            }
        default:
            return{
                ...state
            }
    }
}

export const categoriesReducerSp = (state = [], action) => {
    switch(action.type){
        case 'GET_CATEGORIES_SP':
            return{
                ...state,
                categories: action.payload.data
            }
        default:
            return{
                ...state
            }
    }
}

export default categoriesReducer