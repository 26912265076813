import React, { useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { Skeleton } from "@mui/material"
import { Link } from "react-router-dom"

const style = {
    div: {
        backgroundColor: '#C91C25',
        color: "#fff",
        fontFamily: "Poppins-Regular",
        padding: "6px",
        fontSize: "15px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        minHeight: '37px',
        height: "fit-content",
        boxSizing: 'border-box'
    },
    a: {
        textDecoration: "none",
        color: "yellow",
        fontFamily: "Poppins-Medium",
        marginLeft: "10px"
    },
    aCancel: {
        textDecoration: "none",
        color: "yellow",
        fontFamily: "Poppins-Medium",
        marginLeft: "10px",
        marginRight: "10px"
    },
    skeleton: {
        transform: 'unset',
        height: "15px",
        margin: "0 5px",
        width: "70px"
    }
}

const Banner = ({ setHeight, days }) => {
    const divRef = useRef(null);

    const user = useSelector(state => state.getUserInfo.user)
    const impersonateReducer = useSelector(state => state.impersonateReducer.impersonate)
    const demoReducer = useSelector(state => state.demoReducer.demo)
    const cancelReducer = useSelector(state => state.cancelReducer.cancel)
    const location = useSelector(state => state.locationSelected.location)

    const demo = JSON.parse(demoReducer)
    const impersonate = JSON.parse(impersonateReducer)
    const cancel = JSON.parse(cancelReducer)

    useEffect(() => {
        setTimeout(() => {
            if (divRef.current) {
                setHeight(divRef.current.offsetHeight)
            } else {
                setHeight(0)
            }
        }, 100)
    }, [impersonateReducer, demoReducer, location])

    //This checks div height on resize
    useEffect(() => {
        const handleResize = () => {
            if (divRef.current) {
                setHeight(divRef.current.offsetHeight)
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    if (demo  && impersonate) {
        return (
            <div style={style.div} ref={divRef}>
                {user ? <> You are impersonating as {user && user.user.first_name && user.user.first_name} {user && user.user.last_name && user.user.last_name}</> :
                    <>
                        You are impersonating as
                        <Skeleton style={style.skeleton} />
                    </>
                }
                <a href="https://irevu.com/admin_users.php?unimpersonate=1&manager=1" style={style.a}>Back to Admin</a>.
                {days ?
                    <> This is a demo account and you have {days} days left. Activate your account today to start using all iReview Features. <Link to="/app/admin/subscription" style={style.a}> Start Activation </Link></>
                    :
                    <> Your demo account has expired. Activate your account today to start using all iReview Features. <Link to="/app/admin/subscription" style={style.a}> Start Activation </Link></>
                }
            </div>
        )
    } else if (demo && !impersonate) {
        return (
            <div style={style.div} ref={divRef}>
                {days ?
                    <> This is a demo account and you have {days} days left. Activate your account today to start using all iReview Features. <Link to="/app/admin/subscription" style={style.a}> Start Activation </Link></>
                    :
                    <> Your demo account has expired. Activate your account today to start using all iReview Features. <Link to="/app/admin/subscription" style={style.a}> Start Activation </Link></>
                }
            </div>
        )
    } else if (!demo && !cancel &&  impersonate) {
        return (
            <div style={style.div} ref={divRef}>
                {user ? <> You are impersonating as {user && user.user.first_name && user.user.first_name} {user && user.user.last_name && user.user.last_name}</> :
                    <>
                        You are impersonating as
                        <Skeleton style={style.skeleton} />
                    </>
                }
                <a href="https://irevu.com/admin_users.php?unimpersonate=1&manager=1" style={style.a}>Back to Admin</a>.
            </div>
        )
    } else if (cancel && impersonate){
        return (
            <div style={style.div} ref={divRef}>
                {user ? <> You are impersonating as {user && user.user.first_name && user.user.first_name} {user && user.user.last_name && user.user.last_name}</> :
                    <>
                        You are impersonating as
                        <Skeleton style={style.skeleton} />
                    </>
                }
                <a href="https://irevu.com/admin_users.php?unimpersonate=1&manager=1" style={style.a}>Back to Admin</a>.
                Your account has been cancelled, <Link to="/app/admin/subscription" style={style.aCancel}>Upgrade</Link>  your Account to continue enjoying our services.
            </div>
        )
    }
    else if (cancel && !impersonate){
        return (
            <div style={style.div} ref={divRef}>
              Your account has been cancelled, <Link to="/app/admin/subscription" style={style.aCancel}>Upgrade</Link> your Account to continue enjoying our services.
            </div>
        )
    }
}
export default Banner