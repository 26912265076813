export const widgetCodeShareReducer = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_WIDGET_LOADING': {
            return {
                ...state,
                widget: undefined
            }
        }
        case 'GET_WIDGET_CODE':
            return {
                ...state,
                widget: action?.payload?.data,
            }
        default:
            return {
                ...state
            }
    }
}