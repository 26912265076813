import React from "react"
import { MdArrowBackIos } from "react-icons/md";

const FlaggingSecondStep = ({step, setStep, reason}) => {
    const report_review_categories = [
        {
            id: 1, 
            key: "offTopic", 
            title: "Off topic", 
            icon: "/admin-icons/flagging/offTopic.svg",
            description: "Review doesn’t pertain to an experience at or with this business"
        },
        {
            id: 2, 
            key: "spam",
            title: "Spam", 
            icon: "/admin-icons/flagging/spam.svg",
            description: "Review is from a bot, a fake account, or contains ads and promotions"
        },
        {
            id: 3, 
            key: "conflictOfInterest", 
            title: "Conflict of interest", 
            icon: "/admin-icons/flagging/conflictOfInteres.svg",
            description: "Review is from someone affiliated with the business or a competitor’s business"
        },
        {
            id: 4, 
            key: "profanity", 
            title: "Profanity", 
            icon: "/admin-icons/flagging/profanity.svg",
            description: "Review contains swear words, has sexually explicit language, or details graphic violence"
        },
        {
            id: 5, 
            key: "bullyingHarassment", 
            title: "Bullying or harassment", 
            icon: "/admin-icons/flagging/bullying.svg",
            description: "Review personally attacks a specific individual"
        },
        {
            id: 6, 
            key: "discriminationHatepeech", 
            title: "Discrimination or hate speech",
            icon: "/admin-icons/flagging/discrimination.svg", 
            description: "Review has harnful language about an invidual or group based on indentity"
        },
        {
            id: 7, 
            key: "personalInformation", 
            title: "Personal information", 
            icon: "/admin-icons/flagging/personal_information.svg", 
            description: "Review contains personal information, such as an address or phone number"
        },
        {
            id: 8, 
            key: "notHelpful", 
            title: "Not helpful", 
            icon: "/admin-icons/flagging/notHelpful.svg", 
            description: "Review doesn’t help people decide whether to go to this place"
        },
    ]

    let item = report_review_categories.filter(cat => cat.key == reason)[0]
    return(
        <div className={`report_review_list ${step == 2 ? 'active' : ''}`}>  
            {step == 2 && 
                <div className="report_review_reason">
                    <div>
                        <MdArrowBackIos onClick={() => setStep(1)}/>
                        <h1>{item?.title}</h1>
                        <img src={item?.icon} /> 
                    </div>
                    <p>{item?.description}</p>
                    <div><button onClick={() => setStep(3)}>Submit</button></div>
                </div>
            }
        </div>
    )
}
export default FlaggingSecondStep