import React, {useEffect, useState }from "react"
import { americaStates, canadaStates, mexicoStates } from "../Helpers/states";
import { Select, MenuItem, FormControl } from "@mui/material";
import PhoneInput from 'react-phone-input-2';
import SignUpSelect from "./SignUpSelect";
import { countries } from "../Helpers/countries";
import { FlagIcon } from "react-flag-kit";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoriesSp } from "../../redux/actions/admin-index";


const LocationFormInputs = ({errors, setErrors, fields, setFields}) => {
    const dispatch = useDispatch()
   
    const handleFieldChange = (e) => {
        const {name, value, valid} = e.target
        setFields({...fields, [name]: value})
    }

    const handleFieldSelectChange = (name, value) => {
        setFields({...fields, [name]: value})
    }

    useEffect(() => {
        dispatch(fetchCategoriesSp())
    }, [])

    const categories = useSelector(state => state.categoriesReducerSp.categories)

    return(
        <div>
            <div className={`inputs-wrapper-sp ${errors.length > 0 && errors.map(el => el.business_name)?.filter(business_name => business_name)[0] && "error"}`}>
                <input 
                    required 
                    type="text" 
                    name="business_name" 
                    value={fields.business_name} 
                    placeholder="Business Name" 
                    onChange={(e) => handleFieldChange(e)}
                />
                {errors.length > 0 && errors.map(el => el.business_name)?.filter(business_name => business_name)[0] && <span>{errors.map(el => el.business_name).filter(business_name => business_name)[0]}</span>}
            </div>
            <div className={`inputs-wrapper-sp ${errors.length > 0 && errors.map(el => el.address)?.filter(address => address)[0] && "error"}`}>
                <input 
                    required 
                    type="text" 
                    name="address" 
                    value={fields.address} 
                    placeholder="Street Address" 
                    onChange={(e) => handleFieldChange(e)}
                />
                {errors.length > 0 && errors.map(el => el.address)?.filter(address => address)[0] && <span>{errors.map(el => el.address).filter(address => address)[0]}</span>}
            </div>
            <div className="inputs-wrapper-sp">
                <input 
                    type="text" 
                    name="address2" 
                    value={fields.address2} 
                    placeholder="Street Address 2" 
                    onChange={(e) => handleFieldChange(e)}
                />
                {errors.length > 0 && errors.map(el => el.address2)?.filter(address2 => address2)[0] && <span>{errors.map(el => el.address2).filter(address2 => address2)[0]}</span>}
            </div>
            <div className={`inputs-wrapper-sp ${errors.length > 0 && errors.map(el => el.city)?.filter(city => city)[0] && "error"}`}>
                <input 
                    required 
                    type="text" 
                    name="city" 
                    value={fields.city} 
                    placeholder="City" 
                    onChange={(e) => handleFieldChange(e)}
                />
                {errors.length > 0 && errors.map(el => el.city)?.filter(city => city)[0] && <span>{errors.map(el => el.city).filter(city => city)[0]}</span>}
            </div>
            <div className={`inputs-wrapper-sp ${errors.length > 0 && errors.map(el => el.zip)?.filter(zip => zip)[0] && "error"}`}>
                <input 
                    required 
                    type="text" 
                    name="zip" 
                    value={fields.zip} 
                    placeholder="Zip/Postal Code" 
                    onChange={(e) => handleFieldChange(e)}
                />
            </div>
            <div className={`inputs-wrapper-sp ${errors.length > 0 && errors.map(el => el.country)?.filter(country => country)[0] && "error"}`}>                         
                <FormControl fullWidth>
                    <Select
                        id="input-type-one-select"
                        label="position"
                        name="country"
                        value={fields.country}
                        onChange={(e) => handleFieldSelectChange("country", e.target.value)}
                        input={<SignUpSelect />}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    >
                    <MenuItem value={undefined}><em>None</em></MenuItem>
                    {countries.map(country => {
                            return (
                                <MenuItem key={country.code3A} value={country.code2A}><FlagIcon code={country.code2A}  style={{width: "20px", height: "15px", marginRight: "10px"}}/>{country.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                {errors.length > 0 && errors.map(el => el.country)?.filter(country => country)[0] && <span>{errors.map(el => el.country).filter(country => country)[0]}</span>}
            </div>
            <div className={`inputs-wrapper-sp ${errors.length > 0 && errors.map(el => el.state)?.filter(state => state)[0] && "error"}`}>                         
                {fields.country !== "US" && fields.country !== "MX" && fields.country !== "CA" ?
                    <input 
                        required 
                        type="text" 
                        name="state" 
                        value={fields.state}
                        placeholder="State" 
                        onChange={(e) => handleFieldChange(e)}
                    />    
            
                : <FormControl fullWidth>
                    <Select
                        id="input-type-one-select"
                        name="state"
                        value={fields.state}
                        onChange={(e) => handleFieldSelectChange("state", e.target.value)}
                        input={<SignUpSelect />}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    >
                        <MenuItem value={" "}><em>Select State</em></MenuItem>
                        {fields.country === "US" ?
                            americaStates.map(state => {
                                return(
                                    <MenuItem key={`state${state.name}`} value={state.code2A}>{state.name}</MenuItem>
                                )
                            })
                            : fields.country === "CA" ? 
                            canadaStates.map(state => {
                                return(
                                    <MenuItem key={`state${state.name}`} value={state.code2A}>{state.name}</MenuItem>
                                )
                            }) 
                            : fields.country === "MX" &&
                            mexicoStates.map(state => {
                                return(
                                    <MenuItem key={`state${state.name}`} value={state.code2A}>{state.name}</MenuItem>
                                )
                            })
                        }   
                    </Select>
                </FormControl>
                }
                {errors.length > 0 && errors.map(el => el.state)?.filter(state => state)[0] && <span>{errors.map(el => el.state).filter(state => state)[0]}</span>}
            </div>
            <div className={`inputs-wrapper-sp ${errors.length > 0 && errors.map(el => el.phone)?.filter(phone => phone)[0] && "error"}`}>
                <PhoneInput
                    specialLabel= ""
                    enableSearch
                    value={fields.phone}
                    onChange={(val) => handleFieldChange({ target: { name: "phone", value: val } })}
                    required
                    country={'us'}
                />
                {errors.length > 0 && errors.map(el => el.phone)?.filter(phone => phone)[0] && <span>{errors.map(el => el.phone).filter(phone => phone)[0]}</span>}
            </div>
            <div className={`inputs-wrapper-sp ${errors.length > 0 && errors.map(el => el.business_category)?.filter(business_category => business_category)[0] && "error"}`}>                         
                <FormControl fullWidth>
                    <Select
                        id="input-type-one-select"
                        label="position"
                        name="business_category"
                        value={fields.business_category}
                        onChange={(e) => handleFieldSelectChange("business_category", e.target.value)}
                        input={<SignUpSelect />}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    >
                    <MenuItem value={" "}><em>Select Business Category</em></MenuItem>
                        {
                            categories && Object.keys(categories).map(el => {
                                return (
                                    <MenuItem key={el} value={el}>{categories[el]}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                {errors.length > 0 && errors.map(el => el.business_category)?.filter(business_category => business_category)[0] && <span>{errors.map(el => el.business_category).filter(business_category => business_category)[0]}</span>}
            </div>
        </div>
    )
}
export default LocationFormInputs