import axios from "axios";
import {baseUrl, baseUrlDev} from "../../components/Helpers/baseUrl"
import { getLocalUser } from "../../components/TokenControl/parts/useLocalUser";


let controller = new AbortController();
let controllerSubmission = new AbortController();

let headers = getLocalUser().token && {
    'Authorization': `Bearer ${getLocalUser().token}`
}



function getRequest(url, callback, dispatch ,onErrorCallback){
    axios.get(`${baseUrl}${url}`)
    .then(res => res.data)
    .then(data => dispatch(callback(data)))
    .catch((err) => {
        if(onErrorCallback){
            onErrorCallback?.(err);
        }else{
            console.log('err', err);
        }
    });
}

function getRequestProtected(url, callback, dispatch ,onErrorCallback){
    axios.get(`${baseUrl}${url}`,{headers:{'Authorization': `Bearer ${getLocalUser().token}`}})
    .then(res => res.data)
    .then(data => dispatch(callback(data)))
    .catch((err) => {
        if(onErrorCallback){
            onErrorCallback?.(err);
        }else{
            console.log('err', err);
        }
        if (err.response.status === 401) {
            window.location.href = `${window.location.origin}/login`
        }
    });
}
function getRequestParamProtected(url, param, callback, dispatch, controllerCb){    
    axios.get(`${baseUrl}${url}`, {
        params:{
           ...param
        },
        signal: controllerCb && controllerCb.signal,
        headers:{'Authorization': `Bearer ${getLocalUser().token}`},
    }).then(res => res.data)
    .then(data => dispatch(callback(data)))
    .catch((err) => {
        console.log('err', err)
        if (err.response.status === 401) {
            window.location.href = `${window.location.origin}/login`
        }
    })
}
function getRequestParam(url, param, callback, dispatch){    
    // startline control signal abort
    const {disableAbort} = param || {};
    if(disableAbort){
        delete param['disableAbort']; // do not send  disableAbort to server
    }
    // endline control signal abort 
    
    !disableAbort && controller.abort();
    controller = new AbortController()
    axios.get(`${baseUrl}${url}`, {
        params:{
           ...param
        },
        signal: disableAbort ? null : controller.signal
    }).then(res => res.data)
    .then(data => dispatch(callback(data)))
    .catch((err) => {
        console.log('err', err)
    })
}
function getRequestParamv2(url, param, callback, dispatch){    
    // startline control signal abort
    const {disableAbort} = param || {};
    if(disableAbort){
        delete param['disableAbort']; // do not send  disableAbort to server
    }
    // endline control signal abort 
    
    !disableAbort && controller.abort();
    controller = new AbortController()
    axios.get(`${baseUrlDev}${url}`, {
        params:{
           ...param
        },
        signal: disableAbort ? null : controller.signal
    }).then(res => res.data)
    .then(data => dispatch(callback(data)))
    .catch((err) => {
        console.log('err', err)
    })
}

//Search categories dropdown
export const searchCategoriesDrop = (data) => {
    return{
        type: "SEARCH_CAT_DROP",
        payload: data
    }
}

//Categories
export const categoriesAction = (data) => {
    return{
        type: 'GET_CATEGORIES',
        payload: data
    }
}

export const  fetchCategories = () => {
    return(dispatch) => {
        getRequest('categories', categoriesAction, dispatch)
    }
}

//Recent Reviews
export const recentReviewsAction = (data) => {
    return{
        type: 'RECENT_REVIEWS',
        payload: data
    }
}
export const fetchRecentReviews = () => {
    return(dispatch) => {
        getRequest('reviews/recent', recentReviewsAction, dispatch)
    }
}

//Get Category details
export const categoryDetail = (data) => {
    return{
        type: 'GET_CATEGORY_DETAILS',
        payload: data
    }
}
export const categoryDetailClear = () => {
    return{
        type: 'CLEAR_DATA',
    }
}
export const fetchCategoryDetails = (category, page, city, review, time, status) => {
    return(dispatch) => {
        getRequestParam(`categories/${category}`, { page: page, city: city, review: review, time: time, status: status}, categoryDetail, dispatch)
    }
}


//Search companies & categories
export const searchCatAndComp = (data) => {
    return{
        type: 'SEARCH_CATEGORIES_COMPANIES',
        payload: data
    }
}

export const clearCatAndComp = (data) => {
    return{
        type: 'CLEAR_SEARCH_COMPANIES',
    }
}

export const fetchSearchCatComp = (query) => {
    return(dispatch) => {
        getRequestParam(`search`, { q: query}, searchCatAndComp, dispatch)
    }
}



//fetch Find Keywords
export const FindKeywords = (data) => {
    return{
        type: 'FETCH_FIND_KEYWORDS',
        payload: data
    }
}

export const fetchFindKeywords = (keyword) => {
    return(dispatch) => {
        getRequestParam(`find/${keyword}`, { disableAbort:true }, FindKeywords, dispatch)
    }
}



//Get Companie Data
export const companyDetails = (data) => {
    return{
        type: 'FETCH_COMPANIE_DATA',
        payload: data
    }
}

export const fetchCompanieData = (landingName,page,sort,onError)=>{
    return(dispatch) => {
        getRequest(`reviews/company/${landingName}?page=${page}&sort=${sort}`, companyDetails, dispatch,onError)
    }
}

//Get companies from google
export const searchCompanies = (data) => {
    return{
        type: "SEARCH_COMPANIES",
        payload: data
    }
}

export const fetchCompaniesGoogle = (query) => {
    return(dispatch) => {
        getRequestParam(`register/company`,{ q: query}, searchCompanies, dispatch)
    }
}
export const clearCompaniesLoader = () => {
    return{
        type: "CLEAR_COMPANIES_LOADER"
    }
}
//Get location details from accreditation data
export const getAccDetails = (data) => {
    return{
        type: "GET_ACCREDITATION_DETAILS",
        payload: data
    }
}
export const getAccreditationDetails = (place_id) => {
    return(dispatch) => {
        getRequestParamv2(`get-accreditation-data/${place_id}`,{}, getAccDetails, dispatch)
    }
}
export const clearAccreditationDetails = () => {
    return{
        type: "CLEAR_ACCREDITATION_DETAILS"
    }
}
//Get companies details from google
export const googleCompaniesDetails = (data) => {
    return{
        type: 'FETCH_COMPANIE_DETAILS_GOOGLE',
        payload: data
    }
}

export const fetchCompaniesDetailsGoogle = (query) => {
    return(dispatch) => {
        getRequestParam('register/company/details', {place_id: query}, googleCompaniesDetails, dispatch)
    }
}
//Show alert
export const showAlert = (data, text) => {
    return{
        type: 'SHOW_ALERT',
        payload: data,
        text: text
    }
}

//FILTERS
export const reviews = (data) => {
    return{
        type: 'FILTER_REVIEWS',
        payload: data
    }
}
export const time = (data) => {
    return{
        type: 'FILTER_TIME',
        payload: data
    }
}
export const status = (data) => {
    return{
        type: 'FILTER_STATUS',
        payload: data
    }
}
export const city = (data) => {
    return{
        type: 'FILTER_CITY',
        payload: data
    }
}

//FORM BUILDER STATE
//Fetch forms
export const formBuilderAction = (data) => {
    return{
        type: 'FORM_BUILDER_ACTION',
        payload: data
    }
}
export const clearState = () => {
    return{
        type: 'CLEAR_STATE',
    }
}
export const formDetailsClear = () => {
    return{
        type: 'CLEAR_DATA_FORM',
    }
}
export const formDetailsClearFields = () => {
    return{
        type: 'CLEAR_DATA_FORM_FIELDS',
    }
}

export const getForms = (data) => {
    return {
        type: "GET_FORMS",
        payload: data
    }
}

export const fetchForms = () => {
    return(dispatch) => {
        getRequestProtected(`forms`, getForms, dispatch)
    }
}

//Fetch form Details
export const getFormDetails = (data) => {
    return {
        type: "GET_FORM_DETAILS",
        payload: data
    }
}

export const fetchFormDetails = (id) => {
    return(dispatch) => {
        getRequestProtected(`forms/form/${id}`, getFormDetails, dispatch)
    }
}

//Fetch form Details
export const getFormDetailsFields = (data) => {
    return {
        type: "GET_FORM_DETAILS_FIELDS",
        payload: data
    }
}

export const fetchFormDetailsFields = (id) => {
    return(dispatch) => {
        getRequestProtected(`form-builder/${id}`, getFormDetailsFields, dispatch)
    }
}

export const formModal = (data) => {
    return{
        type: 'OPEN_MODAL',
        payload: data
    }
}

export const fieldType = (data) => {
    return{
        type: 'FIELD_TYPE',
        payload: data
    }
}
export const fieldLabelName = (data) => {
    return{
        type: 'ADD_LABEL_NAME',
        payload: data
    }
}
export const fieldInputName = (data) => {
    return{
        type: 'ADD_INPUT_FIELD_NAME',
        payload: data
    }
}
export const fieldLabelPosition = (data) => {
    return{
        type: 'ADD_LABEL_POSITION',
        payload: data
    }
}
export const fieldLabelVisbility = (data) => {
    return{
        type: 'ADD_LABEL_VISIBILITY',
        payload: data
    }
}
export const fieldDescription = (data) => {
    return{
        type: 'ADD_FIELD_DESCRIPTION',
        payload: data
    }
}
export const fieldPlaceholder = (data) => {
    return{
        type: 'ADD_FIELD_PLACEHOLDER',
        payload: data
    }
}
export const fieldInputType = (data) => {
    return{
        type: 'ADD_FIELD_INPUT_TYPE',
        payload: data
    }
}
export const fieldSelectOptions = (data) => {
    return{
        type: 'ADD_FIELD_SELECT_OPTIONS',
        payload: data
    }
}
export const fieldMaxLength = (data) => {
    return{
        type: 'ADD_FIELD_MAX_LENGTH',
        payload: data
    }
}
export const fieldMinLength = (data) => {
    return{
        type: 'ADD_FIELD_MIN_LENGTH',
        payload: data
    }
}
export const fieldRequired = (data) => {
    return{
        type: 'ADD_FIELD_REQUIRED',
        payload: data
    }
}
export const fieldAsReview = (data) => {
    return{
        type: 'ADD_FIELD_AS_REVIEW',
        payload: data
    }
}
export const addRedirectLinks = (data) => {
    return{
        type: 'ADD_RATING_REDIRECT',
        payload: data
    }
}
export const addFormId = (data) => {
    return{
        type: 'ADD_FORM_ID',
        payload: data
    }
}
//FORMS TO CLIENT
export const getFormClient = (data) => {
    return{
        type: 'ADD_FORM_CLIENT',
        payload: data
    }
}
export const fetchFormClient = (id) => {
    return(dispatch) => {
        getRequest(`form-submission/${id}`, getFormClient, dispatch)
    }
}

export const thankYouMessage = (data) => {
    return{
        type: "THANK_YOU_MESSAGE",
        payload: data
    }
}
//GET Dashboard Survey
export const getSurveys = (data) => {
    return{
        type: 'ADD_SURVEYS',
        payload: data
    }
}
export const fetchSurveys = () => {
    return(dispatch) => {
        getRequestProtected(`surveys`, getSurveys, dispatch)
    }
}

export const getSubmission = (data) => {
    return{
        type: 'GET_SUBMISSION',
        payload: data
    }
}
export const fetchSubmission = (id, page, search, sort, from, to) => {
    let params = {
        page,
        search,
        sort,
        from, 
        to
    }
    return(dispatch) => {
        controllerSubmission.abort();
        controllerSubmission = new AbortController()
        getRequestParamProtected(`forms/form/${id}/submissions`, params, getSubmission, dispatch, controllerSubmission)
    }
}


//SIDEBAR AND NAVBAR MOBILE STATE
export const showSidebar = (event) => {
    return{
        type: "SHOW_SIDEBAR",
        payload: event
    }
}

export const showNavbar = (event) => {
    return{
        type: "SHOW_NAVBAR",
        payload: event
    }
}
