import React, {useState, useEffect} from "react"
import { Modal, Alert, Fade } from "@mui/material"
import {BsQuestionCircle} from "react-icons/bs"
import { LoadingButton } from "@mui/lab"
import axios from "axios"
import { baseUrlDev } from "../Helpers/baseUrl"
import { getLocalUser } from "../TokenControl/parts/useLocalUser"
import { useNavigate } from "react-router-dom"

const LocationPopup = () => {
    const navigate = useNavigate()
    const [err, setErr] = useState(false)
    const [errMessage, setErrMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [id, setId] = useState("")

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        let place_id = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).user.place_id
        if(place_id){
            setOpen(true)
            setId(place_id)
        }
    }, [])

    const submitForm = () => {
        if(id){
            setLoading(true)
            axios.post(`${baseUrlDev}locations/create`, {place_id: id}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then(res => {
                setLoading(false)
                if(res.data.status){
                    navigate("/app/admin")
                } else {
                    setErrMessage(res.data.message)
                    setErr(true)
                    setTimeout(() => {
                        setErr(false)
                    }, 5000)
                    setTimeout(() => {
                        setErrMessage("")
                    }, 6000)
                }
            }).catch(err => {
                setLoading(false)
                setErr(true)
                setErrMessage(err.response?.data?.message)
                setTimeout(() => {
                    setErr(false)
                }, 5000)
                setTimeout(() => {
                    setErrMessage("")
                }, 6000)
            })
        }
    }

    return(
        <Modal 
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-tag-title"
            aria-describedby="modal-tag-description"
        >
            <div className="wrapper-loc-create">
                <Fade in={err}>
                    <div className="signup-alert">
                        <Alert severity="error" className="custom-error-alert">{errMessage}</Alert>
                    </div>
                </Fade>
                <BsQuestionCircle/>
                <div className="wrapper-inner-create">
                    <h2>iReview found your Business on Google</h2>
                    <p>Do you want to create your first location with your Google Data?</p>
                </div>
                <div>
                    <button onClick={handleClose}>No</button>
                    <LoadingButton
                        style={{paddingLeft: loading && "30px"}}
                        className=""
                        onClick={() => submitForm()}
                        loading={loading}
                    >
                        Yes, use my Google Data
                    </LoadingButton>
                </div>
            </div>
        </Modal>
    )
}
export default LocationPopup