import React, { useEffect, useRef, useState } from 'react'
import Search from '../../Helpers/Search'
import { useDispatch } from 'react-redux';
import { searchCategoriesDrop } from "../../../redux/actions";
import { ClickAwayListener } from "@mui/material";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SearchBusiness = () => {
  //const dispatch = useDispatch();
  const refSearchName = useRef(null);
  const refSearchLocation = useRef(null);
  const navigate = useNavigate();
  //const [serachValue, setSerachValue] = useState('');


  useEffect(() => {
    /// http://www.geoplugin.net/json.gp
    axios({
      method: 'GET',
      url: 'https://api.ipgeolocation.io/ipgeo?apiKey=477955365a174f46bdc13ce79555d5d7',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      //console.log("geoRepsonse:",response.data.country_name);
      var part1 = response?.data?.country_name || "";
      var part2 = response?.data?.city || "";

      refSearchLocation.current.value = part2 + (part1 !== "" ? ", " + part1 : "") || "";
    }).catch((error) => {
      console.log(error);
    });
  }, []);



  function keyUpTrigger(event) {
    if (event?.key === 'Enter' || event === "search") {
      // var valueSearched = refSearchName.current.value+" "+refSearchLocation.current.value;
      // if(valueSearched.length >= 3){
      // dispatch(searchCategoriesDrop(true));
      // setSerachValue(valueSearched);
      // }

      navigate("/find/" + encodeURIComponent(refSearchName.current.value + " " + refSearchLocation.current.value));
    }
  }
  function serachBtnClicked() {
    keyUpTrigger("search");
  }


  return (
    <section className="search-options-business">
      <img src="/image/home-search-image-bg.png" className="bg-image" />
      <h1 className="header-text-holder">Find an iReview Trusted Location</h1>
      <h2 className="header-text-footer">Check Places You Can Trust!</h2>
      <p className="text-center">Find trusted companies — and get the job done right, guaranteed.</p>
      <div className="ontop">

        <div className="ontop container-multiple-search">
          <div>
            <input type="text" ref={refSearchName} onKeyUp={keyUpTrigger} placeholder="Search for a business" />
            <input type="text" ref={refSearchLocation} onKeyUp={keyUpTrigger} placeholder="Location" />
            <span onClick={serachBtnClicked} className="serach-btn-icon-serach"><img src="/icon/search-icon.svg" /></span>
          </div>
        </div>
        <Search type="HomeOrigin" serachValue={""} />
      </div>
    </section>
  )
}

export default SearchBusiness