import React,{useEffect,useState} from 'react'

const useQueryParamsHook = () => {
    const params = new URLSearchParams(window.location.search);
    const [queryParams,setQueryParams] = useState({});
    useEffect(()=>{
        const objectParams = {};
        params.forEach((value,key)=>{
            if(key !== 'location'){
                objectParams[key] = value;
            }   
        });
        setQueryParams(objectParams);
    },[]);

    return queryParams;
}

export default useQueryParamsHook