export const getSubscriptionValues = (state = [], action) => {
    switch(action.type){
        case 'GET_SUBSCRIPTION_VALUES':
            return{
                ...state,
                subscription_values: action.payload,
            }
        case 'CLEAR_SUBSCRIPTION_VALUES':
            return{
                ...state,
                subscription_values: undefined
            }
        default:
            return{
                ...state
            }
    }
}