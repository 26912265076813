import {styled } from '@mui/material/styles';
import {TextField} from "@mui/material";

export const CustomTextField = styled(TextField)({
    '& input + fieldset': {
        borderColor: '#dbdbdb',
        borderWidth: 2,
        height: '50px',
        fontFamily: "Poppins-Regular",
        fontSize: '14px'
    },
    '& input:focus + fieldset': {
        borderColor: '#00B4E5'
    }
});