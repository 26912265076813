export const recentReviewsReducer = (state = [], action) => {
    switch(action.type){
        case 'RECENT_REVIEWS':
            return{
                ...state,
                recent_reviews: action.payload.data
            }
        default:
            return{
                ...state
            }
    }
}