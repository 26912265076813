export const getGamifyMembers = (state = {}, action) => {
    switch (action.type) {
        case 'GET_GAMIFY_MEMBERS':
            return {
                ...state,
                gamify_members: action.payload,
                loading: false
            }
        case 'GET_GAMIFY_TARGET':
            return {
                ...state,
                gamify_targets: action.payload,
                loading: false
            }
        case 'CLEAR_GAMIFY':
            return {
                ...state,
                gamify_members: undefined
            }
        default:
            return { ...state }
    }

}
