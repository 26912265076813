import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "../src/language_json/en.json"
import esTranslation from "../src/language_json/es.json"
import frTranslation from "../src/language_json/fr.json"
import deTranslation from "../src/language_json/de.json"
import itTranslation from "../src/language_json/it.json"

const resources = {
    en: enTranslation,
    es: esTranslation,
    fr: frTranslation,
    de: deTranslation,
    it: itTranslation
};
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false
    }
});

export default i18n;