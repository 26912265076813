const companiesSearchReducer = (state = [], action) => {
    switch(action.type){
        case 'SEARCH_COMPANIES':
            return{
                ...state,
                companies: action.payload.data,
            }
        case 'CLEAR_SEARCH_COMPANIES':
            return{
                ...state,
                companies: [],
            }
        case 'CLEAR_COMPANIES_LOADER':
            return{
                ...state,
                companies: undefined
            }
        default:
            return{
                ...state
            }
    }
}
export default companiesSearchReducer