const fetchFindKeywords = (state = {}, action) => {
    switch(action.type){
        case 'FETCH_FIND_KEYWORDS':
            return{
                ...state,
                companie: action.payload.data
            }
        case 'CLEAR_DATA':
            return {
                ...state,
                companie: undefined
            }
        default:
            return{
                ...state
            }
    }
}
export default fetchFindKeywords