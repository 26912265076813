const initialOrdersState = {
    orders: []
}
export const marketingMaterialsOrders = (state = initialOrdersState, action) => {
    switch(action.type){
        case 'GET_MARKETING_ORDERS_SELECTED':
            return{
                ...state,
                orders: [...state.orders, ...action.payload],
            }
        case 'REMOVE_MARKETING_ORDER':
            const newValue = action.payload;
            return {
                ...state,
                orders: state.orders.filter(item => item.marketing_material_id !== newValue)
            };
        case 'CLEAR_MARKETING_ORDER':
            return{
                ...state,
                orders: []
            }
        default:
            return{
                ...state
            }
    }
}