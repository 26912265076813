import { Container, Grid, Skeleton } from '@mui/material'
import React,{useEffect} from 'react'
import CategorieCol from '../part/CategorieCol'
import {useDispatch,useSelector} from 'react-redux';
import {fetchCategories} from '../../../redux/actions';

const AllCategories = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCategories());
    }, [])

    const categories = useSelector(state => state.categoriesReducer.categories)

    return (
        <section className="all-categories-seciton">

            <div className="row">
                <div className="col-md-12 d-flex text-center justify-content-center">
                    <h2>All Categories</h2>
                </div>
            </div>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    {categories && categories.map((item, index) => {
                        return <CategorieCol target={item.slug} key={index} icon={item.icon} label={item.name}/>
                    })}
                    {!categories && Array.from({length: 24}).map((item,index)=>{
                        return <Grid item xs={12} sm={6} md={3} key={index}>
                            <Skeleton variant="rectangular" style={{width:"100%",height:"60px"}} />
                        </Grid>
                    })}
                </Grid>
            </Container>
        </section>
    )
}

export default AllCategories