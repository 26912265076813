export const getReviewPage = (state = [], action) => {
    switch(action.type){
        case 'GET_REVIEW_PAGE':
            return{
                ...state,
                review_page: action.payload,
            }
        case 'CLEAR_REVIEW_PAGE':
            return{
                ...state,
                review_page: undefined
            }
        default:
            return{
                ...state
            }
    }
}