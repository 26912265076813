import React, { useEffect } from 'react'

const useScrollHook = ({onScroll}) => {
    useEffect(() => {
        window.addEventListener("scroll", _onScroll)

        return () => {
            window.removeEventListener("scroll", _onScroll);
        }
    }, []);

    function _onScroll(e) {
        if(onScroll && typeof onScroll === "function"){
            onScroll(e);
        }
    }
    return true;
}

export default useScrollHook