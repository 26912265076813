export const topNavSearch = (state = "", action) => {
    switch(action.type){
        case 'SEARCH_TOP_NAV':
            return{
                ...state,
                res: action.payload,
            }
        case 'CLEAR_TOP_NAVBAR':
            return{
                ...state,
                res: {data: {}}
            }
        default:
            return{
                ...state
            }
    }
}