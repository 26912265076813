export const americaStates = [
    {
        name: "Alabama",
        code2A: "AL",
    },
    {
        name: "Alaska",
        code2A: "AK",
    },
    {
        name: "Arizona",
        code2A: "AZ",
    },
    {
        name: "Arkansas",
        code2A: "AR",
    },
    {
        name: "California",
        code2A: "CA",
    },
    {
        name: "Colorado",
        code2A: "CO",
    },
    {
        name: 'Connecticut',
        code2A: 'CT'
    },
    {
        name: 'Delaware',
        code2A: 'DE'
    },
    {
        name: 'Florida',
        code2A: 'FL'
    },
    {
        name: 'Georgia',
        code2A: 'GA'
    },
    
    {
        name: 'Hawai',
        code2A: 'HI'
    },
    {
        name: 'Idaho',
        code2A: 'ID'
    },
    {
        name: 'District of Columbia',
        code2A:  'DC'
    },
    {
        name: "Illinois",
        code2A: 'IL'
    },
    {
        name: 'Indiana',
        code2A: 'IN'
    },
    {
        name: "Iowa",
        code2A: "IA"
    },
    {
        name: "Kansas",
        code2A: 'KS'
    },
    {
        name: 'Kentucky',
        code2A: "KY"
    },
    {
        name: 'Louisiana',
        code2A: 'LA'
    },
    {
        name: "Maine",
        code2A: "ME"
    },
    {
        name: "Maryland",
        code2A: "MD"
    },
    {
        name: "Massachusetts",
        code2A: "MA"
    },
    {
        name: "Michigan",
        code2A: "MI"
    },
    {
        name: "Minnesota",
        code2A: "MN"
    },
    {
        name: "Mississippi",
        code2A: "MS"
    },
    {
        name: "Missouri",
        code2A: "MO"
    },
    {
        name: "Montana",
        code2A: "MT"
    },
    {
        name: "Nebraska",
        code2A: "NE"
    },
    {
        name: "Nevada",
        code2A: "NV"
    },
    {
        name: "MontaNew Jerseyna",
        code2A: "NJ"
    },
    {
        name: "New Hampshire",
        code2A: "NH"
    },
    {
        name: "New Mexico",
        code2A: "NM"
    },
    {
        name: "New York",
        code2A: "NY"
    },
    {
        name: "North Carolina",
        code2A: "NC"
    },
    {
        name: "North Dakota",
        code2A: "ND"
    },  
    {
        name: "Ohio",
        code2A: "OH"
    },  
    {
        name: "Oklahoma",
        code2A: "OK"
    },  
    {
        name: "Oregon",
        code2A: "OR"
    },  
    {
        name: "Pennsylvania",
        code2A: "PA"
    },  
    {
        name: "Rhode Island",
        code2A: "RI"
    },  
    {
        name: "South Carolina",
        code2A: "SC"
    },  
    {
        name: "South Dakota",
        code2A: "SD"
    },
    {
        name: "Tennessee",
        code2A: "TN"
    },
    {
        name: "Texas",
        code2A: "TX"
    },  
    {
        name: "Utah",
        code2A: "UT"
    },  
    {
        name: "Vermont",
        code2A: "VT"
    },  
    {
        name: "Virginia",
        code2A: "VA"
    }, 
    {
        name: "Washington",
        code2A: "WA"
    }, 
    {
        name: "West Virginia",
        code2A: "WV"
    },  
    {
        name: "Wisconsin",
        code2A: "WI"
    }, 
    {
        name: "Wyoming",
        code2A: "WY"
    },  

]

export const canadaStates = [
    {
        name: "Alberta",
        code2A: "AB"
    },
    {
        name: "British Columbia",
        code2A: "BC"
    },
    {
        name: "Manitoba ",
        code2A: "MB"
    },
    {
        name: "New Brunswick",
        code2A: "NB"
    },
    {
        name: "Newfoundland and Labrador",
        code2A: "NL"
    },
    {
        name: "Nova Scotia",
        code2A: "NS"
    },
    {
        name: "Ontario",
        code2A: "ON"
    },
    {
        name: "Prince Edward Island",
        code2A: "PE"
    },
    {
        name: "Quebec",
        code2A: "QC"
    },
    {
        name: "Saskatchewan",
        code2A: "SK"
    },
    {
        name: "Northwest Territories",
        code2A: "NT"
    },
    {
        name: "Nunavut",
        code2A: "NU"
    },
    {
        name: "Yukon",
        code2A: "YT"
    },
    
]


export const mexicoStates = [
    {
        name: "Aguascalientes ",
        code2A: "AGU"
    },
    {
        name: "Baja California",
        code2A: "BCN"
    },
    {
        name: "Baja California Sur",
        code2A: "BCS"
    },
    {
        name: "Campeche",
        code2A: "CAM"
    },
    {
        name: "Chiapas ",
        code2A: "CHP"
    },
    {
        name: "Chihuahua",
        code2A: "CHH"
    },
    {
        name: "Coahuila",
        code2A: "COA"
    },
    {
        name: "Colima",
        code2A: "COL"
    },
    {
        name: "Durango",
        code2A: "DUR"
    },
    {
        name: "Guanajuato ",
        code2A: "GUA"
    },
    {
        name: "Guerrero",
        code2A: "GRO"
    },
    {
        name: "Hidalgo",
        code2A: "HID"
    },
    {
        name: "Jalisco",
        code2A: "JAL"
    },
    {
        name: "México",
        code2A: "MEX"
    },
    {
        name: "Michoacán",
        code2A: "MIC"
    },
    {
        name: "Morelos",
        code2A: "MOR"
    },
    {
        name: "Nayarit",
        code2A: "NAY"
    },
    {
        name: "Nuevo León",
        code2A: "NLE"
    },
    {
        name: "Oaxaca ",
        code2A: "OAX"
    },
    {
        name: "Puebla",
        code2A: "PUE"
    },
    {
        name: "Querétaro",
        code2A: "QUE"
    },
    {
        name: "Quintana Roo",
        code2A: "ROO"
    },
    {
        name: "San Luis Potosí",
        code2A: "SLP"
    },
    {
        name: "Sinaloa",
        code2A: "SIN"
    },
    {
        name: "Sonora",
        code2A: "SON"
    },
    {
        name: "Tabasco",
        code2A: "TAB"
    },
    {
        name: "Tamaulipas",
        code2A: "TAM"
    },
    {
        name: "Tlaxcala",
        code2A: "TLA"
    },
    {
        name: "Veracruz",
        code2A: "VER"
    },
    {
        name: "Yucatán",
        code2A: "YUC"
    },
    {
        name: "Zacatecas",
        code2A: "ZAC"
    },
    
]