const initialWalletVisibility = {
    visibility: false
}
export const getWalletReducer = (state = [], action) => {
    switch(action.type){
        case 'GET_WALLET':
            return{
                ...state,
                wallet: action.payload
            }
        case 'CLEAR_WALLET':
            return{
                ...state,
                wallet: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const getWalletVisibility = (state = initialWalletVisibility, action) => {
    switch(action.type){
        case 'CHANGE_WALLET_VISIBILITY':
            return{
                ...state,
                visibility: action.payload
            }
        default:
            return{
                ...state
            }
    }
}