export const analyticsReducer = (state = [] ,action) => {
    switch(action.type){
        case 'GET_ANALYSIS':
            return{
                ...state,
                analytics: action.payload
            }
        case 'CLEAR_ANALYSIS':
            return{
                ...state,
                analytics: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const keywordDetailsReducer = (state = [] ,action) => {
    switch(action.type){
        case 'GET_KEYWORD_DETAILS':
            return{
                ...state,
                keyword: action.payload
            }
        case 'CLEAR_KEYWORD_DETAILS':
            return{
                ...state,
                keyword: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const keywordAddedReducer = (state = [] ,action) => {
    switch(action.type){
        case 'GET_ADDED_KEYWORDS':
            return{
                ...state,
                keywords: action.payload
            }
        case 'CLEAR_ADDED_KEYWORDS':
            return{
                ...state,
                keywords: undefined
            }
        default:
            return{
                ...state
            }
    }
}