import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import WalletDetails from "./wallet_details"
import CreateCard from "./createCard"
import { CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"

const WalletBody = ({ setOpen, customerId }) => {
    const [hasWallet, setHasWallet] = useState()
    const wallet = useSelector(state => state.getWalletReducer.wallet)
    const { t } = useTranslation()

    useEffect(() => {
        if (wallet) {
            if (wallet.cards) {
                setHasWallet(true)
            } else {
                setHasWallet(false)
            }
        }
    }, [wallet])

    const style = {
        div: {
            background: "#ffff",
            minWidth: "fit-content",
            padding: "20px",
            width: "400px",
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: "fit-content",
            margin: "auto",
            borderRadius: "5px",
            textAlign: "center"
        },
        h4: {
            fontFamily: "Poppins-Regular",
            fontSize: "14px"
        },
        progress: {
            opacity: '0.1',
        }
    }

    if (wallet) {
        return hasWallet ?
            <WalletDetails setOpen={setOpen} /> :
            <CreateCard setOpen={setOpen} customerId={customerId} setHasWallet={setHasWallet} />
    } else {
        return (
            <div style={style.div}>
                <h4 style={style.h4}>{t("Please Wait")}</h4>
                <CircularProgress color="inherit" style={style.progress} />
            </div>
        )
    }

}
export default WalletBody