const initialState = {
    notFound: false
};

export const notFound = (state = initialState, action) => {
    switch (action.type) {
      case 'HANDLE_404_ERROR':
        return {
          ...state,
          notFound: true
        }
      case 'HANDLE_404_ERROR_RESET':
        return {
          ...state,
          notFound: false
        };
      default:
        return state;
    }
  };