import React from "react";
import { MdNavigateBefore} from "react-icons/md";
import {Skeleton} from "@mui/material";
import { Link, useParams} from "react-router-dom";

const CategoriesList = ({subcategories, company_category}) => {
    let { id } = useParams();
    let sub = subcategories && subcategories.subCategories
    return(
        <div className="white-background-box">
            <div className="title-parent">
                <h4>Categories</h4>
                <div className="categories-list-nav">
                    <Link to="/"><MdNavigateBefore/>Back to Categories</Link>
                    {subcategories === undefined ? 
                        <>
                            <Skeleton height={15} width={100} style={{marginBottom: "7px"}}/>
                            <Skeleton height={12} width={150} style={{marginLeft: '15px', marginBottom: "5px"}}/>
                            <Skeleton height={12} width={120} style={{marginLeft: '15px', marginBottom: "5px"}}/>
                            <Skeleton height={12} width={100} style={{marginLeft: '15px', marginBottom: "5px"}}/>
                            <Skeleton height={12} width={120} style={{marginLeft: '15px', marginBottom: "5px"}}/>
                            <Skeleton height={12} width={100} style={{marginLeft: '15px', marginBottom: "5px"}}/>
                        </>
                    : 
                    
                        <ul>
                            <li className="header">{company_category && company_category.name}</li>
                            <li className={id === company_category.slug ? "active" : ''}>
                                <Link to={`/categories/${company_category && company_category.slug}`}>All</Link>
                            </li>
                            {sub.map(subcat => {
                                return(
                                    <li key={subcat.slug} className={id === subcat.slug ? "active" : ''}><Link to={`/categories/${subcat.slug}`}>{subcat.name}</Link></li>
                                )
                            })}
                        </ul>
                    }
              
                </div>
            </div>
        </div>
    )
}
export default CategoriesList