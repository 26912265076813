import React, {useState, useEffect} from "react"
import Main from "../Helpers/Main"
import { countries } from "../Helpers/countries"
import { Grid, Alert, Fade} from "@mui/material"
import { Container } from "@mui/system"
import "../../../src/assets/signup.css"
import LocationFormInputs from "./locationFormInputs"
import { LoadingButton } from "@mui/lab"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrlDev } from "../Helpers/baseUrl";
import LocationPopup from "./locationPopup";
import { getLocalUser } from "../TokenControl/parts/useLocalUser";
import { useDispatch, useSelector } from "react-redux"
import { fetchUserInfo } from "../../redux/actions/admin-index"
import Loader from "../Admin/helpers/loader"

const LocationForm = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [errMessage, setErrMessage] = useState("")
    const [err, setErr] = useState(false)
    let initialCountry = countries.filter(el => el.code2A === "US")

    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)

    const [emptyFields, setEmptyFields] = useState([]) //This holds empty fields, as long there is any item button is disabled
    const [errors, setErrors] = useState([]) //This holds errors if there are any

    const user = useSelector(state => state.getUserInfo.user)
    let locations = user && user.user.locations


    const [fields, setFields] = useState({
        business_name: "",
        address: "",
        address2: "",
        city: "",
        zip: "",
        country: initialCountry[0].code2A,
        state: " ",
        phone: "",
        business_category: " ",
    })

    useEffect(() => {
        let items = []
        Object.keys(fields).forEach((item) => {
            if(item !== "place_id" && item !== "address2" && item !== "phone"){
                if(fields[item] === "" || fields[item] === " "){
                    items.push(item)
                } else {
                    items = items.filter(it => it !== item)
                }
            }
            if (item === "phone"){
                if(fields[item].length < 11){
                    items.push(item)
                } 
            }
        })
        setEmptyFields(items)
    }, [fields])
    
    useEffect(() => {
        if(locations && locations.length > 0){
            setLoader(false)
            navigate("/app/admin")
        }
    }, [user])

    const submitSignup = () => {
        setLoading(true)
        const data = {
            name: fields.business_name,
            parsed_address: {
              "address": fields.address,
              "country": fields.country  === "XK" ? "AL" : fields.country,
              "state": fields.state,
              "city": fields.city,
              "zip": fields.zip,
            },
            phone: fields.phone,
            taxonomyId: fields.business_category,
            without_placeid: 1,
        };
        axios.post(`${baseUrlDev}locations/create`, data, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then(res => {
            setLoading(false)
            if(res.data.status){
                dispatch(fetchUserInfo())
                setLoader(true)
            } else {
                setErrMessage(res.data.message)
                setErr(true)
                setTimeout(() => {
                    setErr(false)
                }, 5000)
                setTimeout(() => {
                    setErrMessage("")
                }, 6000)
            }
        }).catch(err => {
            setLoading(false)
            Object.keys(err.response.data.errors).map(key => {
                setErrors(prev => [...prev , {[key]: err.response.data.errors[key][0]}])
            })
        })
    }

    return(
        <Main blueHeader={true} noNav={true}> 
            {loader && <Loader fixed={true}/>}
            <Fade in={err}>
                <div className="signup-alert">
                    <Alert severity="error" className="custom-error-alert">{errMessage}</Alert>
                </div>
            </Fade>
            <LocationPopup/>
            <section className="book-demo-section">
                <Container  className="position-relative" maxWidth="xl">
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="book-demo-section-wrapper">
                           
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9} lg={10} style={{margin: "auto"}}>
                            <div className="form-container">
                                <div>
                                    <h3>Setup your first account!</h3>
                                    <p>After setup your account you will redirect to dashboard</p>
                                </div>
                                <LocationFormInputs errors={errors} setErrors={setErrors} fields={fields} setFields={setFields}/>
                                <div className="captcha-div">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</div>
                                <LoadingButton
                                    disabled={emptyFields.length > 0}
                                    style={{paddingLeft: loading && "30px"}}
                                    className="submit-signup"
                                    onClick={() => submitSignup()}
                                    loading={loading}
                                >
                                    Save
                                </LoadingButton>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </Main>
    )
}  
export default LocationForm