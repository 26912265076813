export const competitorsReducer = (state = [] ,action) => {
    switch(action.type){
        case 'GET_COMPETITORS':
            return{
                ...state,
                competitors: action.payload
            }
        case 'CLEAR_COMPETITORS':
            return{
                ...state,
                competitors: undefined
            }
        default:
            return{
                ...state
            }
    }
}