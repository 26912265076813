export const predefinedResponses = (state = [] ,action) => {
    switch(action.type){
        case 'GET_PREDEFINED_RESPONSES':
            return{
                ...state,
                predefined_responses: action.payload
            }
        case 'DELETE_PREDEFINED_RESPONSE':
            return{
                ...state,
                predefined_responses: {"data": state.predefined_responses.data.filter(item => item.id != action.payload)}
            }
        case 'ADD_PREDEFINED_RESPONSE':
            return{
                ...state,
                predefined_responses: action.payload
            }
        case 'EDIT_PREDEFINED_RESPONSE':
            const editedItem = action.payload;
            const index = state.predefined_responses.data.findIndex(item => item.id === editedItem.id)
            if (index !== -1) {
                const newData = [...state.predefined_responses.data];
                newData[index] = {
                    ...newData[index],
                    response: editedItem.response,
                    sentences: editedItem.sentences
                }
                return{
                    ...state,
                    predefined_responses: {
                        ...state.predefined_responses,
                        data: newData
                    }
                }
            }
        case 'CLEAR_PREDEFINED_RESPONSES':
            return{
                ...state,
                predefined_responses: undefined
            }
        default:
            return{
                ...state
            }
    }
}