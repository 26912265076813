import React from 'react'
import { VscTriangleRight } from 'react-icons/vsc';
import { openVideoModal } from '../../../redux/actions/admin-index';
import { useDispatch } from 'react-redux';

const VideoTutorialButton = ({ title }) => {
    const dispatch = useDispatch();
    
    return (
        <div className="video_tutorial__wrapper" onClick={() => dispatch(openVideoModal())}>
            <VscTriangleRight />
            <div className="text_wrapper">
                <h5>Learn More</h5>
                <h6>{title}</h6>
            </div>
        </div>
    )
}

export default VideoTutorialButton;
