const companiesGoogleDetailsReducer = (state = [], action) => {
    switch(action.type){
        case 'FETCH_COMPANIE_DETAILS_GOOGLE':
            return{
                ...state,
                company: action.payload.data,
            }
        case 'CLEAR_DATA':
            return{
                ...state,
                company: undefined
            }
        default:
            return{
                ...state
            }
    }
}
export default companiesGoogleDetailsReducer