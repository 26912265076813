const locationInital = {
    location: localStorage.getItem('location')
}
const groupInital = {
    group: localStorage.getItem('group')
}
const groupInitalLocation = {
    group_location: localStorage.getItem('location_of_group')
}
const impersonateInitial = {
    impersonate: localStorage.getItem('impersonate')
}

const demoInitial = {
    demo: localStorage.getItem('demo')
}
const cancelInitial = {
    cancel: localStorage.getItem('cancel')
}

const initialLanguage = {
    language: "en"
}

export const getUserInfo = (state = {}, action) => {
    switch(action.type){
        case 'GET_USER_INFO':
            return{
                ...state,
                user: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}

export const locationSelected = (state = locationInital, action) => {
    switch(action.type){
        case 'GET_LOCATION_SELECTED':
            return{
                ...state,
                location: action.payload,
            }
        default:
            return state
    }
}

export const groupSelected = (state = groupInital, action) => {
    switch(action.type){
        case 'GET_GROUP_SELECTED':
            return{
                ...state,
                group: action.payload
            }
        default:
            return state
    }
}
export const groupLocationSelected = (state = groupInitalLocation, action) => {
    switch(action.type){
        case 'GET_GROUP_LOCATION_SELECTED':
            return{
                ...state,
                group_location: action.payload
            }
        default:
            return state
    }
}

export const impersonateReducer = (state = impersonateInitial, action) => {
    switch(action.type){
        case 'CHECK_IMPERSONATE':
            return{
                ...state,
                impersonate: action.payload,
            }
        default:
            return state
    }
}

export const demoReducer = (state = demoInitial, action) => {
    switch(action.type){
        case 'CHECK_DEMO':
            return{
                ...state,
                demo: action.payload,
            }
        default:
            return state
    }
}
export const cancelReducer = (state = cancelInitial, action) => {
    switch(action.type){
        case 'CHECK_CANCEL':
            return{
                ...state,
                cancel: action.payload,
            }
        default:
            return state
    }
}
export const getInvoices = (state = [], action) => {
    switch(action.type){
        case 'GET_ACCOUNT_INVOICES':
            return{
                ...state,
                invoices: action.payload
            }
        default:
            return state
    }
}

export const getAgreements = (state = [], action) => {
    switch(action.type){
        case 'GET_ACCOUNT_AGREEMENTS':
            return{
                ...state,
                agreements: action.payload
            }
        default:
            return state
    }
}

export const getLanguage = (state = initialLanguage, action) => {
    switch(action.type){
        case 'LANGUAGE':
            return{
                ...state,
                language: action.payload
            }
        default:
            return state
    }
}