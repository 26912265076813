import React from "react"

const style = {
    span: {
        fontFamily: "Poppins-Regular",
        fontSize: "10px"
    },
    img: {
        marginBottom: "29px",
        paddingTop: "50px"
    },
    p: {
        marginBottom: "46px"
    }
}
const FlaggingThirdStep = ({step}) => {
    return(
        <div className={`report_review_list ${step == 3 ? 'active' : ''}`}>  
            {step == 3 && 
                <div className="report_review_reason" style={{textAlign: "center"}}>
                    <img style={style.img} src="/admin-icons/flagging/thankyoumsg.svg" /> 
                    <p style={style.p}>This review has been reported to Google</p>
                    <span style={style.span}>Thanks for helping make reviews better</span>
                </div>
            }
        </div>
    )
}
export default FlaggingThirdStep