import React from "react"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { fetchNotificationsCount, readLoadedNotifications } from "../../../redux/actions/admin-index"
import { useNavigate } from 'react-router-dom';
import AppUrl from "../../Helpers/appUrl"
import { formatDateNoty } from "../helpers/formatDate";

const style = {
    date: {
        fontSize: '10px',
        color: '#92989D',
        fontFamily: 'Poppins-Regular',
        fontWeight: 400,
        textAlign: 'right',
        display: 'block',
        margin: 0,
    },
};

const NewNotificationItem = ({notification, delay, setOpen}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const readNoty = (e, id) => { 
        e.preventDefault()
        navigate(`/app/admin/reviews/list/${notification.review.id}`)
        dispatch(readLoadedNotifications(id))
        AppUrl.postData(
            `notifications/read`,
            {id: id},
            {},
            (response) => dispatch(fetchNotificationsCount()),
        );
        setOpen(false)
    }
    return(
        <div className="noty-item" style={{ animationDelay: delay, backgroundColor: notification?.read ? "#fff" : "#F5F9FD", borderLeft: notification?.read ? "2px solid #00B2E3" : "none"     }}>
            {notification?.review?.id ?
             <Link to={`/app/admin/reviews/list/${notification.review.id}`} onClick={(e) => readNoty(e, notification.id)}>
                <div>
                    <h4>{notification?.title}</h4>
                    <span style={style.date}>{formatDateNoty(notification.created_at)}</span>
                </div>
            </Link>
            :
            <div>
                <h4>{notification?.title}</h4>
                <span style={style.date}>{formatDateNoty(notification.created_at)}</span>
            </div>
            }
        </div>
    )
}
export default NewNotificationItem