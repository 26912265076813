import React, {useEffect} from "react"
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { Grid, Breadcrumbs, Skeleton, Rating } from "@mui/material";
import {MdNavigateNext} from "react-icons/md";

//My components
import Main from "../Helpers/Main"
import CategoriesList from "../Categories/categories_list"
import Filters from "../Categories/filters"
import Companies from "../Companies/companies";
import Search from "../Helpers/Search";
import { fetchCategoryDetails, categoryDetailClear, fetchSearchCatComp } from "../../redux/actions";
import AlertComponent from "../Helpers/Alert";
import {fetchFindKeywords} from "../../redux/actions";
import OneRowCompany from "./parts/OneRowCompany";




const FindKeywords = () => {
    const {search_text} = useParams();
    const dispatch = useDispatch();



    useEffect(()=>{
        dispatch(fetchFindKeywords(search_text));
    },[]);

    const result = useSelector(state => state?.fetchFindKeywords?.companie?.companies?.data) || [];

  return (
    <Main>
            <Helmet>
                <title>Category Details - iReview</title>
                <meta property="og:title" content="Category Details - iReview"/>
                <meta property="og:url" content={`https://iReview.com/categories/${search_text}`} />
            </Helmet>
            <AlertComponent/>
            {/*  */}
            <Container maxWidth="xl" className="mb-100">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb" separator={<MdNavigateNext fontSize="small" />} className="breadcrumb-padding">
                            <Link to="/" className="no-text-decoration breadcrumb-link">
                                Categories
                            </Link>
                            <h6 className="breadcrumb-active-el">{decodeURI(search_text)}</h6>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Container>
            {/*  */}
            <div className="main-fullscreen-blue pt-25 pb-25">
                <Container maxWidth="xl">
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={8}>
                            <div className="category-item-header">
                                <h1 className="d-flex align-items-center">Best in {decodeURI(search_text)}</h1>
                                <p>Best companies listed in {decodeURI(search_text)}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Search/>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            {/*  */}
            <Container maxWidth="xl" className="mt-100">
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={9} lg={9}>
                        {result?.map((item,index)=>{
                            // console.log("item:",item);
                            return <OneRowCompany key={index} item={item}/>
                        })}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                            <Filters id={1}/>
                    </Grid>
                </Grid>
                
            </Container>
    </Main>
  )
}

export default FindKeywords