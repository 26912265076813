const accreditationReducer = (state = [], action) => {
    switch(action.type){
        case 'GET_ACCREDITATION_DETAILS':
            return{
                ...state,
                accreditated_company: action.payload,
            }
        case 'CLEAR_ACCREDITATION_DETAILS':
            return{
                ...state,
                accreditated_company: undefined,
            }
        default:
            return{
                ...state
            }
    }
}
export default accreditationReducer