import React, {useEffect} from "react"
import { CircularProgress  } from "@mui/material"
import axios from "axios"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { useTranslation } from "react-i18next"

const CustomerIdPopup = ({setCustomerId}) => {
    const style = {
        div: {
            background: "#ffff",
            minWidth: "fit-content",
            padding: "20px",
            width: "400px",
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: "fit-content",
            margin: "auto",
            borderRadius: "5px",
            textAlign: "center"
        },
        h4: {
           fontFamily: "Poppins-Regular",
           fontSize: "14px"
        },
        progress:{
            opacity: '0.1',
        }
    }
    const {t} = useTranslation()
    const createCustomerId = () => {
        axios.post(`${baseUrlDev}wallet/create-ipaymer-customer`, {}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
        .then((res) => {
            setCustomerId(res.data.customer_id)
        }).catch((err) => {
            console.log(err)
        })

    }
    useEffect(() => {
        createCustomerId()
    }, [])

    return(
        <div style={style.div}>
            <h4 style={style.h4}>{t("Please Wait")}</h4>
            <CircularProgress color="inherit" style={style.progress}/>
        </div>
    )
}
export default CustomerIdPopup