import React, { useState, useEffect } from "react"
import { Modal} from "@mui/material"
import WalletBody from "./wallet_body"
import CustomerIdPopup from "./customer_id_popup"
import { useSelector, useDispatch } from "react-redux"
import { changeWalletVisibility } from "../../../../redux/actions/admin-index"

const Wallet = () =>{
    const dispatch = useDispatch()
    const [customerId, setCustomerId] = useState()

    const user = useSelector(state => state.getUserInfo.user)
    const visibility = useSelector(state => state.getWalletVisibility.visibility)

    useEffect(() => {
        if(user?.user?.ipaymer_id){
            setCustomerId(user?.user?.ipaymer_id)
        } else {
            setCustomerId("")
        }
    }, [user])

    const handleClose = () => {
        dispatch(changeWalletVisibility(false))
    }

    return(
        <Modal 
            open={visibility}
            onClose={handleClose}
            aria-labelledby="modal-wallet-title"
            aria-describedby="modal-wallet-description"
        >
            {
                user && customerId ? 
                <>
                    <WalletBody setOpen={handleClose} customerId={customerId} />
                </>
                :
                <>
                    <CustomerIdPopup setCustomerId={setCustomerId}/>
                </>
            }
        </Modal>
    )
}
export default Wallet