import { Box, Container , Grid, Skeleton, } from '@mui/material'
import React, { useEffect } from 'react'
import OneReviewCard from '../part/OneReviewCard'

import {IoIosArrowDown} from "react-icons/io";
import {IoIosArrowUp} from "react-icons/io";

import {useDispatch,useSelector} from 'react-redux';
import {fetchRecentReviews} from '../../../redux/actions';

const ListOfCards = () => {
    const dispatch = useDispatch();
    var recentReviews = useSelector(state => state.recentReviewsReducer.recent_reviews);


    // startline reorder
    const reorderRecentReviews = (recentReviews) => {
        if(!recentReviews) return;
        let newRecentReviews = [];
        for(let i = 0; i < recentReviews.length; i++){
            if(recentReviews[i].company.name.logo){
                newRecentReviews.unshift(recentReviews[i]);
            }else{
                newRecentReviews.push(recentReviews[i]);
            }
        }
        return newRecentReviews;
    }
    recentReviews = reorderRecentReviews(recentReviews);
    // endline reorder

     
       

    const [showMore,setShowMore] = React.useState(false);

    useEffect(()=>{
        dispatch(fetchRecentReviews());
    },[]);
    
    
  return (
    <section className="section-font-family">
        <Container maxWidth="xl">
            <Box sx={{paddingTop:"20px",paddingBottom:"20px"}}>
                <Grid container spacing={2}>
                    {recentReviews && recentReviews.slice(0,(showMore ? Number.MAX_SAFE_INTEGER : 12)).map((item, index) => {
                        return <OneReviewCard key={index} item={item}/>
                    })}

                    {!recentReviews && Array.from({length: 12}).map((item,index)=>{
                        return <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={index}>
                            <Skeleton variant="rectangular" style={{width:"100%",height:"200px"}} />
                            </Grid>
                    })}

                </Grid>
            </Box>
            <Box onClick={()=>setShowMore(!showMore)} className="footer-cards-s">
                {!showMore ? <><IoIosArrowDown style={{marginRight:"5px"}}/>Show more reviews</>: <>
                <IoIosArrowUp style={{marginRight:"5px"}}/>Hide extended reviews
                </>}
            </Box>
        </Container>
    </section>
  )
}

export default ListOfCards