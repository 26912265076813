export const showSidebarReducer = (state = false, action) => {
    switch(action.type){
        case 'SHOW_SIDEBAR':
            return{
                ...state,
                show: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}


export const showNavbarReducer = (state = false, action) => {
    switch(action.type){
        case 'SHOW_NAVBAR':
            return{
                ...state,
                show: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}
