export const getGroupPage = (state = {}, action) => {
    switch(action.type) {
        case 'GET_GROUP_PAGE':
            return {
                 ...state, 
                 groupPage: action.payload 
                }
        case 'CLEAR_GROUP_PAGE':
            return {
                ...state,
                groupPage: undefined
            }
        default :
            return { ...state }
    }

}

export const getGroups = (state = {}, action) => {
    switch(action.type) {
        case 'GET_GROUPS':
            return {
                 ...state, 
                 groups: action.payload 
                }
        case 'CLEAR_GROUPS':
            return {
                ...state,
                groups: undefined
            }
        default :
            return { ...state }
    }

}

export const getGroup = (state = {}, action) => {
    switch(action.type) {
        case 'GET_GROUP':
            return {
                 ...state, 
                 group: action.payload 
                }
        case 'CLEAR_GROUP':
            return {
                ...state,
                group: undefined
            }
        default :
            return { ...state }
    }

}