import React, {useState, useEffect} from "react"
import Main from "../Helpers/Main"
import SignupForm from "./signupForm"
import { Grid} from "@mui/material"
import { Container } from "@mui/system"
import "../../../src/assets/signup.css"
import axios from "axios"
import { baseUrlDev } from "../Helpers/baseUrl"
import {IoCheckmarkCircleSharp} from "react-icons/io5"
import { LoadingButton } from "@mui/lab"
import NavbarIrevu from "../Admin/Navbar_Irevu"
import { useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const SignUp = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false)
    const [showFields, setShowFields] = useState(["email"]) //This is used to tell what fields to display, because on change of another field we show the field that comes after it
    const [emptyFields, setEmptyFields] = useState([]) //This holds empty fields, as long there is any item button is disabled
    const [errors, setErrors] = useState([])//This holds errors if there are any
    const [fields, setFields] = useState({
        email: "",
        business_name: "",
        phone: "",
        first_name: "",
        last_name: "",
        password: "",
        place_id: ""
    })
    const userState = useSelector(state => state.getUserInfo.user)
    useEffect(() => {
        if(searchParams.get("email")){
            setShowFields(["email", "business_name", "phone", "first_name", "last_name", "password"])
            setFields((prevState) => ({...prevState, ["email"]: searchParams.get("email")}))
        }
        if (searchParams.get("first_name")){
            setShowFields(["email", "business_name", "phone", "first_name", "last_name", "password"])
            setFields((prevState) => ({...prevState, ["first_name"]: searchParams.get("first_name")}))
        }
        if (searchParams.get("last_name")){
            setShowFields(["email", "business_name", "phone", "first_name", "last_name", "password"])
            setFields((prevState) => ({...prevState, ["last_name"]: searchParams.get("last_name")}))
        }

        if (searchParams.get("phone")){
            setShowFields(["email", "business_name", "phone", "first_name", "last_name", "password"])
            setFields((prevState) => ({...prevState, ["phone"]: searchParams.get("phone")}))
        }

    }, [])

    useEffect(() => {
        let items = []
        Object.keys(fields).forEach((item) => {
            if(item !== "place_id"){
                if(fields[item] === ""){
                    items.push(item)
                } else {
                    items = items.filter(it => it !== item)
                }
            }
        })
        setEmptyFields(items)
    }, [fields])

    useEffect(() => {
        if(userState || localStorage.getItem("user")){
            navigate("/app/admin")
        }
    }, [userState])

    const submitSignup = () => {
        setLoading(true)
        let data = {
            first_name: fields.first_name,
            last_name: fields.last_name,
            email: fields.email,
            password: fields.password,
            phone: fields.phone,
            place_id: fields.place_id
        }
        axios.post(`${baseUrlDev}register`, data, {headers:{'APP-VERSION': 'react' }}).then(res => {
            setLoading(false)
            localStorage.setItem('user', JSON.stringify(res.data));
            setTimeout(() => {
                window.location.href = res.data.link
            }, 500);
        }).catch(err => {
            setLoading(false)
            Object.keys(err.response.data.errors).map(key => {
                setErrors(prev => [...prev , {[key]: err.response.data.errors[key][0]}])
            })
        })
    }

    return(
        <Main blueHeader={true} noNav={true}>
            <NavbarIrevu blueHeader={true}/>
            <section className="book-demo-section">
                <Container  className="position-relative" maxWidth="xl">
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="book-demo-section-wrapper">
                                <div>
                                    <h3>Get More Reviews Be the Obvious Choice</h3>
                                    <p>Generate more reviews, rank higher on search and get more visibility in more places, more often – the competitive edge that drives real business results.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9} lg={10} style={{margin: "auto"}}>
                            <div className="form-container">
                                <div>
                                    <h3>Let's get started!</h3>
                                </div>
                                <SignupForm errors={errors} setErrors={setErrors} fields={fields} showFields={showFields} setFields={setFields} setShowFields={setShowFields}/>
                                <div className="captcha-div">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</div>
                                <LoadingButton
                                    disabled={emptyFields.length > 0}
                                    style={{paddingLeft: loading && "30px"}}
                                    className="submit-signup"
                                    onClick={() => submitSignup()}
                                    loading={loading}
                                >
                                    Create Account
                                </LoadingButton>
                            </div>
                        </Grid>
                    </Grid>
                    <div className="images-first-sp">
                        <div className="position-relative">
                            <img src="/image/signup-1.svg" alt="google" className="first-image-sp"/>
                            <img src="/image/signup-star.svg" alt="star" className="second-image-sp"/>
                        </div>
                    </div>
                    <div className="images-second-sp">
                        <div className="position-relative">
                            <img src="/image/signup-facebook.svg" alt="facebook" className="third-image-sp"/>
                            <img src="/image/signup-search.svg" alt="search-icon" className="fourth-image-sp"/>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="why-demo-irevu">
                <Container  className="position-relative" maxWidth="xl">
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4}>
                            <div className="why-demo-irevu-div">
                                <h3>Why demo ireview?</h3>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <ul>
                                <li>
                                    <IoCheckmarkCircleSharp/>
                                    Get more positive reviews on top review sites. 
                                </li>
                                <li>
                                    <IoCheckmarkCircleSharp/>
                                    Manage reviews from dozens of review sources like Google, Yelp, Tripadvisor, Facebook and more…
                                </li>
                                <li>
                                    <IoCheckmarkCircleSharp/>
                                    Know what your customers are saying about you.
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <ul>
                                <li>
                                    <IoCheckmarkCircleSharp/>
                                    Communicate and respond to your customers faster and easier on all review sites.
                                </li>
                                <li>
                                    <IoCheckmarkCircleSharp/>
                                    Rank higher, build trust and get more visibility where your customers are searching.
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className="listing-companies">
                <Container  className="position-relative" maxWidth="xl">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="quick-and-easily-div"> 
                                <h3>Thousands of companies rely on their reviews to drive business:</h3>
                            </div>
                            <div className="quick-and-easily-logos">
                                <img src="/image/subway_logo-1.png"/>
                                <img src="/image/andrix_logo-200x145.png"/>
                                <img src="/image/arista_logo-200x145.png"/>
                                <img src="/image/farmers_logo-200x145.png"/>
                                <img src="/image/juicerx_logo-200x145.png"/>
                                <img src="/image/urbanoasis_logo-200x145.png"/>
                                <img src="/image/allstate_logo.png"/>
                                <img src="/image/arcteryx_logo.png"/>
                                <img src="/image/barque_logo-200x145.png"/>
                                <img src="/image/gm_logo-200x145.png"/>
                                <img src="/image/shelborne_logo-200x145.png"/>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className="why-demo-irevu">
                <Container  className="position-relative" maxWidth="xl">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="why-demo-irevu-div text-center">
                                <h3>Reviews are the first organic results on Google.</h3>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </Main>
    )
}  
export default SignUp