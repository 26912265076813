export const PageVisits = (state = [], action) => {
    switch(action.type){
        case 'GET_PAGE_VISITS':
            return{
                ...state,
                page_visits: action.payload,
                loading: false
            }
        case 'CLEAR_PAGE_VISITS':
            return{
                ...state,
                page_visits: undefined,
                loading: false
            }
        case 'LOADING_TABLES':
            return{
                ...state,
                loading: true
            }
        default:
            return state
    }
}