import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Avatar, Rating, Skeleton} from "@mui/material";

//Fetch
import { fetchRecentReviews } from "../../redux/actions";


const Recent = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchRecentReviews())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    let reviews = useSelector(state => state.recentReviewsReducer.recent_reviews)
    if(reviews === undefined){
        return(
            <div className="full-width-div masonry-grid mt-100">
                {[...Array(15)].map((e, i) => {
                    return(
                        <div key={`skeleton-recent${i}`}>
                            <div className="review-item">
                                <div className="review-item-header">
                                    <div>
                                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                                        <Skeleton height={10} width="40%" style={{ marginLeft: 15 }}/>
                                    </div>
                                </div>
                                <div className="review-item-body">
                                    <Skeleton height={200}/>
                                </div>
                            </div>
                        </div>  
                    )
                })}
            </div>
        )
    }

    return(
        <div className="full-width-div masonry-grid mt-100">
            {
                reviews && reviews.map((review, i) => {
                    return(  
                        <div key={`rev${i}`}>
                            <div className="review-item">
                                <div className="review-item-header">
                                    <div>
                                        {review.image ? <Avatar src={review.image} alt={review.name}/> : <Avatar/>}
                                        <Rating className="custom-margin-avatar" value={review.rating} readOnly />
                                    </div>
                                    <h4><span>{review.author.name}</span> reviewed <span>{review.company.name.name}</span></h4>
                                </div>
                                <div className="review-item-body">
                                    <p>{review.content}</p>
                                </div>
                            </div>
                        </div>  
                    )
                })
            }
        </div>
    )
}
export default Recent