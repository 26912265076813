import { styled, InputBase} from "@mui/material";

const LocationSelectCustom = styled(InputBase)(({ theme }) => ({
    '& label': {
        backgroundColor: "#fff",
    },
    '& .MuiInputBase-input': {
        borderRadius: 6,
        position: 'relative',
        backgroundColor: "#fff",
        border: `1px solid`,
        borderColor: "#ced4da",
        fontSize: "13px",
        padding: '8px 26px 8px 12px',
        color: "#25313C",
        fontFamily: "Poppins-Regular",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
    "& .MuiSelect-select[aria-expanded='true']": {
        borderColor: '#18AEE3'
    },
    '& .MuiSvgIcon-root' :{
        color: "#25313C"
    },
    '& .MuiInputLabel-formControl':{
        backgroundColor: "#fff",
    },
    '& .MuiMenuItem-root':{
        color: "#333333",
        fontFamily: "Poppins-Regular",
        fontSize: "14px"
    },
    '& .MuiPaper-root':{
        top: "48px"
    }
}));
export default LocationSelectCustom