export function formatDateCard(value) {
    if (value.length >= 2 && value.indexOf("/") === -1) {
      value = `${value.substr(0, 2)}/${value.substr(2)}`;
    }
    return value;
}

export function formatCardNumber(value) {
  if (value?.length <= 19) {
    value = `${value.replace(/\s/g, '').match(/.{1,4}/g).join(' ')}`;
  }
  return value;
}