import React, {useState} from "react"
import {Rating, Avatar} from "@mui/material";
import {MdOutlineFilterList, MdOutlineLocationOn} from "react-icons/md";
import {IoMdGlobe} from "react-icons/io";
import { Link } from "react-router-dom";


const Collapsable = ({company, category}) => {
    const [show, setShow] = useState(false)
    return(
        <div className="companies-list-box">
            <Link to={`/company/${company.landing}`} className="no-text-decoration">
                <div className="companies-list-box-inner">
                    {company.img && <img src={company.img} alt={company.name}/>}
                    <div>
                        <h5>{company.name}</h5>
                        <div>
                            <Rating className="custom-margin-avatar" value={company?.totalReviewsAvg ?? 0} readOnly />
                            <span>Reviews: {company.totalReviews.toLocaleString()}</span>
                        </div>
                    </div>
                </div>
            </Link>
            <div className="companies-info-box-inner">
                <a href={company.website} target="_blank" className="no-wrap-link"><IoMdGlobe/>{company.website ? company.website : "No website"}</a>
                <div className="d-flex align-items-center remove-last-comma">
                    {company.categories && company.categories.map(category => {
                        return(
                            <Link to={`/categories/${category.slug}`} key={category.slug}>
                                {company.categories > 1 ?  <h6><MdOutlineFilterList/>{category.name}<span className="comma">,</span></h6> :  <h6><MdOutlineFilterList/>{category.name}</h6>} 
                            </Link>
                        )
                    })}
                </div>
                <h6><MdOutlineLocationOn/>{company.address ? company.address : "No address found"}</h6>
            </div>
            <div className="company-latest-reviews">
                {company.recentReviews && company.recentReviews.length > 0 && <button onClick={(e) => setShow(!show)}>{show ? 'Collapse' : 'Expand'} Latest Reviews</button>}
                <div className={`custom-horizontal-scrollbar pb-25 ${show ? 'active' : ''}`}>
                    {company.recentReviews && company.recentReviews.map((review) => {
                        return(
                            <Link to={`/company/${company.landing}`} key={`review${company.id}${review.id}`}>
                                <div className="company-latest-review">
                                    <span>{review.created_at_human}</span>
                                    <div>
                                        <Avatar sx={{ bgcolor: "blue" }}>{review.author.image}</Avatar>
                                        <Rating className="custom-margin-avatar" value={review.rating} readOnly />
                                    </div>
                                    <p>{review.content ? review.content : "No text"}</p>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default Collapsable