import React, {useState} from "react";
import { Link } from "react-router-dom";
import { ClickAwayListener, Collapse, Skeleton, CircularProgress, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompaniesGoogle, clearCompaniesLoader } from "../../redux/actions";
import axios from "axios";
import { baseUrlDev } from "../Helpers/baseUrl";
import { getAccreditationDetails } from "../../redux/actions";

const AccreditationSignupForm = ({active, setActive, company, setCompany}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [searching, setSearching] = useState(false)
    const [text, setText] = useState("")
    
    const [errors, setErrors] = useState([])
    const companies = useSelector(state => state.companiesSearchReducer.companies)

    const handleSearch = (e) => {
        dispatch(clearCompaniesLoader())
        let value = e.target.value;
        setText(value)
        if(value.length >= 3){
            dispatch(fetchCompaniesGoogle(value))
            setSearching(true)
        } else {
            setSearching(false)
        }
        if(value.length == 0 ){
            setCompany("")
        }
    }

    const handleCompanyName = (name, place_id) => {
        setText(name)
        setCompany(place_id)
        setSearching(false)
    }

    const registerAccreditation = () => {
        setLoading(true)
        axios.post(`${baseUrlDev}accreditation-process`, {place_id: company}, {headers:{'APP-VERSION': 'react' }}).then(res => {
            setLoading(false)
            setActive(2)
            dispatch(getAccreditationDetails(company))
        })
        .catch(err => {
            setLoading(false)
            err.response.data.errors ?
            Object.keys(err.response.data.errors).map(key => {
                setErrors(errors => [...errors, err.response.data.errors[key][0]])
            })
            :
            setErrors(["Something Went Wrong"])
            setTimeout(() => {
                setErrors([])
            }, 5000)
        })

        
    }

    return(
        <div className="accreditation-form">
            {errors && errors.map(err => {
                return (
                    <div className="alert-inside-modal" key={err}>
                        <Alert severity="error" className="custom-error-alert">{err}</Alert>
                    </div>
                )
            })}
            <ClickAwayListener onClickAway={() => setSearching(false)}>
                <>
                    <input type="text" placeholder="Business Name" onChange={(e) => handleSearch(e)} value={text}/>   
                    <Collapse in={searching}>
                        <div className="search-results-wrapper custom-scrollbar-vertical">
                            {companies === undefined ? 
                                <>
                                    <Skeleton height={35} width={"100%"} style={{marginTop: "15px", marginBottom: "15px", transform: "unset"}}/> 
                                    <Skeleton height={35} width={"100%"} style={{marginBottom: "15px", transform: "unset"}}/>
                                    <Skeleton height={35} width={"100%"} style={{marginBottom: "15px", transform: "unset"}}/>
                                    <Skeleton height={35} width={"100%"} style={{marginBottom: "15px", transform: "unset"}}/>
                                </>
                            : 
                            companies.length == 0 ?
                                <h5>No business found with this name</h5>
                            :
                            companies.map(company => {
                                return(
                                    <h5 key={company.place_id} onClick={() => handleCompanyName(company.description,company.place_id)}>{company.description}</h5>
                                )
                            })} 
                        </div>
                    </Collapse>
                </>
            </ClickAwayListener>
            <div className="accreditation-form-btn">
                {!company ? 
                    <button disabled={true}>Register now</button>
                :
                    <button className="d-flex align-items-center justify-content-center" onClick={() => registerAccreditation()}>
                        {loading && 
                            <CircularProgress 
                                style={{color: "#fff", width: "15px", height: "15px", margin: "0 10px"}}
                            />
                        }
                        Register now
                    </button>
                }
                <span>Already have an account? <Link to="/login">To come in</Link></span>
            </div>
        </div>
    )
}
export default AccreditationSignupForm