const searchCatAndComp = (state = [], action) => {
    switch(action.type){
        case 'SEARCH_CATEGORIES_COMPANIES':
            return{
                ...state,
                result: action.payload.data
            }
        default:
            return{
                ...state
            }
    }
}
export default searchCatAndComp