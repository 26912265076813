import React from 'react';
import { Box, Grid, Rating } from '@mui/material'

const OneReviewCard = (props) => {
    
    let { rating , content , source } = props?.item;
    let { name } = props?.item?.author;


    const parser = new DOMParser();
    content = parser.parseFromString(content, 'text/html').body.textContent.trim();
    content = content.trim().replace(/(<br>|<br\/>|xa0|\n)/g, ' ');
    content = content.replace(/(?:xa0)?&#(\d+);/g, function(match, capture) {
        return String.fromCharCode(capture);
    });

    name = name.trim().replace(/(<br>|<br\/>|xa0|\n)/g, ' ');

    function onerror(e){
        e.target.src = '/image/placeholder-image-card-s.png';
        e.target.remove();
    }

    let companyName = props?.item?.company?.name?.name ?? " ";
    companyName = companyName.trim().replace(/(<br>|<br\/>|xa0|\n|\-)/g, ' ');
   
    return (
        <Grid  item xs={12} sm={12} md={6} lg={4} xl={3}>
            <div className="one-card-list-s">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: 'background.paper',flexWrap:"wrap" }}>
                    <Box sx={{display:"flex"}}>
                        <img className="card-limits-image-profile" src={source?.logo} />
                        <Box sx={{ ml:"3px"}}>
                            <div className="card-name-author">{name}</div>
                            <div>Wrote a Review</div>
                        </Box>
                    </Box>
                    <Rating name="read-only" value={rating} readOnly />
                </Box>
                <Box className="name-of-bussines-card">{companyName}</Box>
                <Box sx={{textAlign:"center"}}>
                    <img className="card-limits-image" onError={onerror} src={props?.item?.company?.name?.logo ? props?.item?.company?.name?.logo : "error"} />
                </Box>
                <Box className="clamp-3-line-rows">
                        {content ? content : ""}
                </Box>
            </div>
        </Grid>
    )
}

export default OneReviewCard