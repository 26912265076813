import axios from 'axios'
import { mainBaseUrl, mainBaseUrlDev } from '../../Helpers/baseUrl';

const expires = new Date();
expires.setDate(expires.getDate() + 14);

export function apiFetchTokenLogin(token, callback, path) {
    localStorage.removeItem("location");
    localStorage.removeItem("location_of_group");
    axios({
        method: 'post',
        url: `${mainBaseUrlDev}/api/v2/sso/login`,
        data: { token },
        headers: {
            'APP-VERSION': 'react',
        }
    }).then((response) => {
        document.cookie = `irw_token=${response.data.i_token}; domain=.irevu.com; expires=${expires}; path=/`;
        document.cookie = `i_token=${response.data.i_token}; domain=.irevu.com; expires=${expires}; path=/`;
        callback(null, response.data)
    }).catch((error) => {
        callback(error, null)
    });
}



