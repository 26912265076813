import React, {useState} from "react"
import {GiHamburgerMenu} from "react-icons/gi"
import {MdOutlineClear} from "react-icons/md"
import { Link } from "react-router-dom"
import { Container } from "@mui/material"
import OurProductSuite from "../NavBarParts/OurProductSuite"

const NavbarIrevu = ({blueHeader}) => {
    const [show, setShow] = useState(false)
    const user = localStorage.getItem("user")
    return(
        <div className={`navbar-container navbar-container-mobile ${show ? 'active' : ''} ${blueHeader ? "blue-navbar-wrapper" : ""}`}>
            <Container  maxWidth="xl">
                <div className="navbar-main">
                    <a href="https://irevu.com">
                        <img src={`${blueHeader ? '/admin-images/irevu_tm_white.svg' : '/admin-images/iReview.svg'}`} height="40px" alt="logo"/>
                    </a>
                    {show !== true ? <GiHamburgerMenu onClick={() => setShow(!show)}/> : <MdOutlineClear onClick={() => setShow(false)}/>}
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className="navbar-elements navbar-elements-irevu">
                            <ul>
                                <li style={{position:"relative"}}>
                                    <OurProductSuite irevu={true}/>
                                </li>
                                <li>
                                    <a href="https://irevu.com/listings/">Listing</a>
                                </li>
                                <li>
                                    <a href="https://irevu.com/pricing/">Pricing</a>
                                </li>
                                <li>
                                    <a href="https://irevu.com/reviews/">Reviews</a>
                                </li>
                                <li>
                                    <a href="https://irevu.com/service/">Full Service</a>
                                </li>
                                <li>
                                    <a href="https://local.ireview.com">Local</a>
                                </li>
                                <li>
                                    <a href="https://irevu.com/blog/">Blog</a>
                                </li>
                            </ul>
                        </div>
                        <div className="navbar-elements navbar-elements-irevu-last">
                            <ul className="align-items-center">
                                <li>
                                    <a className="d-flex align-items-center" href="tel:(888) 744-7388"><img src="/admin-icons/call-icon.svg"/>(888) 744-7388</a>
                                </li>
                                <li className="li-as-btn">
                                    <Link to="/create">Sign Up for Free</Link>
                                </li>
                                <li className="li-outline-as-btn">
                                    <Link to="/login">Sign In</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
export default NavbarIrevu