import React, {useEffect} from "react"
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { Grid, Breadcrumbs, Skeleton,Box } from "@mui/material";
import {MdNavigateNext} from "react-icons/md";

//My components
import Main from "../Helpers/Main"
import CategoriesList from "./categories_list"
import Filters from "./filters"
import Companies from "../Companies/companies";
import Search from "../Helpers/Search";
import { fetchCategoryDetails, categoryDetailClear } from "../../redux/actions";
import AlertComponent from "../Helpers/Alert";

const Category = () => {
    const dispatch = useDispatch();
    let { id } = useParams();
    let category_name = id.replace('-', ' ')


    useEffect(() => {
        dispatch(fetchCategoryDetails(id, 1))
        return () => {
            dispatch(categoryDetailClear())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const result =  useSelector(state => state.categoryReducer.category)

    const res_category_details = result && result.category
    const category_companies = result && result.companies && result.companies.data
    const category_pagination = result && result.companies && result.companies.meta
    const company_category = res_category_details && res_category_details

    return(
        <Main>
            <Helmet>
                <title>Category Details - iReview</title>
                <meta property="og:title" content="Category Details - iReview"/>
                <meta property="og:url" content={`https://iReview.com/categories/${id}`} />
            </Helmet>
            <AlertComponent/>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb" separator={<MdNavigateNext fontSize="small" />} className="breadcrumb-padding">
                            <Link to="/" className="no-text-decoration breadcrumb-link">
                                Categories
                            </Link>
                            <h6 className="breadcrumb-active-el">{category_name}</h6>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Container>
            <div className="main-fullscreen-blue pt-25 pb-25">
                <Container maxWidth="xl">
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={8}>
                            <div className="category-item-header">
                                <h1 className="d-flex align-items-center">Best in {company_category === undefined ? <Skeleton height={35} width={200} style={{marginLeft: '10px'}}/> : company_category.name}</h1>
                                <p>Best companies listed in {company_category === undefined ? <Skeleton height={35} width={200} style={{marginLeft: '10px'}}/> : company_category.name}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Search/>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth="xl" className="mt-100 mb-100">
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={4} lg={3}>
                        <CategoriesList subcategories={res_category_details} company_category={company_category}/>
                    </Grid>
                    <Grid item xs={12} md={8} lg={6}>
                    {category_companies === undefined ? 
                    <>
                        <Skeleton height={30}  width="50%" style={{marginBottom: "10px"}}/>
                        <Skeleton height={10}  width="100%"/>
                        <Skeleton height={10}  width="100%"/>
                        <Skeleton height={10}  width="100%"/>
                    </>
                    : category_companies.length > 0 ?
                        <div className="companies-list-holder">
                            <h5>Best companies in this category</h5>
                            <p>{`${category_pagination.from}-${category_pagination.to} of ${category_companies.length}`} results based on current filters. Ordered by ireview and number of reviews. Default filter settings show companies as best in this category if they're actively asking for reviews and have received 25+ in the past 12 months. You can adjust these filters.</p>
                        </div>
                    : ''}
                        {category_companies === undefined ? 
                            [...Array(3)].map((e, i) => {
                                return(
                                    <div className="companies-list-holder" key={`cat-details-${i}`}>
                                        <div className="companies-list-box" style={{ marginTop: 15 }}>
                                            <div className="companies-list-box-inner">
                                                <Skeleton animation="wave" variant="circular" width={40} height={40} />
                                                <div  style={{ marginLeft: 15 }}>
                                                    <Skeleton height={15}  width={200}/>
                                                    <Skeleton height={10}  width={200}/>
                                                    <Skeleton height={10}  width={200}/>
                                                    <Skeleton height={10}  width={200}/>
                                                </div>
                                            </div>
                                            <div className="companies-info-box-inner">
                                                <Skeleton height={15} width="30%" style={{ marginRight: 15 }}/>
                                                <Skeleton height={15} width="30%" style={{ marginRight: 15 }}/>
                                                <Skeleton height={15} width="30%" style={{ marginRight: 15 }}/>
                                            </div>
                                            <div className="company-latest-reviews">
                                                <Skeleton height={30} width="30%"/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <Companies companies={category_companies} pagination={category_pagination} id={id} category={company_category}/>
                        }   
                    </Grid>
                    <Box
                        component={Grid}
                        item
                        lg={3}
                        display={{ xs: "none", lg: "block" }} >
                        <Filters id={id}/>
                    </Box>
                </Grid>
            </Container>
        </Main>
    )
}
export default Category