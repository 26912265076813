import { Grid, Box } from '@mui/material'
import React from 'react';
import { ClickAwayListener } from '@mui/material';
import useScrollHook from './useScrollHook';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';


const OurProductSuite = ({irevu}) => {
    const [show, setShow] = React.useState(false);

    useScrollHook({
        onScroll: () => {
            setShow(false);
        }
    });

    return (
        <>
            <a onClick={() => setShow(!show)} className="c-pointer">{irevu ? "Included" : "Our Product Suite"}
            {show ? <IoIosArrowUp  className="hide-on-mobile" style={{ color: irevu ? "#103254" : "#00B4E5", display: "inline-block" }} /> : <IoIosArrowDown className="hide-on-mobile" style={{ color: irevu ? "#103254" : "#00B4E5",  display: "inline-block", marginBottom: "-4px" }} />}</a>

            {show && <ClickAwayListener onClickAway={() => setShow(false)}><Box className="dropdown-menu-suite">
                <Box sx={{ background: "#ffffff", borderRadius: "8px" }}>
                    <ul className="landing-included-list">
                        <li className="li-no-margin-b" onClick={(e)=> e.target.firstChild.click()}>
                            <a href="https://claritask.com" target="_blank" className="no-text-decoration" >
                                <img style={{ maxWidth: "100%" }} src="/image/claritask.png" />
                                <div>Project Management </div>
                            </a>
                        </li>
                        <li className="li-no-margin-b" onClick={(e)=> e.target.firstChild.click()}>
                        <a href="https://sendbat.com/" target="_blank" className="no-text-decoration" >
                                <img style={{ maxWidth: "100%" }} src="/image/sendbat.png" />
                                <div>Email & SMS Marketing</div>
                            </a>
                        </li>

                        <li className="li-no-margin-b" onClick={(e)=> e.target.firstChild.click()}>
                            <a href="https://claritick.com" target="_blank" className="no-text-decoration" >
                                <img style={{ maxWidth: "100%" }} src="/image/claritick.png" />
                                <div>Ticket Management </div>
                            </a>
                        </li>
                        
                        <li className="li-no-margin-b" onClick={(e)=> e.target.firstChild.click()}>
                        <a href="https://urless.com/" target="_blank" className="no-text-decoration" >
                                <img style={{ maxWidth: "100%" }} src="/image/urless.png" />
                                <div>URL Shortening Service</div>
                            </a>
                        </li>
                       
                        <li className="li-no-margin-b" onClick={(e)=> e.target.firstChild.click()}>
                        <a href="https://morsix.com/" target="_blank" className="no-text-decoration" >
                                <img style={{ maxWidth: "100%" }} src="/image/morsix.png" />
                                <div>Warehouse Management</div>
                            </a>
                        </li>
                        
                        <li className="li-no-margin-b" onClick={(e)=> e.target.firstChild.click()}>
                        <a href="https://convosio.com/" target="_blank" className="no-text-decoration" >
                                <img style={{ maxWidth: "100%" }} src="/image/convosio.png" />
                                <div>Chat Support</div>
                            </a>
                        </li>
                        <li className="li-no-margin-b" onClick={(e)=> e.target.firstChild.click()}>
                        <a href="https://ipaymer.com/" target="_blank" className="no-text-decoration" >
                                <img style={{ maxWidth: "100%" }} src="/image/ipaymer.png" />
                                <div>Payment System</div>
                            </a>
                        </li>
                    </ul>
                  
                </Box>
            </Box></ClickAwayListener>}
        </>
    )
}

export default OurProductSuite