import React from 'react'
import { Helmet } from 'react-helmet'
import Main from '../Helpers/Main'
import AllCategories from './section/AllCategories'
import BecomeIreview from './section/BecomeIreview'
import ListOfCards from './section/ListOfCards'
import SearchBusiness from './section/SearchBusiness'
import AlertComponent from '../Helpers/Alert'

const HomeOrigin = () => {
  return (
    <Main>
        
            <Helmet>
                <title>iReview</title>
                <meta property="og:title" content="iReview"/>
                <meta charset="UTF-8" />
                <meta property="og:url" content={`https://iReview.com/`} />
            </Helmet>


            <SearchBusiness/>

            <AllCategories/>

            <ListOfCards/>

            <BecomeIreview/>
        <AlertComponent/>
    </Main>
  )
}

export default HomeOrigin