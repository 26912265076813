import React from "react";
import { Container } from "@mui/system";
import { Grid } from "@mui/material";
import {MdStars} from "react-icons/md";
import { Helmet } from "react-helmet";


//My components
import Main from "../Helpers/Main";
import Categories from "./Categories";
import Recent from "./Recent";
import Search from "../Helpers/Search";
import AlertComponent from "../Helpers/Alert";


const Home = () => {
    return(
        <Main>
            <Helmet>
                <title>iReview</title>
                <meta property="og:title" content="iReview"/>
                <meta property="og:url" content={`https://iReview.com/`} />
            </Helmet>
            <AlertComponent/>
            <div className="main-fullscreen-blue main-fullscreen-padding bg-ilustration-1">
                <Container maxWidth="xl">
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={6}>
                            <div className="header-container">
                                <h1>
                                    Read Reviews. Write Reviews. Check Places You Can Trust!
                                </h1>
                                <p>Read reviews. Write reviews. Find companies.</p>
                                <Search/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="header-container-acc">
                                <div>
                                    <div>
                                        <h2>Become an iReview Trusted Location <MdStars/></h2>
                                        <p>Customers today are constantly looking for a place they can trust, before they decide to whom they will give their business to.</p>
                                    </div>
                                    <button>Get Accredited</button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth="xl">
                <div className="mt-100">
                    <Categories/>
                </div>
            </Container>
            <Recent/>
        </Main>
    )
}

export default Home