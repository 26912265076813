import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { fetchCategoryDetails } from "../../redux/actions";
import { time, city, reviews, status } from "../../redux/actions";

const Filters = ({id}) => {
    const dispatch = useDispatch();
    let page = 1

    
    let timeState = useSelector(state => state.timeReducer.time)
    let reviewState = useSelector(state => state.reviewsReducer.reviews)
    let statusState = useSelector(state => state.statusReducer.status)
    let cityState = useSelector(state => state.cityReducer.city)

    const filterCompanies = () => {
        dispatch(fetchCategoryDetails(id, page, cityState, reviewState, timeState, statusState))
    }
    useEffect(() => {
        dispatch(fetchCategoryDetails(id, page, cityState, reviewState, timeState, statusState))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeState, reviewState, statusState])

    useEffect(() => {
        dispatch(time(''))
        dispatch(city(''))
        dispatch(reviews(''))
        dispatch(status(''))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    
    const resetFilters = () => {
        dispatch(time(''))
        dispatch(city(''))
        dispatch(reviews(''))
        dispatch(status(''))
    }

    return(
        <div className="grey-background-box">
            <div className="title-parent pb-25 d-flex align-items-center justify-content-between">
                <h4>Filters</h4>
                <span onClick={(e) => resetFilters()}>Reset Filters</span>
            </div>
            <div className="pb-25">
                <div className="code-container">
                    <h6>City or Zip Code</h6>
                    <div>
                        <input type="text" defaultValue={cityState} onChange={(e) => dispatch(city(e.target.value))}/>
                        <button onClick={() => filterCompanies()}>Apply</button>
                    </div>
                </div>
            </div>
            <div className="pb-25">
                <div>
                    <FormControl>
                        <FormLabel>Number of Reviews</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={reviewState ? reviewState : ""}
                            name="radio-buttons-group"
                            onChange={(e) => dispatch(reviews(e.target.value))}
                        >
                            <FormControlLabel className="control-label-poppins" value="" control={<Radio className="mui-radio-custom" />} label="All" />
                            <FormControlLabel className="control-label-poppins" value="25"  control={<Radio className="mui-radio-custom" />} label="25+" />
                            <FormControlLabel className="control-label-poppins" value="50"  control={<Radio className="mui-radio-custom" />} label="50+" />
                            <FormControlLabel className="control-label-poppins" value="100" control={<Radio className="mui-radio-custom" />} label="100+" />
                            <FormControlLabel className="control-label-poppins" value="250" control={<Radio className="mui-radio-custom" />} label="250+" />
                            <FormControlLabel className="control-label-poppins" value="500" control={<Radio className="mui-radio-custom" />} label="500+" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div className="pb-25">
                <div>
                    <FormControl>
                        <FormLabel>Period of Time</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={timeState ? timeState : ""}
                            name="radio-buttons-group"
                            onChange={(e) => dispatch(time(e.target.value))}
                        >
                            <FormControlLabel className="control-label-poppins" value="" control={<Radio className="mui-radio-custom" />} label="All time" />
                            <FormControlLabel className="control-label-poppins" value="6"  control={<Radio className="mui-radio-custom" />} label="6 months" />
                            <FormControlLabel className="control-label-poppins" value="12"  control={<Radio className="mui-radio-custom" />} label="12 months" />
                            <FormControlLabel className="control-label-poppins" value="18" control={<Radio className="mui-radio-custom" />} label="18 months" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div>
                <div>
                    <FormControl>
                        <FormLabel>Company status</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={statusState ? statusState : ""}
                            name="radio-buttons-group"
                            onChange={(e) => dispatch(status(e.target.value))}
                        >
                            <FormControlLabel className="control-label-poppins" value="" control={<Radio className="mui-radio-custom" />} label="All" />
                            <FormControlLabel className="control-label-poppins" value="1" control={<Radio className="mui-radio-custom" />} label="Accredited" />
                            <FormControlLabel className="control-label-poppins" value="2"  control={<Radio className="mui-radio-custom" />} label="Unaccredited" />
                            <FormControlLabel className="control-label-poppins" value="3"  control={<Radio className="mui-radio-custom" />} label="Claimed" />
                            <FormControlLabel className="control-label-poppins" value="4"  control={<Radio className="mui-radio-custom" />} label="Unclaimed" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}
export default Filters