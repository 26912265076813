import React, {useState} from "react";
import FlaggingFirstStep from "./FlaggingFirstStep";
import FlaggingSecondStep from "./FlaggingSecondStep";
import FlaggingThirdStep from "./FlaggingThirdStep";
import { Collapse, Slide } from "@mui/material";

const style = {
    sidebarTop: {
        height: "61px",
        boxSizing: "border-box"
    },
}

const Flagging = () => {
    const [step, setStep] = useState(1)
    const [reason, setReason] = useState()
    
    return(
        <div>
            <div className="search-user-holder"></div>
            <div className="main-container">
                <div className="stepper-container-report">
                    <FlaggingFirstStep step={step} setStep={setStep} setReason={setReason}/>  
                    <FlaggingSecondStep step={step} setStep={setStep} reason={reason}/>  
                    <FlaggingThirdStep step={step}/>  
                </div>              
            </div>
            <div className="new-sys-sidebar">
                <div className="new-sidebar-top" style={style.sidebarTop}></div>
                <div className="sidebar-main-elements "></div>
            </div>
        </div>
    )
}
export default Flagging