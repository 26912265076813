import React from "react";

const PageNotFound = () => {
    return(
        <div className="page-not-found">
            <img src={"/admin-images/page-404.svg"} alt="404"/>
            <h1>Oops looks like you're lost!</h1>
        </div>
    )
}
export default PageNotFound