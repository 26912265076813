const categoryReducer = (state = [], action) => {
    switch(action.type){
        case 'GET_CATEGORY_DETAILS':
            return{
                ...state,
                category: action.payload.data,
            }
        case 'CLEAR_DATA':
            return{
                ...state,
                category: undefined
            }
        default:
            return{
                ...state
            }
    }
}
export default categoryReducer