import React, {useEffect} from "react"
import { baseUrlDev } from "./Helpers/baseUrl"
import { getLocalUser } from "./TokenControl/parts/useLocalUser"
import axios from "axios"

const LogOut = () => {
    useEffect(() => {
        axios.post(`${baseUrlDev}logout`, {} , {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(() => {
            window.location.href = "https://irevu.com"
            localStorage.clear()
            document.cookie = "i_token=0;domain=.irevu.com;path=/;"
            document.cookie = "irw_token=0;domain=.irevu.com;path=/;"
        })
        
    }, [])
    return null;
}
export default LogOut


