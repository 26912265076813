export const reviewsReducer = (state = '', action) => {
    switch(action.type){
        case 'FILTER_REVIEWS':
            return{
                ...state,
                reviews: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}

export const timeReducer = (state = '', action) => {
    switch(action.type){
        case 'FILTER_TIME':
            return{
                ...state,
                time: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}

export const statusReducer = (state = '', action) => {
    switch(action.type){
        case 'FILTER_STATUS':
            return{
                ...state,
                status: action.payload
            }
        default:
            return{
                ...state
            }
    }
}

export const cityReducer = (state = '', action) => {
    switch(action.type){
        case 'FILTER_CITY':
            return{
                ...state,
                city: action.payload
            }
        default:
            return{
                ...state
            }
    }
}