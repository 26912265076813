import React, {useEffect, useState} from "react"
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector  } from "react-redux";
import { showSidebar } from "../../../redux/actions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SubItem = ({children}) => {
    const dispatch = useDispatch()
    const [showPages, setShowPage] = useState([])
    const navigate = useNavigate()
    const loc = useLocation()
    const location = useSelector(state => state.locationSelected.location)
    const user = useSelector(state => state.getUserInfo.user)
    const [t] = useTranslation()

    //This was used to not show specific submenu items if you are not full Service, momentally is not used. We keep this uncommented in case we need it 

    useEffect(() => {
        if(location){
            let location_details = user && user.user.locations.filter(loc => loc.id == location)
            if(location_details && location_details[0] && (location_details[0].plan == 2 && location_details[0].is_demo == 0)){
                children.map(item => {
                    if(item.fullService){
                        if(!showPages.some(el => el === item.subKey)){
                            setShowPage(prev => [...prev, item.subKey])
                            if(loc.pathname === item.path){
                                navigate("/app/admin")
                            }
                        }
                    }
                })
            } else {
                setShowPage([])
            }
        }
    }, [location, user])
    return(
        <ul className={`nav flex-column child-ul-container`}>
            {children.map(sub => {
                if(sub.fullService && showPages.some(el => el === sub.subKey)){
                    return (
                        ""
                    )
                } else {
                    return(
                        <li key={sub.subKey} onClick={() => {
                            dispatch(showSidebar(false))
                        }}>
                        {
                            sub.external ? <a href={sub.external}>{sub.name}{sub.subtitle && <p className="sidebar-child-sub">{sub.subtitle}</p>}</a> : 
                            <NavLink 
                                to={sub.path}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            >
                                {t(sub.name)}
                                {sub.subtitle && <p className="sidebar-child-sub">{sub.subtitle}</p>}
                            </NavLink>
                        }
                        </li>
                    )
                }
            })}
        </ul>
   
    )
}
export default SubItem