import React from 'react';
import { Container } from "@mui/system";

const TermsConditions = () => {
    return (
        <div className='terms_wrapper'>
            <div className="terms_header">
                <Container maxWidth="xl">
                    <div>
                        <img src="/admin-images/iReview.svg" height="40" alt="" />
                    </div>
                </Container>
            </div>
            <div className='section1'>
                <h3>Terms and Conditions</h3>
            </div>

            <Container maxWidth="xl">
                <div>
                    <p>This Subscription Agreement (the "Agreement") is entered on <span>[DATE]</span> (effective date) between The Social Plus Holding LLC – DBA IREVU, with its principal place of business at 2150 E Lake Cook Rd, Suite 730, Buffalo Grove IL 60089 (hereinafter referred to as the "Provider") and <span>[Subscriber Name]</span> (hereinafter referred to as the "Subscriber"), collectively referred to as the "Parties."</p>
                    <p>WHEREAS, the Provider offers a subscription service (the "Service") which includes Reputation Management platform, but not limited to; and
                    </p>
                    <p>WHEREAS, the Subscriber wishes to subscribe to the <span>[PACKAGE]</span> Service for a period of <span>12 months</span>, subject to the terms and conditions set forth in this Agreement.</p>
                    <p>
                        NOW, THEREFORE, in consideration of the mutual covenants contained herein, the Parties agree as follows:
                    </p>
                    <p>Subscription Services 1.1. The Provider agrees to provide the Subscriber with access to the Service, as described in this Agreement, for a period of <span>12 months</span> (the "Subscription Period"), starting from the effective date of this Agreement.</p>
                    <p>1.2. The Service includes iReview Management Platform or Reputation Management, and all its features and offerings.</p>
                    <p>1.3. The Subscriber acknowledges that additional services, features, or functionalities not specifically mentioned in this Agreement may be subject to additional charges or separate agreements.</p>
                    <p>
                        Subscription Fees 2.1. The Subscriber agrees to pay the Provider the subscription fee (the "Fee") as specified in the pricing schedule attached hereto as Appendix A. The Fee shall cover the entire Subscription Period and shall be payable monthly, yearly or in full.
                    </p>
                    <p>
                        2.2. The Subscriber shall make the payments within 5 days of the date of invoice or as otherwise agreed between the Parties.
                    </p>
                    <p>2.3. In the event of non-payment or late payment of the Fee, the Provider reserves the right to suspend or terminate the Subscriber's access to the Service, without liability for any resulting loss or damages, where Subscriber will still continue being liable for the remaining of the owed amount as per the term of this agreement.</p>
                    <p>Intellectual Property 3.1. The Subscriber acknowledges that all intellectual property rights, including but not limited to copyrights, trademarks, and trade secrets, associated with the Service and its related materials, shall remain the exclusive property of the Provider or its licensors.</p>
                    <p>3.2. The Subscriber agrees not to reproduce, distribute, modify, or create derivative works based on the Service, except as expressly permitted by the Provider in writing.</p>
                    <p>Confidentiality 4.1. Both Parties acknowledge that during the course of this Agreement, they may have access to certain confidential information belonging to the other Party. Confidential information includes, but is not limited to, business plans, customer data, financial information, and proprietary technology.</p>
                    <p>
                        4.2. The Parties agree to maintain the confidentiality of any such information received from the other Party, and not to disclose or use it for any purpose other than as required to fulfill their obligations under this Agreement, unless prior written consent is obtained from the disclosing Party.
                    </p>
                    <p>Renewal and Termination 5.1. This Agreement shall commence on the effective date and continue for a period of  <span>12 months</span> unless a termination is requested at a minimum of 30 days prior to the renewal date in accordance with this agreement.
                    </p>
                    <p>Limitation of Liability 6.1. To the maximum extent permitted by law, the Provider shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of or in connection with this Agreement.</p>
                </div>

                <div className='footer'>
                    <h5>Appendix A</h5>
                    <h5>Subscription Fee <span>$price</span></h5>
                    <h5>Term <span>12 months</span></h5>
                    <h5>PACKAGE <span>package</span></h5>
                </div>
            </Container>
        </div>
    )
}

export default TermsConditions;
