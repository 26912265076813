import React from 'react'

const UnAuthorize = ({message,children}) => {
  message ??= "";

  return (
    <div className="page-not-found">
        <h1>401</h1>
        <h6>Unauthorized</h6>
        {message !== "" && <p>{message}</p>}
        {children}
    </div>
  )
}

export default UnAuthorize