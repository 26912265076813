import React from "react";

//My Components
import Navbar from "../Navbar";
import Footer from "../Footer";

const Main = (props) => {
    return(
        <>
            {!props.noNav && <Navbar blueHeader={props.blueHeader}/>}
            <div className={`${!props.noNav ? " main-padding-top" : ""}`}>
                {props.children}
            </div>
            <Footer/>
        </>
    )
}

export default Main