import React, {useState} from "react";
import {Pagination} from "@mui/material";
import { useDispatch, useSelector } from "react-redux/es/exports";
//My imports
import Collapsable from "./collapsable";
import { fetchCategoryDetails } from "../../redux/actions";

const Companies = ({companies, pagination, id, category}) => {
    const dispatch = useDispatch()
    let [page, setPage] = useState(pagination ? pagination.current_page : 1)
    let timeState = useSelector(state => state.timeReducer.time)
    let reviewState = useSelector(state => state.reviewsReducer.reviews)
    let statusState = useSelector(state => state.statusReducer.status)
    let cityState = useSelector(state => state.cityReducer.city)

    const handleChange = (e, value) => {
        setPage(value)
        dispatch(fetchCategoryDetails(id, value, cityState, reviewState, timeState, statusState))
    }
    return(
        <div className="companies-list-holder">
            {companies.length > 0 ? companies.map(company => {
                return(
                    <Collapsable company={company} key={`company${company.id}`} category={category}/>
                )
            }) : 
            <div className="no-data-to-show">
                <h6>No Companies Found in this Category</h6>
                <img src={"/admin-images/no-data.svg"} alt="no companies"/>
            </div>}
            {companies.length > 0 && <Pagination count={pagination && pagination.last_page} page={page} shape="rounded" style={{float: "right"}} onChange={handleChange}/>}
        </div>
    )
}
export default Companies;