import {useState} from 'react'

const ContentReviews = ({ content }) => {
    content = content.trim().replace(/(<br>|<br\/>)/g, '\n'); // replace <br> with \n
    content = content.replace(/&#(\d+);/g, function(match, capture) {
        return String.fromCharCode(capture);
      });
      
    const [configContent, setConfigContent] = useState({
        enabled: content.length > 300 ? true : false,
        expanded: false,
    });

    const expandContent = () => {
        setConfigContent({
            ...configContent,
            expanded: true,
        });
    };

    const shrinkContent = () => {
        setConfigContent({
            ...configContent,
            expanded: false,
        });
    };


    return (
        <>
            <div  className={`reviews-text-paragraph`}>
                {configContent.enabled ? <>
                    {configContent.expanded ? content : content.slice(0, 300)+"..."}
                </> : content}
                {configContent.enabled && <>
                    {configContent.expanded ? <>
                        <div><span onClick={shrinkContent} className='read-moreLess'>Read Less</span></div>
                    </> : <>
                        <span onClick={expandContent} className='read-moreLess'>Read More</span>
                    </>}
                </>}
            </div>
        </>
    )
}

export default ContentReviews