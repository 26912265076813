export const getUsersManagement = (state = [] ,action) => {
    switch(action.type){
        case 'GET_USERS_MANAGEMENT':
            return{
                ...state,
                users_management: action.payload
            }
        case 'CLEAR_USERS_MANAGEMENT':
            return{
                ...state,
                users_management: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const getUserManagement = (state = [] ,action) => {
    switch(action.type){
        case 'GET_USER_MANAGEMENT':
            return{
                ...state,
                user_management: action.payload
            }
        case 'CLEAR_USER_MANAGEMENT':
            return{
                ...state,
                user_management: undefined
            }
        default:
            return{
                ...state
            }
    }
}