import React, {useState} from "react";
import "../../../src/assets/accreditation.css"
import Main from "../Helpers/Main";
import NavbarIrevu from "../Admin/Navbar_Irevu";
import { Grid } from "@mui/material";
import AccreditationSignupForm from "./accreditation-signup-form";
import AccreditationSignupInformation from "./accreditation-signup-informations";
import AccreditatedCompany from "./accreditated-company";

const AccreditationSignUp = () => {
    const [active, setActive] = useState(1)
    const [company, setCompany] = useState('')
    return(
        <Main blueHeader={true} noNav={true}>
            <NavbarIrevu blueHeader={true}/>
            <div className="accreditation-section">
                <h5>Earn Trust and Exellence</h5>
                <h1>Get Accredited Today</h1>
                <img src="/admin-icons/blue-star-acc.svg" alt="blue-star"/>
                <div>
                    <img src="/admin-icons/shadow-acc.svg" alt="shadow"/>
                    <img src="/admin-icons/yellow-star-acc.svg" alt="yellow-star"/>
                </div> 
            </div>
            {active == 1 ?   
                <div className="accreditation-form-wrapper">
                    <Grid container>
                        <Grid item xs={12} md={5}>
                            <div className="accreditation-form-left">
                                <h6>Start your</h6>
                                <h6>journey with us.</h6>
                                <p>
                                    Customers trust businesses with sterling reputations. Our cutting-edge approach to obtaining positive reviews will bolster your brand's credibility and inspire confidence in your offerings.
                                </p>
                                <img src="/admin-images/white-stars.svg" style={{width: "100px", marginTop: "12px"}} alt="stars"/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <div className="accreditation-form-right">
                                <h4>Join iReview!</h4>
                                <AccreditationSignupForm 
                                    active={active} 
                                    setActive={setActive}
                                    company={company}
                                    setCompany={setCompany}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                :
                active == 2 ? 
                <AccreditatedCompany active={active} setActive={setActive}/> :
                <AccreditationSignupInformation 
                    active={active} 
                    setActive={setActive}
                    company={company}
                />  
                
            }
            <div className="accreditation-footer">
                <img src="/admin-images/frame-acc.svg" alt="frame"/>
            </div>
        </Main>
    )
}
export default AccreditationSignUp