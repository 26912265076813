import React, { useEffect, useRef, useState } from "react"
import { Modal, Grid, FormControl, Select, MenuItem, Skeleton, InputLabel, Collapse, CircularProgress } from "@mui/material"
import { formatDateCard, formatCardNumber } from "../../helpers/formatCard"
import { LoadingButton } from "@mui/lab"
import axios from "axios"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { Alert } from "@mui/material"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { mexicoStates, canadaStates, americaStates } from "../../../Helpers/states"
import { countries } from "../../../Helpers/countries"
import { FlagIcon } from "react-flag-kit"
import { myLocation } from "../../helpers/myLocation"
import { MySelectBorder, MySelectSmall } from "../../../Helpers/MySelectShadow"
import { clearWallet } from "../../../../redux/actions/admin-index"
import { MdClear } from "react-icons/md";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

const card_types = [
    { value: "4", label: "visa" },
    { value: "5", label: "mastercard" },
    { value: "3", label: "amex" },
    { value: "6", label: "discover" },
    { value: "5", label: "maestro" },
]

const CreateCard = ({ setOpen, customerId, setHasWallet }) => {
    const dispatch = useDispatch()
    const yearRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [focusCard, setFocusCard] = useState(false)
    const [cvvFocused, setCvvFocused] = useState(false)
    const [monthFocused, setMonthFocus] = useState(false)
    const [yearFocused, setYearFocus] = useState(false)
    const [nameFocused, setNameFocused] = useState(false)
    const [lastNumbers, setLastNumber] = useState()
    const [disabled, setDisabled] = useState(true)
    const [collapsed, setCollapsed] = useState(false)
    const [card_type, setCardType] = useState("unknown")
    const [errors, setErrors] = useState([])
    const { t } = useTranslation()
    const [cartDetails, setCartDetails] = useState({
        name: "",
        email: "",
        card_number: "",
        month: "",
        year: "",
        expiry: "",
        cvc: "",
        address: '',
        city: "",
        state: "",
        country: "US",
        zip_code: ""
    })

    const handleClose = () => {
        setOpen()
        let resetDetails = {
            name: "",
            email: "",
            card_number: "",
            expiry: "",
            cvc: "",
            address: "",
            city: "",
            state: "",
            country: "",
            zip_code: ""

        }
        setCartDetails(resetDetails)
    }

    const handleFindState = (country, region) => {
        if (country === "US") {
            return americaStates.find(state => state.name === region)?.code2A
        } else if (country === "CA") {
            return canadaStates.find(state => state.name === region)?.code2A
        } else if (country === "MX") {
            return mexicoStates.find(state => state.name === region)?.code2A
        } else {
            return ""
        }
    }

    useEffect(() => {
        if (cartDetails.name && cartDetails.card_number && cartDetails.month && cartDetails.year && cartDetails.cvc) {
            setDisabled(false)
        }
    }, [cartDetails])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await myLocation();
                setCartDetails({ ...cartDetails, country: result?.country, state: handleFindState(result?.country, result?.region) })
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [])

    const handleChange = (e) => {
        setErrors([])
        const { name, value } = e.target
        switch (name) {
            case "month":
                if (value.length === 2) {
                    yearRef.current.focus();
                }
                setCartDetails({ ...cartDetails, [name]: value.replace(/\D/g, '') });
                break;
            case "year":
                setCartDetails({ ...cartDetails, [name]: value.replace(/\D/g, '') });
                break;
            case "country":
                if (["US", "CA", "MX"].includes(value)) {
                    setCartDetails({ ...cartDetails, [name]: value });
                } else {
                    setCartDetails({ ...cartDetails, [name]: value, state: "" });
                }
                break;
            default:
                setCartDetails({ ...cartDetails, [name]: value });
                break;
        }

    }

    const handleCardNumber = (e) => {
        const { name, value } = e.target
        setCardType(value.length >= 1 ? (card_types.find(it => it.value == value[0])?.label || "unknown") : "unknown");
        if (value.length <= 19 && e.target.validity.valid) {
            setCartDetails({ ...cartDetails, [name]: value })
        } else {
            return false
        }
    }

    const handleCvvNumber = (e) => {
        const { name, value } = e.target
        if (value.length <= 4) {
            setCartDetails({ ...cartDetails, [name]: value })
        } else {
            return false
        }
    }

    const create = () => {
        setLoading(true)
        let data = {
            name: cartDetails.name,
            number: cartDetails.card_number,
            expiry: `${cartDetails.month}/${cartDetails.year}`,
            cvv: cartDetails.cvc,
            street: cartDetails.address,
            city: cartDetails.city,
            state: cartDetails.state,
            country: cartDetails.country,
            zip_code: cartDetails.zip_code
        }
        axios.post(`${baseUrlDev}wallet/save-card`, data, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(() => {
                setHasWallet(true)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                err.response.data.errors ?
                    Object.keys(err.response.data.errors).map(key => {
                        setErrors(errors => [...errors, err.response.data.errors[key][0]])
                    })
                    :
                    setErrors(["Something Went Wrong"])
                setTimeout(() => {
                    setErrors([])
                }, 5000)
            })
    }

    return (
        <div className="wrapper-loc-edit-cart">
            {errors && errors.map(err => {
                return (
                    <div className="alert-inside-modal" key={err}>
                        <Alert severity="error" className="custom-error-alert">{err}</Alert>
                    </div>
                )
            })}
            <div className="cart-details-container">
                <div className="cart-header-wrapper">
                    <img src="/admin-icons/kore.png" alt="Kore" />
                    <button onClick={handleClose}><MdClear /></button>
                </div>
                <div className="cart-details-body custom-scrollbar-vertical">
                    <div className="card_details_wrapper">
                        <h6>Enter Your Credit Card Information</h6>
                        <div className="cart-form-inputs">
                            <label>{t("Full Name*")}</label>
                            <input
                                type="text"
                                name="name"
                                value={cartDetails.name}
                                onChange={(e) => handleChange(e)}
                                onFocus={() => setNameFocused(true)}
                                onBlur={() => setNameFocused(false)}
                            />
                        </div>
                        <div className="cart-form-inputs">
                            <label>{t("Card Number")}</label>
                            <input
                                data-card={card_type}
                                placeholder="1244 1244 1244 1244"
                                maxLength="19"
                                className="card-number-img"
                                type="text"
                                name="card_number"
                                value={cartDetails.card_number && formatCardNumber(cartDetails.card_number)}
                                onChange={handleCardNumber}
                                pattern="[0-9\s]*"
                                onFocus={() => setFocusCard(true)}
                                onBlur={() => setFocusCard(false)}
                            />
                        </div>
                        <div className="cart-info-details">
                            <div>
                                <label>{t("Expiry Date")}</label>
                                <div className="cart-expiry-details">
                                    <div className="cart-form-inputs">
                                        <input
                                            type="text"
                                            pattern="[0-9\s]*"
                                            name="month"
                                            placeholder="MM"
                                            maxLength="2"
                                            value={cartDetails.month}
                                            onChange={(e) => handleChange(e)}
                                            onFocus={() => setMonthFocus(true)}
                                            onBlur={() => setMonthFocus(false)}
                                        />
                                    </div>
                                    <div className="cart-form-inputs">
                                        <input
                                            type="text"
                                            pattern="[0-9\s]*"
                                            name="year"
                                            ref={yearRef}
                                            maxLength="4"
                                            placeholder="YY"
                                            value={cartDetails.year}
                                            onChange={(e) => handleChange(e)}
                                            onFocus={() => setYearFocus(true)}
                                            onBlur={() => setYearFocus(false)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="cart-form-inputs">
                                <label>Security Code</label>
                                <input
                                    type="number"
                                    name="cvc"
                                    placeholder="CVV/CVV"
                                    maxLength={4}
                                    value={cartDetails.cvc}
                                    onChange={(e) => handleCvvNumber(e)}
                                    onFocus={() => setCvvFocused(true)}
                                    onBlur={() => setCvvFocused(false)}
                                />
                            </div>
                        </div>
                    </div>
                    <Collapse in={collapsed}>
                        <div className="collapsing-wrapper">
                            <h6>{t("Billing Address")}</h6>
                            <div className="cart-form-inputs">
                                <label>{t("Address")}</label>
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Enter your address"
                                    value={cartDetails.address}
                                    onChange={(e) => handleChange(e)}
                                    onFocus={() => setNameFocused(true)}
                                    onBlur={() => setNameFocused(false)}
                                />
                            </div>
                            <div class="cart-info-details">
                                <div className="cart-form-inputs">
                                    <label>{t("City")}</label>
                                    <input
                                        type="text"
                                        name="city"
                                        placeholder="Enter your City"
                                        value={cartDetails.city}
                                        onChange={(e) => handleChange(e)}
                                        onFocus={() => setNameFocused(true)}
                                        onBlur={() => setNameFocused(false)}
                                    />
                                </div>
                                <div className="cart-form-inputs">
                                    <label>{t("State")}</label>
                                    {cartDetails.country !== "US" && cartDetails.country !== "CA" && cartDetails.country !== "MX" ?
                                        <input
                                            name="state"
                                            type="text"
                                            placeholder='Select State'
                                            value={cartDetails.state}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        :
                                        <FormControl fullWidth>
                                            <Select
                                                id="input-type-one-select"
                                                label="position"
                                                name="state"
                                                value={cartDetails.state}
                                                onChange={(e) => handleChange(e)}
                                                input={<MySelectSmall />}
                                                displayEmpty
                                                renderValue={(value) => (cartDetails.state === "" ? 'Select State' : value)}
                                                MenuProps={{
                                                    PaperProps: { sx: { maxHeight: 300 } },
                                                    style: {
                                                        width: "100%",
                                                    },
                                                }}
                                            >
                                                <MenuItem disabled value="">{t("Select State")}</MenuItem>
                                                {cartDetails.country === "US" ?
                                                    americaStates.map(state => {
                                                        return (
                                                            <MenuItem key={`state${state.name}`} value={state.code2A}>{state.name}</MenuItem>
                                                        )
                                                    })
                                                    : cartDetails.country === "CA" ?
                                                        canadaStates.map(state => {
                                                            return (
                                                                <MenuItem key={`state${state.name}`} value={state.code2A}>{state.name}</MenuItem>
                                                            )
                                                        })
                                                        : cartDetails.country === "MX" &&
                                                        mexicoStates.map(state => {
                                                            return (
                                                                <MenuItem key={`state${state.name}`} value={state.code2A}>{state.name}</MenuItem>
                                                            )
                                                        })
                                                }
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                            </div>
                            <div class="cart-info-details">
                                <div className="cart-form-inputs">
                                    <label>{t("Country")}</label>
                                    <FormControl fullWidth>
                                        <Select
                                            id="input-type-one-select"
                                            label="position"
                                            name="country"
                                            value={cartDetails.country ? cartDetails.country : "US"}
                                            onChange={(e) => handleChange(e)}
                                            input={<MySelectSmall />}
                                            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                        >
                                            <MenuItem value={undefined}><em>None</em></MenuItem>
                                            {countries.map(country => {
                                                return (
                                                    <MenuItem key={country.code3A} value={country.code2A}>
                                                        {country.code2A === "US" ? <img src={"/admin-icons/usa.png"} alt={"USA"} style={{ width: "20px", height: "15px", marginRight: "10px" }} /> :
                                                            <FlagIcon code={country.code2A} style={{ width: "17px", height: "15px", marginRight: "4px" }} />}
                                                        {country.name}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="cart-form-inputs">
                                    <label>Zip Code</label>
                                    <input
                                        type="text"
                                        name="zip_code"
                                        placeholder="Enter your Zip Code"
                                        value={cartDetails.zip_code}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>
                <div className="card-details-footer">
                    {!collapsed ?
                        <button disabled={disabled} onClick={() => setCollapsed(true)}>Continue <HiOutlineArrowNarrowRight /></button>
                        :
                        <>
                            {
                                loading == true ?
                                    <button disabled={disabled}><CircularProgress style={{ marginRight: 10, color: "#fff", width: 12, height: 12 }} /> Saving...</button>
                                    :
                                    <button onClick={create}> <img style={{ width: 12, marginRight: 10 }} src="/admin-icons/add-plus-box-white.svg" alt="add" /> Save Card </button>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
export default CreateCard