const alertReducer = (state = '', action) => {
    switch(action.type){
        case 'SHOW_ALERT':
            return{
                ...state,
                show: action.payload,
                text: action.text
            }
        default:
            return{
                ...state
            }
    }
}
export default alertReducer