import { Box,Container } from '@mui/material'
import React from 'react'

import {MdStars} from "react-icons/md";

const BecomeIreview = () => {


  return (
    <section className="section-font-family mb-100">
        <Container  maxWidth="lg">
            <Box className="become-bg-image">
                    <div className="come-header-text">
                        Become an iReview  Trusted Location 
                        <MdStars size="40px" style={{marginBottom:"-4px"}} color="#00B2E3"/>
                    </div>
                    <Box sx={{color:"black",width:"60%"}} md={{color:"black",marginTop:"18px",marginBottom:"25px"}} >
                        Customers today are constantly looking for a place they can trust, before they decide to whom they will give their business to.
                    </Box>
                    <span className="become-btn-get">Get Accredited</span>
            </Box>
        </Container>
    </section>
  )
}

export default BecomeIreview