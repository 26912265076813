import React , {useRef, useState} from "react"
import { Link } from "react-router-dom"
import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import Banner from "./parts/banner"
import { useSelector } from "react-redux"


const Canceled = () => {
    const divRef = useRef(null);
    const [height, setHeight] = useState(0)

    const user = useSelector(state => state.getUserInfo.user)
    const impersonateReducer = useSelector(state => state.impersonateReducer.impersonate)
    const demoReducer = useSelector(state => state.demoReducer.demo)
    const cancelReducer = useSelector(state => state.cancelReducer.cancel)

    return(
        <>
            {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} /></div>}
            <Navbar height={height} title="" all={false} groups={true}/>
            <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                <div className='locked-page'>
                    <div>
                        <div className='locked-page-btn'>
                            <span>
                                <img className="lock-item-svg" src="/admin-images/lock.svg" alt="unlock" />
                            </span>
                            <button>Canceled</button>
                        </div>
                        <p>Take your experience to the next level with Premium.</p>
                        <Link to="/app/admin/subscription" className="link-as-button">Upgrade Now</Link>
                        <span>Request a Demo</span>
                    </div>
                </div>
            </div>
            <Sidebar height={height} />
        </>
    )
}
export default Canceled