import { styled, InputBase} from "@mui/material";

const SmallSelect = styled(InputBase)(({ theme }) => ({
    '& label': {
        backgroundColor: "#fff",
    },
    '& .MuiInputBase-input': {
        borderRadius: '6px',
        position: 'relative',
        backgroundColor: "#fff",
        border: `1px solid rgba(166,178,205,.25)`,
        fontSize: 14,
        fontWeight: 400,
        fontFamily: "Poppins-Regular",
        padding: '4px 26px 4px 12px',
        color: "#252C32",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
    '& .MuiSvgIcon-root' :{
        color: "#252C32"
    },
    '& .MuiInputLabel-formControl':{
        backgroundColor: "#fff",
    }
}));
export default SmallSelect
