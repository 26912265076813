import React from "react"
import { MdArrowForwardIos } from "react-icons/md";
import { useTranslation } from "react-i18next";

const FlaggingFirstStep = ({step, setStep, setReason}) => {
    const [t] = useTranslation()
    const report_review_categories = [
        {id: 1, key: "offTopic", title: "Off topic", description: "Review doesn’t pertain to an experience at or with this business"},
        {id: 2, key: "spam",title: "Spam", description: "Review is from a bot, a fake account, or contains ads and promotions"},
        {id: 3, key: "conflictOfInterest", title: "Conflict of interest", description: "Review is from someone affiliated with the business or a competitor’s business"},
        {id: 4, key: "profanity", title: "Profanity", description: "Review contains swear words, has sexually explicit language, or details graphic violence"},
        {id: 5, key: "bullyingHarassment", title: "Bullying or harassment", description: "Review personally attacks a specific individual"},
        {id: 6, key: "discriminationHatepeech", title: "Discrimination or hate speech", description: "Review has harnful language about an invidual or group based on indentity"},
        {id: 7, key: "personalInformation", title: "Personal information", description: "Review contains personal information, such as an address or phone number"},
        {id: 8, key: "notHelpful", title: "Not helpful", description: "Review doesn’t help people decide whether to go to this place"},
    ]
    return(
        <div className={`report_review_list ${step == 1 ? 'active' : ''}`}>  
            {step == 1 && 
                <>
                    <h1>Report review</h1>
                    <div className="report_review_list_wrapper">
                        {report_review_categories.map(cat => (
                            <div className="report_review_list_item" key={cat.title} onClick={() =>{
                                setStep(2)
                                setReason(cat.key)
                            }}>
                                <div>
                                    <h6>{cat.title}</h6>
                                    <p>{cat.description}</p>
                                </div>
                                <MdArrowForwardIos />
                            </div>
                        ))}
                    </div>
                    <span>Report a legal issue</span>
                </>
            }
        </div>
    )
}
export default FlaggingFirstStep